import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData, configPermisosRW } from '../../service/AuthHelperMethods';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import ShowLoading from "../../components/ShowLoading/ShowLoading";


require('react-dom');
window.React2 = require('react');

const initialFValues = {
    txtFechaOrden: '',
    cboMoneda: '',
    cboProveedor: '',
    txtObservacion: '',
    txtReferenciaV: '',
    txtNroControl: '',
    cboTipoDocumento: '',
    cboTipoPago: '',

    cboProducto: '',
    cboConcepReteISLR: 0,
    sProducto: '',
    cboUoM: '',
    cboTipoCompra: '',
    sUoM: '',
    txtCantidad: 0,
    txtPrecioUnit: 0,
    cboImpuesto: '',
    sImpuesto: '',
    txtDescripcion: '',
    txtContribuyente: '',
    nIdUsuario_aux: 0,
    caso: 0,
    id: '',
    txtIgtf: 0
};
const array_aux = [];
export const OrdenDeCompra = () => {

    const history = useHistory();

    let emptyProducto = {
        cboProducto: '',
        cboConcepReteISLR: 0,
        sProducto: '',
        cboUoM: '',
        cboTipoCompra: '',
        sUoM: '',
        txtCantidad: 0,
        txtPrecioUnit: 0,
        cboImpuesto: '',
        sImpuesto: '',
        txtDescripcion: '',
        txtIgtf: 0
    };

    let location = useLocation();
    const [acceso, setAcceso] = useState("");

    const toast = useRef(null);
    const [MonedaOptions, setMonedaOptions] = useState([]);
    const [ProveedorOptions, setProveedorOptions] = useState([]);
    const [TipoDocumentosOptions, setTipoDocumentosOptions] = useState([]);
    const [TipoPagoOptions, setTipoPagoOptions] = useState([]);
    const [uoMOptions, setUoMOptions] = useState([]);
    const [tipoCompraOptions, setTipoCompraOptions] = useState([]);
    const [impuestosOptions, setImpuestosOptions] = useState([]);
    const [productos, setProductos] = useState([]);
    const [producto, setProducto] = useState(emptyProducto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [httpService] = useState(new HttpService());
    const userData = getUserData() || null;
    const [caso, setCaso] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [submitted2, setSubmitted2] = useState(false);
    const [ProductoDialog, setProductoDialog] = useState(false);
    const [/* disabled */, setDisabled] = useState(true);
    const [productoOptions, setProductoOptions] = useState([]);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [visibleConcepReteISLR, setVisibleConcepReteISLR] = useState(true);
    const [disabledCboImpuesto, setDisabledCboImpuesto] = useState(false);
    const [concepRetenISLROptions, setConcepRetenISLROptions] = useState([]);

    useEffect(() => {
        let acc = configPermisosRW(location, userData);
        setAcceso(acc);
    }, [location, userData]);

    useEffect(() => {
        httpService.getOptionSelect("lista_TipoCompra").then(data => setTipoCompraOptions(data));
        httpService.getOptionSelect("lista_UoM").then(data => setUoMOptions(data));
        httpService.getOptionSelect("lista_productos", userData.contribuyente, '').then(data => setProductoOptions(data));
        httpService.getOptionSelect("lista_Monedas").then(data => setMonedaOptions(data));
        httpService.getOptionSelect("lista_proveedores", userData.contribuyente, '').then(data => setProveedorOptions(data));
        httpService.getOptionSelect("lista_impuestos").then(data => setImpuestosOptions(data));
        httpService.getOptionSelect("lista_concepRetenISLR", userData.contribuyente, '').then(data => setConcepRetenISLROptions(data));
        let array_string_aux = array_aux.toString();
        httpService.getTableSelect("tabla_item_compraP", userData.contribuyente, array_string_aux, 'P').then(data => setProductos(data));
        httpService.getOptionSelect("lista_tipo_documentos", userData.contribuyente, '').then(data => setTipoDocumentosOptions(data));
        httpService.getOptionSelect("lista_tipo_pagos").then(data => setTipoPagoOptions(data));
    }, [httpService, userData.contribuyente]);


    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboProducto" in fieldValues) temp.cboProducto = fieldValues.cboProducto ? "" : "Campo Requerido.";
        if ("cboUoM" in fieldValues) temp.cboUoM = fieldValues.cboUoM ? "" : "Campo Requerido.";
        if ("txtCantidad" in fieldValues) temp.txtCantidad = fieldValues.txtCantidad ? "" : "Campo Requerido.";
        if ("txtPrecioUnit" in fieldValues) temp.txtPrecioUnit = fieldValues.txtPrecioUnit ? "" : "Campo Requerido.";
        if ("txtDescripcion" in fieldValues) temp.txtDescripcion = fieldValues.txtDescripcion ? "" : "Campo Requerido.";
        if ("cboTipoCompra" in fieldValues) temp.cboTipoCompra = fieldValues.cboTipoCompra ? "" : "Campo Requerido.";

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const validate2 = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboProveedor" in fieldValues) temp.cboProveedor = fieldValues.cboProveedor ? "" : "Campo Requerido.";
        if ("txtFechaOrden" in fieldValues) temp.txtFechaOrden = fieldValues.txtFechaOrden ? "" : "Campo Requerido.";
        if ("cboMoneda" in fieldValues) temp.cboMoneda = fieldValues.cboMoneda ? "" : "Campo Requerido.";
        if ("txtReferenciaV" in fieldValues) temp.txtReferenciaV = fieldValues.txtReferenciaV ? "" : "Campo Requerido.";
        if ("cboTipoDocumento" in fieldValues) temp.cboTipoDocumento = fieldValues.cboTipoDocumento ? "" : "Campo Requerido.";
        if ("cboTipoPago" in fieldValues) temp.cboTipoPago = fieldValues.cboTipoPago ? "" : "Campo Requerido.";

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    const handleSubmit = async (e) => {
        setSubmitted(true);
        e.preventDefault();
        setGenerarOrdenDialog(false);
        if (array_aux.length > 0) {
            if (validate2()) {
                setButtonDisabled(true);
                values.txtContribuyente = userData.contribuyente;
                values.nIdUsuario_aux = userData.idUsuario;
                values.caso = caso;
                setLoadingDialog(true);
                httpService.registrarNuevaCompra(values)
                    .then((res) => {
                        setButtonDisabled(false);
                        if (res.error) {
                            let temp = { ...errors };
                            setErrors({ ...temp, });
                            setLoadingDialog(false);
                            swal({ text: res.message, icon: "error", timer: "10000" });
                        } else {
                            let array_string_aux = array_aux.toString();
                            if (res.nId_db !== 0) {
                                array_string_aux = array_string_aux + '||' + res.nId_db;
                            }

                            httpService.asociarItemsCompra(array_string_aux)
                                .then((res) => {
                                    if (res.error) {
                                        let temp = { ...errors };
                                        setErrors({ ...temp, });
                                        setLoadingDialog(false);
                                        swal({ text: res.message, icon: "error", timer: "10000" });
                                    } else {
                                        setLoadingDialog(false);
                                        swal({ text: res.message, icon: "success", timer: "7000" });
                                        resetForm();
                                        array_aux.length = 0
                                        let array_string = array_aux.toString();
                                        httpService.getTableSelect("tabla_item_compraP", userData.contribuyente, array_string).then(data => setProductos(data));
                                        history.push("/consulta_de_compras");
                                    }
                                })
                                .catch((err) => {
                                    setButtonDisabled(false);
                                });
                        }
                    })
                    .catch((err) => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
                        setButtonDisabled(false);
                    });
            }
        } else {
            swal({ text: "La compra no tiene Items cargados", icon: "error", timer: "3000" });
        }

    };

    const AgregarItem = async () => {
        setSubmitted2(true);
        if (validate()) {
            if (!visibleConcepReteISLR && values.cboConcepReteISLR === "") {
                swal({ text: "El Item es un servicio, por favor seleccione un concepto para la retencion de ISLR", icon: "error", timer: "5000" });
            } else {
                setButtonDisabled(true);
                values.txtContribuyente = userData.contribuyente;
                values.nIdUsuario_aux = userData.idUsuario;
                setProductoDialog(false);
                setLoadingDialog(true);
                httpService.agregarItemCompra(values)
                    .then((res) => {
                        setButtonDisabled(false);
                        if (res.error) {
                            let temp = { ...errors };
                            setErrors({
                                ...temp,
                            });
                            setLoadingDialog(false);
                            swal({ text: res.message, icon: "error", timer: "3000" });
                        } else {
                            if (res.nId_db !== 0) {
                                array_aux.push(res.nId_db);
                            }
                            let array_string = array_aux.toString();
                            setLoadingDialog(false);
                            httpService.getTableSelect("tabla_item_compraP", userData.contribuyente, array_string).then(data => setProductos(data));                            
                        }
                    })
                    .catch((err) => {
                        swal({ text: err.message, icon: "error", mtimer: "3000" });
                        setButtonDisabled(false);
                    });
            }
        }

    }

	const resetFormProd = () => {
		const val = { ...values };
		val.cboProducto =  '';
        val.cboConcepReteISLR =  0;
        val.sProducto =  '';
        val.cboUoM =  '';
        val.cboTipoCompra =  '';
        val.sUoM =  '';
        val.txtCantidad =  0;
        val.txtPrecioUnit =  0;
        val.cboImpuesto =  '';
        val.sImpuesto =  '';
        val.txtDescripcion =  '';
        val.txtIgtf = 0;
		setValues(val);

	}

    const openNew = () => {
        setCaso(0);
		resetFormProd();
        setDisabled(false);
        setSubmitted2(false);
        setProductoDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductoDialog(false);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Agregar Item" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={openNew} disabled={acceso && acceso === "W" ? false : true} />
            </React.Fragment>
        )
    }

    /* const header = (
        <div className="table-header">
            <h5 className="p-m-0">Administrador de Productos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    ); */

    const ProductosDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Agregar" icon="pi pi-plus" className="p-button-text" disabled={ButtonDisabled} onClick={AgregarItem} />
        </>
    );

    const ProductoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sProducto}
            </>
        );
    }

    const DescripcionBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtDescripcion}
            </>
        );
    }

    const CantidadBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtCantidad)} ${rowData.sUoM}`;
    }

    const PrecioUnitBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtPrecioUnit)}`;
    }

    const ImpuestoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sImpuesto}
            </>
        );
    }

    const IGTFBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtIgtf}
            </>
        );
    }

    const SubTotalBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtSubTotal)}`;
    }

    const BaseImponibleBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtBaseImponible)}`;
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-mr-2" onClick={() => confirmDeleteSelected(rowData)} disabled={acceso && acceso === "W" ? false : true} />
            </div>
        );
    }

    const ProductoOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.title}</div>
            </div>
        );
    }

    const selectedProductoTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.title}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const [deleteProductosDialog, setDeleteProductosDialog] = useState(false);
    const [generarOrdenDialog, setGenerarOrdenDialog] = useState(false);

    /* const [globalFilter, setGlobalFilter] = useState(null); */
    const dt = useRef(null);



    const hideDeleteProductosDialog = () => {
        setDeleteProductosDialog(false);
    }

    const hideGenerarOrdenDialog = () => {
        setGenerarOrdenDialog(false);
    }

    const confirmDeleteSelected = (producto) => {
        setProducto(producto);
        setDeleteProductosDialog(true);
    }

    const confirmGenerarOrden = (producto) => {
        setProducto(producto);
        setGenerarOrdenDialog(true);
    }


    const deleteSelectedProductos = async (e) => {
        producto.nIdContribuyente = userData.contribuyente;
        e.preventDefault();
        httpService.deleteItemCompra(producto)
            .then((res) => {
                if (res.error) {
                    let temp = { ...errors };
                    if (res.message.indexOf("Email") >= 0) {
                        temp.txtEmail = res.message;
                    }
                    setErrors({
                        ...temp,
                    });
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                } else {
                    //Eliminacion exitosa
                    setDeleteProductosDialog(false);
                    let array_string = array_aux.toString();
                    httpService.getTableSelect("tabla_item_compraP", userData.contribuyente, array_string).then(data => setProductos(data));
                }
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
    }

    const deleteProductosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductosDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProductos} />
        </>
    );

    const generarOrdenDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideGenerarOrdenDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={handleSubmit} />
        </>
    );

    const formatCurrency = function (number) {
        var numeral = require('numeral');
        var myNumeral = numeral(number);
        return myNumeral.format('0,0.00');
    };

    const Nsi = () => {
        let total = 0;
        for (let mont of productos) {
            total += (parseFloat(mont.txtBaseImponible));
        }
        return formatCurrency(total);
    }

    var groupBy = function (miarray, prop) {
        return miarray.reduce(function (groups, item) {
            var val = item[prop];
            groups[val] = groups[val] || { sImpuesto: item.sImpuesto, deImpuesto: 0, deBaseImpon: 0 };
            groups[val].deImpuesto += parseFloat(item.deImpuesto);
            groups[val].deBaseImpon += parseFloat(item.txtSubTotalBaseImpo);
            return groups;
        }, {});
    };

    const grupoImpuesto = () => {
        /* let grupo = [
            {
                sImpuesto: "Alicuota",
                deImpuest: 0,
            },
        ]; */
        let grImp = groupBy(productos, "sImpuesto");
        let refinado = Object.values(grImp).filter((i) => i.sImpuesto !== "Exento");

        return refinado;
    };

    const Total = () => {
        let total = 0;
        for (let mont of productos) {
            total += parseFloat(mont.txtSubTotal);
        }
        return formatCurrency(total);
    }

    const TotalIgtf = () => {
        let total = 0;
        for (let mont of productos) {
            total += parseFloat(mont.txtIgtf);
        }
        return formatCurrency(total);
    }

    const cargarDatosProducto = (e) => {
        let idPro = e.target.value;
        let val = { ...values };

        if (idPro !== undefined) {
            const resultado = productoOptions.find(pro => pro.id === idPro);
            val.cboProducto = idPro;
            val.cboUoM = resultado.nUoM;
        } else {
            val.cboProducto = idPro;
            val.cboUoM = '';
        }
        setValues(val);
    }

    const cargarConcepRetenISLR = (e) => {
        let idPro = e.target.value;
        let val = { ...values };

        if (idPro !== undefined) {
            val.cboTipoCompra = idPro;
            val.cboConcepReteISLR = '';
            setVisibleConcepReteISLR(true);

        }

        if (idPro === 73) {
            val.cboTipoCompra = idPro;
            setVisibleConcepReteISLR(false);
        } else {
            val.cboTipoCompra = idPro;
            val.cboConcepReteISLR = '';
            setVisibleConcepReteISLR(true);
        }
        setValues(val);
    }

    const ActivarAlicuota = async (e) => {
        let idPro = e.target.value;
        let val = { ...values };
        if (idPro !== undefined) {

            val.cboTipoDocumento = idPro;

            httpService.ObtenerIsContable(val)
            .then((res) => {
                setButtonDisabled(false);
                if (res.error) {
                    let temp = { ...errors };
                    setErrors({ ...temp, });
                    swal({ text: res.message, icon: "error", timer: "10000" });
                } else {
                    //registro exitoso
                    if (res.isContable === 0){
                        val.cboImpuesto = '';
                        setDisabledCboImpuesto(true);
                    };
                    if (res.isContable === 1){
                        val.cboImpuesto = '';
                        setDisabledCboImpuesto(false);
                    };
                };
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
        }
       setValues(val);
    }

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer="Total Base Imponible:" colSpan={7} footerStyle={{ textAlign: 'right' }} />
            <Column footer={Nsi} />
        </Row>
        {grupoImpuesto().map((post) => (
            <Row>
                <Column footer={`${post.sImpuesto} (B.I. = ${formatCurrency(post.deBaseImpon)}): `} colSpan={7} footerStyle={{ textAlign: "right" }} />
                <Column footer={formatCurrency(post.deImpuesto)} />
            </Row>
        ))}
        <Row>
            <Column footer="TOTAL IGTF:" colSpan={7} footerStyle={{ textAlign: 'right' }} />
            <Column footer={TotalIgtf} />
        </Row>
        <Row>
            <Column footer="TOTAL:" colSpan={7} footerStyle={{ textAlign: 'right' }} />
            <Column footer={Total} />
        </Row>
    </ColumnGroup>;


    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <h5>Nueva Orden de Compra</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="cboProveedor">Proveedor</label>
                            <Dropdown id="cboProveedor" name="cboProveedor" value={values.cboProveedor} onChange={handleInputChange} optionValue="id" optionLabel="title" options={ProveedorOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboProveedor })} autoFocus ></Dropdown>
                            {submitted && !values.cboProveedor && <small className="p-invalid">{errors.cboProveedor}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="txtFechaOrden">Fecha del documento y/o orden</label>
                            <Calendar showIcon showButtonBar dateFormat="dd/m/yy" id="txtFechaOrden" name="txtFechaOrden" value={values.txtFechaOrden} onChange={handleInputChange} monthNavigator yearNavigator yearRange="2010:2030" className={classNames({ 'p-invalid': submitted && !values.txtFechaOrden })} />
                            {submitted && !values.txtFechaOrden && <small className="p-invalid">{errors.txtFechaOrden}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="cboTipoDocumento">Tipo Documento</label>
                            <Dropdown id="cboTipoDocumento" name="cboTipoDocumento" value={values.cboTipoDocumento} onChange={(e) => {handleInputChange(e); ActivarAlicuota(e)}} optionValue="id" optionLabel="title" options={TipoDocumentosOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboTipoDocumento })} autoFocus ></Dropdown>
                            {submitted && !values.cboTipoDocumento && <small className="p-invalid">{errors.cboTipoDocumento}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="cboTipoPago">Tipo de Pago</label>
                            <Dropdown id="cboTipoPago" name="cboTipoPago" value={values.cboTipoPago} onChange={handleInputChange} optionValue="id" optionLabel="title" options={TipoPagoOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboTipoPago })}></Dropdown>
                            {submitted && !values.cboTipoPago && <small className="p-invalid">{errors.cboTipoPago}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="txtReferenciaV">Referencia del Vendedor (Nro Factura, Nota de Debito o Credito)</label>
                            <InputText name="txtReferenciaV" value={values.txtReferenciaV} onChange={handleInputChange} required className={classNames({ 'p-invalid': submitted && !values.txtReferenciaV })} />
                            {submitted && !values.txtReferenciaV && <small className="p-invalid">{errors.txtReferenciaV}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="txtNroControl">Nro Control del Vendedor</label>
                            <InputText name="txtNroControl" value={values.txtNroControl} onChange={handleInputChange} required className={classNames({ 'p-invalid': submitted && !values.txtNroControl })} />
                            {submitted && !values.txtNroControl && <small className="p-invalid">{errors.txtNroControl}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="cboMoneda">Moneda de la compra</label>
                            <Dropdown id="cboMoneda" name="cboMoneda" value={values.cboMoneda} onChange={handleInputChange} optionValue="id" optionLabel="title" options={MonedaOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboMoneda })}></Dropdown>
                            {submitted && !values.cboMoneda && <small className="p-invalid">{errors.cboMoneda}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="txtObservacion">Observación</label>
                            <InputTextarea name="txtObservacion" value={values.txtObservacion} onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={productos} dataKey="id" footerColumnGroup={footerGroup}>
                        <Column field="sProducto" header="Producto" body={ProductoBodyTemplate}></Column>
                        <Column field="txtDescripcion" header="Descripcion" body={DescripcionBodyTemplate}></Column>
                        <Column field="txtCantidad" header="Cant." body={CantidadBodyTemplate}></Column>
                        <Column field="txtPrecioUnit" header="Precio Unit." body={PrecioUnitBodyTemplate}></Column>
                        <Column field="cboImpuesto" header="Alicuota" body={ImpuestoBodyTemplate}></Column>
                        <Column field="txtIgtf" header="IGTF (3%)" body={IGTFBodyTemplate}></Column>
                        <Column field="txtBaseImponible" header="Base Imponible" body={BaseImponibleBodyTemplate}></Column>
                        <Column field="txtSubTotal" header="Sub-Total" body={SubTotalBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={ProductoDialog} maximizable modal style={{ width: '80vw' }} header="Detalles del Producto" className="p-fluid" footer={ProductosDialogFooter} onHide={hideDialog}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cboTipoCompra">Tipo de Compra</label>
                                <Dropdown id="cboTipoCompra" name="cboTipoCompra" value={values.cboTipoCompra} onChange={(e) => { handleInputChange(e); cargarConcepRetenISLR(e) }} optionValue="id" optionLabel="title" options={tipoCompraOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted2 && !values.cboTipoCompra })}></Dropdown>
                                {submitted2 && !values.cboTipoCompra && <small className="p-invalid">{errors.cboTipoCompra}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cboConcepReteISLR">Concepto para Retencion de ISLR</label>
                                <Dropdown disabled={visibleConcepReteISLR} id="cboConcepReteISLR" name="cboConcepReteISLR" value={values.cboConcepReteISLR} onChange={(e) => { handleInputChange(e) }} optionValue="id" optionLabel="title" options={concepRetenISLROptions} filter showClear filterBy="title" placeholder="Selecciona uno" itemTemplate={ProductoOptionTemplate} valueTemplate={selectedProductoTemplate} required className={classNames({ 'p-invalid': submitted2 && !visibleConcepReteISLR && !values.cboConcepReteISLR })}></Dropdown>
                                {submitted2 && !values.cboConcepReteISLR && !visibleConcepReteISLR && <small className="p-invalid">{errors.cboConcepReteISLR}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cboProducto">Producto/Servicio</label>
                                <Dropdown id="cboProducto" name="cboProducto" value={values.cboProducto} onChange={(e) => { handleInputChange(e); cargarDatosProducto(e) }} optionValue="id" optionLabel="title" options={productoOptions} filter showClear filterBy="title" placeholder="Selecciona uno" itemTemplate={ProductoOptionTemplate} valueTemplate={selectedProductoTemplate} required className={classNames({ 'p-invalid': submitted2 && !values.cboProducto })}></Dropdown>
                                {submitted2 && !values.cboProducto && <small className="p-invalid">{errors.cboProducto}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cboUoM">UoM</label>
                                <Dropdown disabled id="cboUoM" name="cboUoM" value={values.cboUoM} onChange={handleInputChange} optionValue="id" optionLabel="title" options={uoMOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted2 && !values.cboUoM })}></Dropdown>
                                {submitted2 && !values.cboUoM && <small className="p-invalid">{errors.cboUoM}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="txtCantidad">Cantidad</label>
                                <InputNumber name="txtCantidad" mode="decimal" minFractionDigits={2} value={values.txtCantidad} onValueChange={handleInputChange} required className={classNames({ 'p-invalid': submitted2 && !values.txtCantidad })} />
                                {submitted2 && !values.txtCantidad && <small className="p-invalid">{errors.txtCantidad}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtPrecioUnit">Precio Unit</label>
                                <InputNumber name="txtPrecioUnit" mode="decimal" minFractionDigits={2} value={values.txtPrecioUnit} onValueChange={handleInputChange} required className={classNames({ 'p-invalid': submitted2 && !values.txtPrecioUnit })} />
                                {submitted2 && !values.txtPrecioUnit && <small className="p-invalid">{errors.txtPrecioUnit}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="cboImpuesto">Alicuota</label>
                                <Dropdown disabled={disabledCboImpuesto} id="cboImpuesto" name="cboImpuesto" value={values.cboImpuesto} onChange={handleInputChange} optionValue="id" optionLabel="title" options={impuestosOptions} placeholder="Opcional" ></Dropdown>
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="txtIgtf">IGTF (3%)</label>
                                <InputNumber name="txtIgtf" mode="decimal" minFractionDigits={2} value={values.txtIgtf} onValueChange={handleInputChange} required className={classNames({ 'p-invalid': submitted2 && !values.txtIgtf })} />
                                {submitted2 && !values.txtIgtf && <small className="p-invalid">{errors.txtIgtf}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtDescripcion">Descripción</label>
                                <InputTextarea name="txtDescripcion" value={values.txtDescripcion} onChange={handleInputChange} required className={classNames({ 'p-invalid': submitted2 && !values.txtDescripcion })} />
                                {submitted2 && !values.txtDescripcion && <small className="p-invalid">{errors.txtDescripcion}</small>}
                            </div>
                        </div>
                    </Dialog>


                    <Dialog visible={deleteProductosDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductosDialogFooter} onHide={hideDeleteProductosDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {producto && <span>Esta Seguro de Eliminar el producto seleccionado?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
            <div className="p-col-12">
                <div className="card">
                    <React.Fragment>
                        <Button label="Generar Orden de Compra" icon="pi pi-add" className="p-button-success p-mr-2" onClick={confirmGenerarOrden} disabled={acceso && acceso === "W" ? false : true} />
                    </React.Fragment>
                    <Dialog visible={generarOrdenDialog} style={{ width: '450px' }} header="Confirm" modal footer={generarOrdenDialogFooter} onHide={hideGenerarOrdenDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {producto && <span>Esta Seguro de generar esta orden. Verifique la moneda seleccionada y recuerde que despues no podra eliminarse?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>

            <div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
};
