import React, { useState, useEffect, useRef } from "react";
import { Grid, Container, Paper, Avatar, Typography, CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { House as HouseIcon } from "@material-ui/icons";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import { useHistory } from "react-router-dom";
import { HttpService } from '../../service/HttpService';
import { Toast } from 'primereact/toast';
import { getUserData_aux, /* getTokenFCM  */} from '../../service/AuthHelperMethods';
import { login } from '../../service/AuthHelperMethods';
/* import { getTokenFirebase } from '../../firebase'; */
import ShowLoading from "../../components/ShowLoading/ShowLoading";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#2E6DB4",
        height: "100vh",
        overflow: "auto",
    },
    container: {
        opacity: "0.8",
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        [theme.breakpoints.down(400 + theme.spacing(2) + 2)]: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            width: "100%",
        },
    },
    div: {
        marginTop: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    div_error: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%",
        height: "80vh",
        marginTop: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const initialFValues = {
    cboContrib: '',
    idUsuario: '',
};

const clearStorage = () => {
    localStorage.clear();
};

export default function SelectContribuyente(props) {
    const [httpService] = useState(new HttpService());
    const toast = useRef(null);
    const [tipoContribOptions, seTtipoContribOptions] = useState([]);
    const [userData] = useState(getUserData_aux() || null);
    const [loadingDialog, setLoadingDialog] = useState(false);
    /* const [isTokenFound, setTokenFound] = useState(false); */
    
    useEffect(() => {
        clearStorage();
        return () => { };
    }, []);

    const history = useHistory();
    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const classes = useStyles();

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboContrib" in fieldValues) temp.cboContrib = fieldValues.cboContrib ? "" : "Campo Requerido.";
        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    useEffect(() => {
        httpService.getOptionContribuyente("lista_contribuyente", userData.idUsuario).then(data => seTtipoContribOptions(data));
        values.idUsuario = userData.idUsuario;
    }, [httpService, values, userData]);

    const handleSubmit = async e => {
        e.preventDefault()
        if (validate()) {
            setLoadingDialog(true);
            setButtonDisabled(true);
            login(values)
                .then(res => {
                    setButtonDisabled(false);
                    if (res.error) {
                        setLoadingDialog(false);
                        let temp = { ...errors }
                        if (res.message.indexOf("Clave") >= 0) {
                            temp.txtClave = res.message
                        } else {
                            temp.txtUsuario = res.message
                        }
                        setErrors({
                            ...temp
                        })
                    } else { //login exitoso 
                        /* const tokenFCM = getTokenFCM();
                        const userData2 = {
                            "idUsuario": values.idUsuario,
                            "contribuyente": values.cboContrib
                        } */
                        /* getTokenFirebase(setTokenFound, userData2, tokenFCM); */
                        setLoadingDialog(false);
                        history.push("/dashboard");
                        resetForm();
                    }

                })
                .catch(err => {
                    console.log(err);
                    setLoadingDialog(false);
                    setButtonDisabled(false);
                })
        } else {
            setButtonDisabled(false);
        }
    }

    /*    const handleSubmit = async (e) => {
           e.preventDefault();
           if (validate()) {
               setButtonDisabled(true);
               httpService.registrarContribuyente(values)
                   .then((res) => {
                       setButtonDisabled(false);
                       if (res.error) {
                           let temp = { ...errors };
                           if (res.message.indexOf("Rif") >= 0) {
                               temp.txtRif = res.message;
                           } else {
                               temp.txtEmail = res.message;
                           }
                           setErrors({
                               ...temp,
                           });
                       } else {
                           //registro exitoso
                           toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                           resetForm();
                           setTimeout(() => {
                               history.push("/login");
                           }, 3100);
                       }
                   })
                   .catch((err) => {
                       toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
                       setButtonDisabled(false);
                   });
           }
       }; */

    return (
        <Form onSubmit={handleSubmit} id="formRegistro">
            <Toast ref={toast} />
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Container component={Paper} elevation={5} maxWidth="xs" className={classes.container}>
                    <div className={classes.div}>
                        <Avatar className={classes.avatar}>
                            <HouseIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Contribuyente Registrados
                        </Typography>
                        <Typography component="h1" variant="subtitle1" align="left">
                            Seleccione un contribuyente para entrar a su perfil
                        </Typography>
                        <div className="p-field p-col-12 p-md-12">
                            <Controls.Select fullWidth autoFocus color="Primary" margin="normal" variant="outlined" onChange={handleInputChange} name="cboContrib" label="Contribuyentes" options={tipoContribOptions} value={values.cboContrib} error={errors.cboContrib} />
                        </div>
                        {/* Boton */}
                        <div className="p-field p-col-12 p-md-12">
                            <Controls.Button fullWidth variant="contained" color="primary" className={classes.button} disabled={ButtonDisabled} type="submit" text="Entrar" />
                        </div>
                    </div>

                    <div className="p-col-12">
                        <div>
                            <ShowLoading data={loadingDialog} />
                        </div>
                    </div>
                </Container>
            </Grid>
        </Form>
    );
}
