// import { initializeApp } from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import "firebase/compat/messaging";
import { setTokenFCM } from "./service/AuthHelperMethods";
import { HttpService } from "./service/HttpService";
import Constantes from './Constantes';

var firebaseConfig = {
    apiKey: Constantes.FB_API_KEY,
    authDomain: "notificaciones-push-fcm-9ea7f.firebaseapp.com",
	databaseURL: "https://notificaciones-push-fcm-9ea7f.firebaseio.com",
	projectId: "notificaciones-push-fcm-9ea7f",
	storageBucket: "notificaciones-push-fcm-9ea7f.appspot.com",
	messagingSenderId: "770690209918",
	appId: "1:770690209918:web:b1771973ec8a6f397be02f",
	measurementId: "G-QW28GDZY97"
};

// firebase.initializeApp(firebaseConfig);
const firebase = initializeApp(firebaseConfig);
// const messaging = firebase.messaging();
const messaging = getMessaging(firebase);
const httpService = new HttpService();

export const firebase_messaging = messaging;

export const getTokenFirebase = (setTokenFound, userData, tokenFCM) => {
    return getToken(messaging, { vapidKey: Constantes.FB_APP_VAPID_KEY })
        .then((currentToken) => {
            const idUsuario = userData.idUsuario;
            const idContrib = userData.contribuyente;
            if (currentToken) {
                // console.log("current token for client: ", currentToken);
                setTokenFound(true);
				if (currentToken !== tokenFCM){
					httpService.updateTokenPush(idUsuario, idContrib, currentToken)
						.then((res) => {
							setTokenFCM(currentToken);
							httpService.sendNotificationsPush(idUsuario, idContrib)
							.then((res) => { console.log("envio exitoso") })
							.catch((err) => {console.log(err) });

                            httpService.sendNotificationsVencidaPush(idUsuario, idContrib, 'V')
							.then((res) => { console.log("Vencidas Exitosas") })
							.catch((err) => {console.log(err) });

							httpService.sendNotificationsVencidaPush(idUsuario, idContrib, 'NP')
							.then((res) => { console.log("No Pagadas Exitosas") })
							.catch((err) => {console.log(err) });
						})
						.catch((err) => {
							console.log(err);
						});
				}
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log("No registration token available. Request permission to generate one.");
                setTokenFound(false);
                // shows on the UI that permission is required
            }
        })
        .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
			setTokenFound(false);
            // catch error while creating client token
        });
};

// onMessage(messaging, (payload) => {
// 	console.log('Message received. ', payload);
// });

// export const onMessageListener = () =>
//     new Promise((resolve) => {
//         onMessage(messaging, (payload) => {
//             resolve(payload);
//         });
//     });
