import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData, configPermisosRW } from '../../service/AuthHelperMethods';
import ShowLoading from "../../components/ShowLoading/ShowLoading";

const initialFValues = {
    txtNombre: '',
    txtDescripcion: '',
    txtContribuyente: '',
    caso: 0,
    id: '', 
};

export default function AdmTipoProductos() {
    let emptyTproducto = {
        txtNombre: '',
        txtDescripcion: '',
        id: '', 
    };
	
	let location = useLocation();
	const [acceso, setAcceso] = useState("");

    const toast = useRef(null);
    const [tProductoDialog, setTProductoDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [userData] = useState(getUserData() || null);
    const [httpService] = useState(new HttpService());
    const [tproductos, setTProductos] = useState([]);
    const [tproducto, setTProducto] = useState(emptyTproducto);
    const [caso, setCaso] = useState(true);
	const [loadingDialog, setLoadingDialog] = useState(false);

    useEffect(() => {
		setLoadingDialog(true);
		let acc = configPermisosRW(location, userData);
		setAcceso(acc);
        httpService.getTableSelect("tabla_tproductos", userData.contribuyente, '')
		.then(data => { setLoadingDialog(false); if (data.error) toast.current.show({ severity: 'error', summary: 'Error', detail: data.message, life: 5000 }); else  setTProductos(data)})
		.catch(err => { setLoadingDialog(false); console.log(err); });
    }, [httpService, location, userData]);

    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("txtNombre" in fieldValues) temp.txtNombre = fieldValues.txtNombre ? "" : "Campo Requerido.";
        if ("txtDescripcion" in fieldValues) temp.txtDescripcion = fieldValues.txtDescripcion ? "" : "Campo Requerido.";

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    const handleSubmit = async (e) => {
        setSubmitted(true);
        e.preventDefault();

        if (validate()) {
            setButtonDisabled(true);
            values.txtContribuyente = userData.contribuyente;
            values.caso = caso;
            httpService.registrarNuevoTProducto(values)
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        let temp = { ...errors };
                        if (res.message.indexOf("Email") >= 0) {
                            temp.txtEmail = res.message;
                        }
                        setErrors({
                            ...temp,
                        });
                        toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                    } else {
                        //registro exitoso
                        toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                        setTProductoDialog(false);
                        resetForm();
                        httpService.getTableSelect("tabla_tproductos", userData.contribuyente, "").then(data => setTProductos(data));
                    }
                })
                .catch((err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
                    setButtonDisabled(false);
                });
        }
    };
    const openNew = () => {
        setCaso(0);
        setValues(emptyTproducto);
        setSubmitted(false);
        setTProductoDialog(true);
    }

    const editProduct = (tproducto) => {
        setCaso(1);
        setValues(tproducto);
        setTProductoDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setTProductoDialog(false);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} disabled={ acceso && acceso === "W" ? false : true } />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={ acceso && acceso === "W" ? (!selectedTProductos || !selectedTProductos.length) : true } />
            </React.Fragment>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Administrador de Tipo de Productos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const tProductosDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmit} />
        </>
    );

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.txtNombre}
            </>
        );
    }

    const descripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.txtDescripcion}
            </>
        );
    }

    const estatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Estatus</span>
                {rowData.estatus}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-mr-2" onClick={() => editProduct(rowData)} disabled={ acceso && acceso === "W" ? false : true } />
                <Button icon={rowData.iconStatus} className={rowData.iconClass} onClick={() => confirmUptEstatusTProducto(rowData)} disabled={ acceso && acceso === "W" ? false : true } />
            </div>
        );
    }

    const confirmUptEstatusTProducto = (tproducto) => {
        setTProducto(tproducto);
        setUptEstatusTProductoDialog(true);
    }


    const updEstatusTProducto = async (e) => {
        e.preventDefault();
        values.txtContribuyente = userData.contribuyente;
        values.idTProducto = tproducto.id

        httpService.updateEstatusTProducto(values)
            .then((res) => {
                if (res.error) {
                    let temp = { ...errors };
                    if (res.message.indexOf("Email") >= 0) {
                        temp.txtEmail = res.message;
                    }
                    setErrors({
                        ...temp,
                    });
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                } else {
                    //Actualizacion exitosa
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                    setUptEstatusTProductoDialog(false);
                    setTProducto(emptyTproducto);
                    resetForm();
                    httpService.getTableSelect("tabla_tproductos", userData.contribuyente, "").then(data => setTProductos(data));
                }
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
    };


    const [UptEstatusTProductoDialog, setUptEstatusTProductoDialog] = useState(false);

    const hideUpdEstatusTProductosDialog = () => {
        setUptEstatusTProductoDialog(false);
    }
    const updEstatusTProductoDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideUpdEstatusTProductosDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={updEstatusTProducto} />
        </>
    );

    const [deleteTProductosDialog, setDeleteTProductosDialog] = useState(false);

    const [selectedTProductos, setSelectedTProductos] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);



    const hideDeleteTProductosDialog = () => {
        setDeleteTProductosDialog(false);
    }


    const confirmDeleteSelected = () => {
        setDeleteTProductosDialog(true);
    }

    const deleteSelectedTProductos = async (e) => {
               
        e.preventDefault();
        httpService.deleteTProductos(selectedTProductos)
            .then((res) => {
                if (res.error) {
                    let temp = { ...errors };
                    if (res.message.indexOf("Email") >= 0) {
                        temp.txtEmail = res.message;
                    }
                    setErrors({
                        ...temp,
                    });
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                } else {
                    //Eliminacion exitosa
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                    setDeleteTProductosDialog(false);
                    setSelectedTProductos(null);
                    httpService.getTableSelect("tabla_tproductos", userData.contribuyente, "").then(data => setTProductos(data));
                }
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
    }

    const deleteTProductosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTProductosDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedTProductos} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable  ref={dt} value={tproductos} selection={selectedTProductos} onSelectionChange={(e) => setSelectedTProductos(e.value)}
                        dataKey="id" paginator rows={10} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} tipos de productos"
                        globalFilter={globalFilter} emptyMessage="No existen Tipos de Productos." header={header} >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="txtNombre" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                        <Column field="txtDescripcion" header="Descripción" sortable body={descripcionBodyTemplate}></Column>
                        <Column field="estatus" header="Estatus" sortable body={estatusBodyTemplate}    ></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={tProductoDialog} style={{ width: '450px' }} header="Detalles del Tipo Producto" modal className="p-fluid" footer={tProductosDialogFooter} onHide={hideDialog}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtNombre">Nombre</label>
                                <InputText name="txtNombre" value={values.txtNombre} onChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtNombre })} />
                                {submitted && !values.txtNombre && <small className="p-invalid">{errors.txtNombre}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtDescripcion">Descripcion</label>
                                <InputTextarea name="txtDescripcion" value={values.txtDescripcion} onChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtDescripcion })} />
                                {submitted && !values.txtDescripcion && <small className="p-invalid">{errors.txtDescripcion}</small>}
                            </div>
                        </div>
                    </Dialog>


                    <Dialog visible={UptEstatusTProductoDialog} style={{ width: '450px' }} header="Confirm" modal footer={updEstatusTProductoDialogFooter} onHide={hideUpdEstatusTProductosDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {tproducto && <span>Esta seguro de cambiarle el Estatus a  <b>{tproducto.txtNombre }</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteTProductosDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTProductosDialogFooter} onHide={hideDeleteTProductosDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {tproducto && <span>Esta Seguro de Eliminar el Tipo de producto seleccionado?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
}
