import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData, configPermisosRW } from '../../service/AuthHelperMethods';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import swal from 'sweetalert';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { PDFViewer } from "@react-pdf/renderer";
import Retenciones from "../../components/RetencionesTemplate/Retenciones";
import RetencionesISLR from "../../components/RetencionesTemplate/RetencionesISLR";
import ShowLoading from "../../components/ShowLoading/ShowLoading";
import { Checkbox } from 'primereact/checkbox'; 

const initialFValues = {
    txtFechaOrden: '',
    cboMoneda: '',
    cboProveedor: '',
    txtObservacion: '',
    txtReferenciaV: '',
    txtNroControl: '',
    txtPctRetenido: '',
    nNroComprobante: '',
    nIdComprobante: '',
    txtContribuyente: '',
    sEstatus: '',
    id: '',
    nIdUsuario_aux: 0,
    isComprobanteActive: 0,
    numeroComprobante: '',

};
export default function RetencionesDeCompras() {

    let emptyRetencion = {
        txtFechaOrden: '',
        cboMoneda: '',
        cboProveedor: '',
        txtObservacion: '',
        txtReferenciaV: '',
        txtNroControl: '',
        txtPctRetenido: '',
        id: ''
    };

    let location = useLocation();
    const [acceso, setAcceso] = useState("");
    const [MonedaOptions, setMonedaOptions] = useState([]);
    const [/* ProveedorOptions */, setProveedorOptions] = useState([]);

    const toast = useRef(null);
    const [retencionDialog, setRetencionDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [httpService] = useState(new HttpService());
    const userData = getUserData() || null;
    const [retenciones, setRetenciones] = useState([]);
    const [productos, setProductos] = useState([]);
    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const [confirmarComprobanteDialog, setConfirmarComprobanteDialog] = useState(false);
    const [retencion, /* setRetencion */] = useState(emptyRetencion);
    const [retencionData, setRetencionData] = useState([]);
    const [comprobanteRetencionDialog, setComprobanteRetencionDialog] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [isComprobanteActive, setIsComprobanteActive] = useState(false);
    const [numeroComprobante, setNumeroComprobante] = useState('');

    useEffect(() => {
        let acc = configPermisosRW(location, userData);
        setAcceso(acc);
    }, [location, userData]);

    useEffect(() => {
        setLoadingDialog(true);
        httpService.getOptionSelect("lista_Monedas").then(data => setMonedaOptions(data));
        httpService.getOptionSelect("lista_proveedores", userData.contribuyente, '').then(data => setProveedorOptions(data));
        httpService.getTableSelect("tabla_retenciones_en_compras", userData.contribuyente, '')
            .then(data => { setRetenciones(data); setLoadingDialog(false); })
            .catch(err => setLoadingDialog(false));
    }, [userData.contribuyente, httpService]);

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("txtRazonSocial" in fieldValues) temp.txtRazonSocial = fieldValues.txtRazonSocial ? "" : "Campo Requerido.";
        if ("txtRifProv" in fieldValues) temp.txtRifProv = fieldValues.txtRifProv ? "" : "Campo Requerido.";
        if ("txtRepresentanteLegal" in fieldValues) temp.txtRepresentanteLegal = fieldValues.txtRepresentanteLegal ? "" : "Campo Requerido.";
        if ("txtTelefono" in fieldValues) temp.txtTelefono = fieldValues.txtTelefono ? "" : "Campo Requerido.";
        if ("txtMovil" in fieldValues) temp.txtMovil = fieldValues.txtMovil ? "" : "Campo Requerido.";
        if ("txtPersonaContacto" in fieldValues) temp.txtPersonaContacto = fieldValues.txtPersonaContacto ? "" : "Campo Requerido.";
        if ("txtEmail" in fieldValues) temp.txtEmail = fieldValues.txtEmail ? "" : "Campo Requerido.";
        if ("txtDireccion" in fieldValues) temp.txtDireccion = fieldValues.txtDireccion ? "" : "Campo Requerido.";
        if ("cboMoneda" in fieldValues) temp.cboMoneda = fieldValues.cboMoneda ? "" : "Campo Requerido.";

        setErrors({ ...temp, });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };


    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    const editCompra = (compra) => {
        setValues(compra);
        httpService.getTableSelect("tabla_item_compraCV", userData.contribuyente, compra.id).then(data => setProductos(data));
        setRetencionDialog(true);
    }

    const printRetencion = (compra) => {
        httpService.getTableSelect("tabla_item_retencionesC", userData.contribuyente, compra.id)
            .then((data) => {
                compra.items = data;
                setRetencionData(compra);
                setComprobanteRetencionDialog(true);
            });
    }

    const hideComprobanteRetencionDialog = () => {
        setComprobanteRetencionDialog(false);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setRetencionDialog(false);
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Retenciones de Impuestos en Compras</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const handleCheckboxChange = (e) => {
        setIsComprobanteActive(e.checked);
        if (!e.checked) {
            setNumeroComprobante('');
        }
    };

    const handleComprobanteChange = (e) => {
        setNumeroComprobante(e.target.value);
    };

    const handleGenerarComprobanteSubmit = async (e) => {
        setSubmitted(true);
        setConfirmarComprobanteDialog(false);
        setButtonDisabled(true);
        e.preventDefault();

        values.txtContribuyente = userData.contribuyente;
        values.nIdUsuario_aux = userData.idUsuario;
        values.isComprobanteActive = isComprobanteActive;
        values.numeroComprobante = numeroComprobante;

        if (values.sEstatus === 'A') {
            httpService.generarComprobanteReten(values)
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        let temp = { ...errors };
                        setErrors({ ...temp, });
                        swal({ text: res.message, icon: "error", timer: "6000" });
                    } else {
                        //registro exitoso
                        setRetencionDialog(false);
                        swal({ text: res.message, icon: "success", timer: "4000" });
                        httpService.getTableSelect("tabla_retenciones_en_compras", userData.contribuyente, '').then(data => setRetenciones(data));
                        resetForm();
                    }
                })
                .catch((err) => {
                    swal({ text: err.message, icon: "error", timer: "4000" });
                    setButtonDisabled(false);
                });
        } else {
            swal({ text: "Comprobante ya Generado", icon: "error", timer: "3000" });
            setButtonDisabled(false);
        }

    };

    const ProductoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sProducto}
            </>
        );
    }


    const DescripcionBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtDescripcion}
            </>
        );
    }

    const CantidadBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtCantidad)} ${rowData.sUoM}`;
    }

    const PrecioUnitBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtPrecioUnit)} ${rowData.sMoneda}`;
    }

    const ImpuestoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sImpuesto}
            </>
        );
    }

    const IgtfBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtIgtf}
            </>
        );
    }

    const BaseImponibleBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtBaseImponible)} ${rowData.sMoneda}`;
    }

    const SubTotalBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtSubTotal)} ${rowData.sMoneda}`;
    }

    const TotalBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtTotal)} ${rowData.sMoneda}`;
    }

    const TotalRetenidoBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtdePctRetenido)} ${rowData.sMoneda}`;
    }

    const formatCurrency = function (number) {
        var numeral = require('numeral');
        var myNumeral = numeral(number);
        return myNumeral.format('0,0.00');
    };

    const NumeroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Compra</span>
                <span><b>{rowData.sNroOrden}</b></span>
            </>
        );
    }

    const FechaCompraTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha</span>
                {rowData.txtFechaOrden}
            </>
        );
    }

    const ProveedorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Proveedor</span>
                {rowData.sProveedor}
            </>
        );
    }

    const CompRetencionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Comp. Retencion</span>
                <span><b><p style={{ color: 'blue' }}>{rowData.nNroComprobante}</p></b></span>
            </>
        );
    }

    const TipoCompRetencionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tipo</span>
                {rowData.sTipoComprobante}
            </>
        );
    }

    const EstatusDeclaracionBodyTemplate = (rowData) => {
        return (
            <>
                <span className={`product-badge status-${rowData.inventoryDeclaracion.toLowerCase()}`}>{rowData.txtEstatusDeclaracion}</span>
            </>
        );
    }

    const EstatusPagoBodyTemplate = (rowData) => {
        return (
            <>
                <span className={`product-badge status-${rowData.inventoryPago.toLowerCase()}`}>{rowData.txtEstatusPago}</span>
            </>
        );
    }

    const confirmComprobante = () => {

        setConfirmarComprobanteDialog(true);
    }

    const hideConfirmarComprobanteDialog = () => {
        setConfirmarComprobanteDialog(false);
    }


    const RetencionDialogFooter = (
        <>
            <Button label="GENERAR COMPROBANTE DE RETENCION" icon="pi pi-add" className="p-button-success p-mr-2" onClick={confirmComprobante} disabled={acceso && acceso === "W" ? ButtonDisabled : true} />
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-search" className="p-button-rounded p-button-success p-mr-2" onClick={() => editCompra(rowData)} />
                <Button disabled={rowData.ComprobHab} icon="pi pi-cloud-download" className="p-button-rounded p-button-info p-mr-2" onClick={() => printRetencion(rowData)} />
            </div>
        );
    }
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const Nsi = () => {
        let total = 0;
        let sMoneda = "";
        for (let mont of productos) {
            total += parseFloat(mont.txtBaseImponible);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency(total) + " " + sMoneda;
    }

    var groupBy = function (miarray, prop) {
        return miarray.reduce(function (groups, item) {
            var val = item[prop];
            groups[val] = groups[val] || { sImpuesto: item.sImpuesto, deImpuesto: 0, deBaseImpon: 0, sPctRetenido: item.sPctRetenido, dePctRetenido: 0, deRetenidoISLR: 0, sPctTarifaRetenidaISLR: item.sPctTarifaRetenidaISLR };
            groups[val].deImpuesto += parseFloat(item.deImpuesto);
            groups[val].sMoneda = item.sMoneda;
            groups[val].deBaseImpon += parseFloat(item.txtSubTotalBaseImpo);
            groups[val].dePctRetenido += parseFloat(item.dePctRetenido);
            groups[val].deRetenidoISLR += parseFloat(item.deRetenidoISLR);
            return groups;
        }, {});
    };

    const grupoImpuesto = () => {
        let grImp = groupBy(productos, "sImpuesto");
        let refinado = Object.values(grImp).filter((i) => i.sImpuesto !== "Exento");

        return refinado;
    };

    const grupoPctRetenido = () => {
        let grImp = groupBy(productos, "sPctRetenido");
        let refinado = Object.values(grImp).filter((i) => i.sPctRetenido !== "Exento");

        return refinado;
    };

    const grupoPctRetenidoISLR = () => {
        let grImp = groupBy(productos, "sPctTarifaRetenidaISLR");
        let refinado = Object.values(grImp);

        return refinado;
    };

    const Total = () => {
        let subtotal = 0;
        let dePctRetenido_aux = 0;
        let deRetenidoISLR_aux = 0;
        let total = 0;
        let sMoneda = "";
        for (let mont of productos) {
            subtotal += parseFloat(mont.txtSubTotal);
            dePctRetenido_aux += parseFloat(mont.dePctRetenido);
            deRetenidoISLR_aux += parseFloat(mont.deRetenidoISLR);
            sMoneda = mont.sMoneda;
        }
        total = (subtotal - deRetenidoISLR_aux) - dePctRetenido_aux;
        return formatCurrency(total) + " " + sMoneda;
    }

    const TotalIgtf = () => {
        let total = 0;
        let sMoneda = "";
        for (let mont of productos) {
            total += parseFloat(mont.txtIgtf);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency(total) + " " + sMoneda;
    }

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total Base Imponible:" colSpan={7} footerStyle={{ textAlign: 'right' }} />
                <Column footer={Nsi} footerStyle={{ textAlign: "right" }} />
            </Row>

            {grupoImpuesto().map((post) => (
                <Row>
                    <Column footer={`${post.sImpuesto} (B.I. = ${formatCurrency(post.deBaseImpon)} ${post.sMoneda}): `} colSpan={7} footerStyle={{ textAlign: "right" }} />
                    <Column footer={formatCurrency(post.deImpuesto) + " " + post.sMoneda} footerStyle={{ textAlign: "right" }} />
                </Row>
            ))}

            {grupoPctRetenidoISLR().map((post) => (
                <Row>
                    <Column footer={`Porcentaje Retenido ISRL: (${post.sPctTarifaRetenidaISLR}): `} colSpan={7} footerStyle={{ textAlign: "right" }} />
                    <Column footer={formatCurrency((-1 * post.deRetenidoISLR)) + " " + post.sMoneda} footerStyle={{ textAlign: "right" }} />
                </Row>
            ))}

            {grupoPctRetenido().map((post) => (
                <Row>
                    <Column footer={`Porcentaje Retenido IVA (${post.sPctRetenido}): `} colSpan={7} footerStyle={{ textAlign: "right" }} />
                    <Column footer={formatCurrency((-1 * post.dePctRetenido)) + " " + post.sMoneda} footerStyle={{ textAlign: "right" }} />
                </Row>
            ))}

            <Row>
                <Column footer="Total IGTF:" colSpan={7} footerStyle={{ textAlign: 'right' }} />
                <Column footer={TotalIgtf} footerStyle={{ textAlign: "right" }} />
            </Row>

            <Row>
                <Column footer="TOTAL:" colSpan={7} footerStyle={{ textAlign: 'right' }} />
                <Column footer={Total} footerStyle={{ textAlign: "right" }} />
            </Row>
        </ColumnGroup>
    );

    const confirmarCompraDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideConfirmarComprobanteDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={handleGenerarComprobanteSubmit} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <DataTable ref={dt} value={retenciones} dataKey="id" paginator rows={10} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} retenciones"
                        globalFilter={globalFilter} emptyMessage="No existen retenciones." header={header} >
                        <Column field="sNroOrden" header="Compra" sortable body={NumeroBodyTemplate}></Column>
                        <Column field="txtFechaOrden" sortable header="Fecha Compra" body={FechaCompraTemplate} ></Column>
                        <Column field="sProveedor" header="Proveedor" sortable body={ProveedorBodyTemplate}></Column>
                        <Column field="nNroComprobante" header="Comp. Retencion" sortable body={CompRetencionBodyTemplate}></Column>
                        <Column field="sTipoComprobante" header="Tipo" sortable body={TipoCompRetencionBodyTemplate}></Column>
                        <Column field="txtTotal" header="Monto Compra" sortable body={TotalBodyTemplate}></Column>
                        <Column field="txtdePctRetenido" header="Monto Retencion" sortable body={TotalRetenidoBodyTemplate}></Column>
                        <Column field="txtEstatusDeclaracion" sortable body={EstatusDeclaracionBodyTemplate}></Column>
                        <Column field="txtEstatusRecepcion" sortable body={EstatusPagoBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={retencionDialog} maximizable modal style={{ width: '50vw' }} header="Detalles del Comprobante" className="p-fluid" onHide={hideDialog} footer={RetencionDialogFooter}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        <TabView className="tabview-custom">
                            <TabPanel header="Datos Básicos" leftIcon="pi pi-user">
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <div className="p-field p-grid p-col-6">
                                            <label htmlFor="swParaVenta">Agregar Nro Comprobante Manual</label>
                                            <div className="p-col">
                                                <Checkbox inputId="comprobanteActive" name="comprobante" checked={isComprobanteActive} onChange={handleCheckboxChange} />
                                            </div>
                                        </div>
                                        {isComprobanteActive && (
                                            <div className="p-field p-col">
                                                <InputText id="numeroComprobante" name="numeroComprobante" value={numeroComprobante} onChange={handleComprobanteChange} autoFocus />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="cboProveedor">Proveedor</label>
                                        <InputText disabled name="sProveedor" value={values.sProveedor} onChange={handleInputChange} autoFocus />
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtFechaOrden">Fecha de la orden</label>
                                        <InputText disabled name="txtFechaOrden" value={values.txtFechaOrden} onChange={handleInputChange} autoFocus />
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtReferenciaV">Referencia del Vendedor</label>
                                        <InputText disabled name="txtReferenciaV" value={values.txtReferenciaV} onChange={handleInputChange} autoFocus />
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtNroControl">Nro Control del Vendedor</label>
                                        <InputText disabled name="txtNroControl" value={values.txtNroControl} onChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtNroControl })} />
                                        {submitted && !values.txtNroControl && <small className="p-invalid">{errors.txtNroControl}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-flied p-col">
                                        <label htmlFor="cboMoneda">Moneda</label>
                                        <Dropdown disabled id="cboMoneda" name="cboMoneda" value={values.cboMoneda} onChange={handleInputChange} optionValue="id" optionLabel="title" options={MonedaOptions} placeholder="Selecciona uno" ></Dropdown>
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtPctRetenido">Porcentaje de Retención</label>
                                        <InputText disabled name="txtPctRetenido" value={values.txtPctRetenido} onChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtPctRetenido })} />
                                        {submitted && !values.txtPctRetenido && <small className="p-invalid">{errors.txtPctRetenido}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtObservacion">Observación</label>
                                        <InputTextarea disabled name="txtObservacion" value={values.txtObservacion} onChange={handleInputChange} autoFocus />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Items de la Compra" leftIcon="pi pi-dollar">
                                <div className="p-grid crud-demo">
                                    <div className="p-col-12">
                                        <div className="card">
                                            <DataTable ref={dt} value={productos} dataKey="id" footerColumnGroup={footerGroup}>
                                                <Column field="sProducto" header="Producto" body={ProductoBodyTemplate}></Column>
                                                <Column field="txtDescripcion" header="Descripcion" body={DescripcionBodyTemplate}></Column>
                                                <Column field="txtCantidad" header="Cant." body={CantidadBodyTemplate}></Column>
                                                <Column field="txtPrecioUnit" header="Precio Unit." body={PrecioUnitBodyTemplate}    ></Column>
                                                <Column field="cboImpuesto" header="Alicuota" body={ImpuestoBodyTemplate}></Column>
                                                <Column field="txtIgtf" header="IGTF (3%)" body={IgtfBodyTemplate}></Column>
                                                <Column field="txtBaseImponible" header="Base Imponible" body={BaseImponibleBodyTemplate}></Column>
                                                <Column field="txtSubTotal" header="Sub-Total" body={SubTotalBodyTemplate}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </TabView>
                    </Dialog>

                    <Dialog visible={confirmarComprobanteDialog} style={{ width: '450px' }} header="Confirm" modal footer={confirmarCompraDialogFooter} onHide={hideConfirmarComprobanteDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {retencion && <span>Esta Seguro de Generar este comprobante? esta operación no podrá modificarse o eliminarse</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={comprobanteRetencionDialog} onHide={hideComprobanteRetencionDialog} contentStyle={{ maxHeight: "650px" }} resizable={true}>
                        <PDFViewer width="1000" height="450" className="app" >
                            { retencionData.nIdTipoComprobante === 43 &&(
                                <Retenciones data={retencionData} />
                            )}
                            { retencionData.nIdTipoComprobante === 44 &&(
                                <RetencionesISLR data={retencionData} />
                            )}
                        </PDFViewer>
                    </Dialog>
                </div>
            </div>
            <div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
}
