import React, { useState, useRef } from 'react';
import {InputText } from  'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { HttpService } from '../../service/HttpService';
import { getUserData } from '../../service/AuthHelperMethods';
import { Formik } from 'formik';

export default function CambioClave() {
    /*   const txtUsuario = useRef(null); */

    const userData = getUserData() || null;

    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const [clase1, setclase1] = useState('');
    const [clase2, setclase2] = useState('');

    const msgs2 = useRef(null);
    const httpService = new HttpService();


    return (
        <Formik
            initialValues={{ txtPassword1: '', txtPassword2: '', idUsuario:'' }}

            onSubmit= { async (values, { resetForm }) => {
                values.idUsuario = userData.idUsuario;

                if (values.txtPassword1 === "") {
                    setclase1('p-invalid');
                    msgs2.current.show([{ severity: 'error', summary: 'Error:', detail: 'Campo Requerido', sticky: true }]);
                }
                else if (values.txtPassword2=== "") {
                    setclase2('p-invalid');
                    msgs2.current.show([{ severity: 'error', summary: 'Error:', detail: 'Campo Requerido', sticky: true }]);
                }
                else {
                    if (values.txtPassword1 === values.txtPassword2) {
                        setButtonDisabled(true);
                        httpService.cambiarClaveUsuario(values)
                            .then((res) => {
                                setButtonDisabled(false);
                                if (res.error) {
                                    msgs2.current.show([{ severity: 'error', summary: 'Error', detail: res.message, life: 3000 }]);
        
                                } else {
                                    //registro exitoso
                                    resetForm({ values: '' })
                                    setclase1('');
                                    setclase2('');
                                    msgs2.current.show([{ severity: 'success', summary: 'Successful ', detail: res.message, life: 3000 }]);
                                }
                            })
                            .catch((err) => {
                                msgs2.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
                                setButtonDisabled(false);
                            });        
                    }
                    else {
                        msgs2.current.show([{ severity: 'error', summary: 'Error ', detail: 'Las Contraseñas no son Iguales', sticky: true }]);
                    }
                }
                setTimeout(() => {
                    msgs2.current.clear();
                }, 4000);
            }}>
            {
                formik => (
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card">
                                <h5>Cambio Contraseña</h5>
                                <Toast ref={msgs2} />
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="p-fluid p-formgrid p-grid">
                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="firstname2">Ingrese la Nueva Contraseña</label>
                                            <InputText  type="password" ID="txtPassword1" name="txtPassword1" value={formik.values.txtPassword1} onChange={formik.handleChange} feedback={false} toggleMask className={clase1} />
                                        </div>
                                        <div className="p-field p-col-12 p-md-6">
                                            <label htmlFor="lastname2">Repita la Nueva Contraseña</label>
                                            <InputText  type="password" ID="txtPassword2" name="txtPassword2" value={formik.values.txtPassword2} onChange={formik.handleChange} feedback={false} className={clase2} />
                                        </div>
                                        <Button label="Cambiar" type="submit" disabled={ButtonDisabled} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )
            }
        </Formik>
    )
}



