import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "./AsientosContables.styles";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: 900, // Set your custom width here
    mt: 3,
    p: 5,
    [theme.breakpoints.down("sm")]: {
      width: 700, // Set a different width for small screens if necessary
    },
  },
}));

export default function ModalDetalleAsiento({
  asientoData,
  isOpenDialog,
  handleCloseDialog,
}) {
  const classes = useStyles();

  const handleClose = () => {
    handleCloseDialog(false);
  };

  const formatNumber = (value) => {
    // Remove non-numeric characters from the input value
    const numericValue = value.toString().replace(/\D/g, "");
    // Convert the numeric value to a number and divide by 100 to move the decimal point two places to the left
    const numberValue = Number(numericValue) / 100;
    // Format the number value with a dot as a thousand separator and a comma as a decimal separator
    return numberValue.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatDate = (dateStr) => {
    let [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year}`;
  };

  return (
    <Box>
      {asientoData && (
        <>
          <Dialog
            maxWidth={false}
            open={isOpenDialog}
            keepMounted
            onClose={handleClose}
          >
            <DialogTitle
              sx={{
                borderBottom: "1px solid #0003",
                backgroundColor: `#0288D1`,
                color: "white",
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: "18px" }}>
                Asiento Contable: Nº{asientoData.nNroAsiento}
              </Typography>
              <IconButton
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "white",
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <DialogContentText
                sx={{
                  fontSize: "1.2rem",
                  color: `black`,
                  width: "100%",
                }}
              >
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs="auto">
                    {/* Column 1 */}
                    <Typography sx={styles.gridTextTitle}>
                      Número de Asiento:
                    </Typography>
                    <Typography sx={styles.gridTextTitle}>
                      Fecha de Asiento:
                    </Typography>
                    <Typography sx={styles.gridTextTitle}>
                      Monto Total Asiento:
                    </Typography>
                    <Typography sx={styles.gridTextTitle}>Concepto:</Typography>
                  </Grid>
                  <Grid item xs="auto">
                    {/* Column 2 */}
                    <Typography sx={styles.gridTextContent}>
                      {asientoData.nNroAsiento}
                    </Typography>
                    <Typography sx={styles.gridTextContent}>
                      {asientoData.dtFechaAsiento !== undefined && formatDate(asientoData.dtFechaAsiento)}
                    </Typography>
                    <Typography sx={styles.gridTextContent}>
                      {asientoData.deMonto
                        ? formatNumber(asientoData.deMonto)
                        : "0,00"}
                    </Typography>
                    <Typography sx={styles.gridTextContent}>
                      {asientoData.sConcepto}
                    </Typography>
                  </Grid>
                  <Grid item xs={0.5}></Grid>
                </Grid>
              </DialogContentText>
              <Box
                sx={{
                  width: "100%",
                  color: "white",
                  borderTop: "1px solid #D9D9D9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                }}
              />
              <DialogContentText sx={{ color: `black` }}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 500, mb: 2, mt: 3 }}
                >
                  Detalle Asiento Contable
                </Typography>

                <Box sx={{ mt: 2, borderRadius: "3px 3px 0px 0px" }}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "9vh",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#F8F9FA",
                      border: "1px solid #0003",
                      pr: 3,
                      pl: 3,
                    }}
                  >
                    <Box
                      sx={{ width: "40%", fontWeight: 500, fontSize: "1rem" }}
                    >
                      Cuenta
                    </Box>
                    <Box
                      sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}
                    >
                      Debe
                    </Box>
                    <Box
                      sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}
                    >
                      Haber
                    </Box>
                    <Box sx={{ width: "5%" }}></Box>
                  </Box>
                  {asientoData &&
                    asientoData.Detalle?.map((i, index) => (
                      <>
                        <Box
                          sx={{
                            width: "100%",
                            height: "8vh",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "white",
                            border: "1px solid #0003",
                            pr: 3,
                            pl: 3,
                          }}
                          key={index}
                        >
                          <Box
                            sx={{
                              width: "40%",
                              fontWeight: 500,
                              fontSize: "1rem",
                            }}
                          >
                            {i.sCodigoCta}
                          </Box>
                          <Box
                            sx={{
                              width: "30%",
                              fontWeight: 500,
                              fontSize: "1rem",
                            }}
                          >
                            {i.cTipoRegMov === "D"
                              ? formatNumber(i.deMontoMovCont)
                              : 0}
                          </Box>
                          <Box
                            sx={{
                              width: "30%",
                              fontWeight: 500,
                              fontSize: "1rem",
                            }}
                          >
                            {i.cTipoRegMov === "H"
                              ? formatNumber(i.deMontoMovCont)
                              : 0}
                          </Box>
                          <Box
                            sx={{
                              width: "5%",
                              fontWeight: 500,
                              fontSize: "1rem",
                            }}
                          ></Box>
                        </Box>
                      </>
                    ))}
                  <Box
                    sx={{
                      width: "100%",
                      height: "9vh",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#F8F9FA",
                      border: "1px solid #0003",
                      pr: 3,
                      pl: 3,
                    }}
                  >
                    <Box
                      sx={{ width: "40%", fontWeight: 500, fontSize: "1rem" }}
                    >
                      Total Asiento
                    </Box>
                    <Box
                      sx={{ width: "30%", fontWeight: 700, fontSize: "1rem" }}
                    >
                      {asientoData.deMonto
                        ? formatNumber(asientoData.deMonto)
                        : "0,00"}
                    </Box>
                    <Box
                      sx={{ width: "30%", fontWeight: 700, fontSize: "1rem" }}
                    >
                      {asientoData.deMonto
                        ? formatNumber(asientoData.deMonto)
                        : "0,00"}
                    </Box>
                    <Box sx={{ width: "5%" }}></Box>
                  </Box>
                </Box>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
}
