import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#EDF0F5';
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
	rowSinBorde: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amountUnit: {
        width: '15%',
		borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
	vacio: {
		width: '65%',
		borderLeft: 'none',
	},
	labelTotal: {
		width: '85%',
		borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
		fontFamily: 'Helvetica-Bold',
	},
	labelTotal_con: {
		width: '85%',
		borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
		fontFamily: 'Helvetica-Oblique',
	},
	total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
	total_con: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
		fontFamily: 'Helvetica-Oblique',
    },
  });

	/* var txtMoneda = 0; */
  	const formatCurrency = function (number, moneda) {
		var numero = 0;
		if (moneda === 27 || moneda === "Bs."){
			const options = { minimumFractionDigits: 2}
			const numberFormat1 = new Intl.NumberFormat('es-VE' , options);
			numero = numberFormat1.format(number);
		} else {
			var numeral = require("numeral");
			var myNumeral = numeral(number);
			numero = myNumeral.format("0,0.00");
		}	
		return numero;
	};

	const Nsi = (items) => {
        let total = 0;
        let sMoneda = "";
        for (let mont of items) {
            total += parseFloat(mont.txtSubTotal);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency(total, sMoneda) + " " + sMoneda;
    };

	const Total = (items, total_igtf, total_igtf_con, moneda) => {
        let total = 0;
        let sMoneda = "";
        for (let mont of items) {
            total += parseFloat(mont.txtSubTotal) + parseFloat(mont.deImpuesto);
            sMoneda = mont.sMoneda;
        }
		if (moneda === 27) {
			total += total_igtf_con !== undefined ? parseFloat(total_igtf_con) : 0;
		} else {
			total += total_igtf !== undefined ? parseFloat(total_igtf) : 0;
		}
        return formatCurrency(total, sMoneda) + " " + sMoneda;
    };

	const Total_con = (items, total_igtf) => {
        let total = 0;
        let sMoneda = "Bs.";
        for (let mont of items) {
            total += parseFloat(mont.deTotalConImpuesto_con);
        }
		total += total_igtf !== undefined ? parseFloat(total_igtf) : 0;
        return formatCurrency(total, sMoneda) + " " + sMoneda;
    };

	const tasa_con = (items) => {
		return formatCurrency(items[0].deTasaConversion, 27);
	}

	var groupBy = function (miarray, prop) {
        return miarray.reduce(function (groups, item) {
            var val = item[prop];
            groups[val] = groups[val] || { sImpuesto: item.sImpuesto, deImpuesto: 0, deBaseImpon: 0};
            groups[val].deImpuesto += parseFloat(item.deImpuesto);
            groups[val].sMoneda = item.sMoneda;
			groups[val].deBaseImpon += parseFloat(item.txtSubTotal);

            return groups;
        }, {});
    };

    const grupoImpuesto = (productos) => {
        /* let grupo = [
            {
                sImpuesto: "Alicuota",
                deImpuest: 0,
            },
        ]; */
        let grImp = groupBy(productos, "sImpuesto");
        let refinado = Object.values(grImp).filter((i) => i.sImpuesto !== "Exento");

        return refinado;
    };
	
	const formatDescripcion = (sImpuesto, dePctDescuento, sProducto) => {
		let exento = sImpuesto === "Exento" ? "(E)" : "";
		let dcto = dePctDescuento !== "0.00" ? "(-" + dePctDescuento + "% dcto)" : "";
		let descrpt = sProducto + " " + exento + " " + dcto ;
		return descrpt;
    };

	const formatIGTF = (total_igtf, total_igtf_con, moneda) => {
		if (moneda === 27) {
			return formatCurrency(total_igtf_con, moneda);
		} else {
			return formatCurrency(total_igtf, moneda);
		}

	};


const FacturaItemsRows = ({items, moneda, txtMoneda, total_igtf, total_igtf_con, pct_igtf}) => {
	let  rows = items.map( item => 
        <View style={styles.row} key={item.id}>
            <Text style={styles.qty}>{parseInt(item.txtCantidad) + " " + item.sUoM}</Text>
            <Text style={styles.description}>{formatDescripcion(item.sImpuesto, item.dePctDescuento, item.sProducto)}</Text>
            <Text style={styles.amountUnit}>{formatCurrency(item.txtPrecioUnit, moneda) + " " + item.sMoneda}</Text>
			<Text style={styles.amount}>{formatCurrency(item.txtSubTotal, moneda) + " " + item.sMoneda}</Text>
        </View>
    )
    return (
		<Fragment>
			{rows}
			<View style={styles.rowSinBorde}>
				<Text style={styles.labelTotal}>SUBTOTAL</Text>
				<Text style={styles.total}>{ Nsi(items) }</Text>
			</View>

			{grupoImpuesto(items).map((post) => (
				<View style={styles.rowSinBorde}>
					<Text style={styles.labelTotal}>{`${post.sImpuesto} (B.I. = ${formatCurrency(post.deBaseImpon)} ${post.sMoneda})`}</Text>
					<Text style={styles.total}>{ formatCurrency(post.deImpuesto, moneda) + " " + post.sMoneda }</Text>
				</View>
			))}
			{ total_igtf && 
			<View style={styles.rowSinBorde}>
				<Text style={styles.labelTotal}>{`IGTF ${parseInt(pct_igtf)}%`}</Text>
				<Text style={styles.total}>{ formatIGTF(total_igtf, total_igtf_con, moneda) + " " + txtMoneda }</Text>
			</View>
			}
			<View style={styles.row}>
				<Text style={styles.labelTotal}>TOTAL</Text>
				<Text style={styles.total}>{ Total(items, total_igtf, total_igtf_con, moneda) }</Text>
			</View>
			{ moneda !== 27 &&
			<View style={styles.row}> 
				<Text style={styles.labelTotal_con}>TOTAL (Tasa de cambio { tasa_con(items) } Bs. por { txtMoneda })</Text>
				<Text style={styles.total_con}>{ Total_con(items, total_igtf_con) }</Text>
			</View>
				}
		</Fragment> 
	)
};
  
  export default FacturaItemsRows