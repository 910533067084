import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData } from '../../service/AuthHelperMethods';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import ShowLoading from "../../components/ShowLoading/ShowLoading";

const initialFValues = {
    txtNombre : '',
	cboTipoDocumento : '',
	cboNombreCampo : '',
	txtSerie : '',
	txtCorrelativoInicial : 0,
	txtCorrelativoSig : 0,
	txtCorrelativoFinal : ' ',
	txtContribuyente: '',
    caso: 0,
    id: '', 
};

export default function AdmCorrelativos() {
    let emptyCorrelativo = {
		txtNombre : '',
		cboTipoDocumento : '',
		cboNombreCampo : '',
		txtSerie : '',
		txtCorrelativoInicial : 0,
		txtCorrelativoSig : 0,
		txtCorrelativoFinal : null
    };

    const toast = useRef(null);
    const [correlativoDialog, setCorrelativoDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [userData] = useState(getUserData() || null);
    const [httpService] = useState(new HttpService());
    const [correlativos, setCorrelativos] = useState([]);
    const [correlativo, setCorrelativo] = useState(emptyCorrelativo);
    const [tDocElectronicoOptions, setTDocElectronicoOptions] = useState([]);
    const [campoDocumentoOptions, setCampoDocumentoOptions] = useState([]);
    const [caso, setCaso] = useState(true);
    const [disableEdit, setDisableEdit] = useState(false);
    const [disableCorrSig, setDisableCorrSig] = useState(false);
	const [loadingDialog, setLoadingDialog] = useState(false);

    useEffect(() => {
		setLoadingDialog(true);
        httpService.getOptionSelect("tipo_doc_elect").then(data => setTDocElectronicoOptions(data));
        httpService.getOptionSelect("campo_doc_elect").then(data => setCampoDocumentoOptions(data));
        httpService.getTableSelect("tabla_correlativos", userData.contribuyente, '')
		.then(data => { setCorrelativos(data); setLoadingDialog(false); })
		.catch(err => setLoadingDialog(false));
    }, [httpService, userData]);

    const [ButtonDisabled, setButtonDisabled] = useState(false);
    
	const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("txtNombre" in fieldValues) temp.txtNombre = fieldValues.txtNombre ? "" : "Campo Requerido.";
        if ("cboTipoDocumento" in fieldValues) temp.cboTipoDocumento = fieldValues.cboTipoDocumento ? "" : "Campo Requerido.";
        if ("cboNombreCampo" in fieldValues) temp.cboNombreCampo = fieldValues.cboNombreCampo ? "" : "Campo Requerido.";
        if ("txtCorrelativoInicial" in fieldValues) temp.txtCorrelativoInicial = (fieldValues.txtCorrelativoInicial > 0) ? "" : "Campo Requerido.";
        
		setErrors({
            ...temp,
        });
        if (fieldValues === values) {
			return Object.values(temp).every((x) => x === "");
		}
    };
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    const handleSubmit = async (e) => {
        setSubmitted(true);
        e.preventDefault();
		
        if (validate()) {
			setButtonDisabled(true);
            values.txtContribuyente = userData.contribuyente;
            values.caso = caso;
            httpService.registrarCorrelativo(values)
			.then((res) => {
					
                    setButtonDisabled(false);
                    if (res.error) {
                        let temp = { ...errors };
                        setErrors({
                            ...temp,
                        });
                        toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                    } else {
                        //registro exitoso
                        toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                        setCorrelativoDialog(false);
                        resetForm();
                        httpService.getTableSelect("tabla_correlativos", userData.contribuyente, "").then(data => setCorrelativos(data));
                    }
                })
                .catch((err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
                    setButtonDisabled(false);
                });
        } else {
			console.log("Formulario no válido.");
		}
    };
    const openNew = () => {
        setCaso(0);
		setDisableEdit(false);
		setDisableCorrSig(true);
        setValues(emptyCorrelativo);
        setSubmitted(false);
        setCorrelativoDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setCorrelativoDialog(false);
    }

	const editCorrelativo = (correlativo) => {
        setCaso(1);
		setDisableEdit(true);
		setDisableCorrSig(false);
        setValues(correlativo);
        setCorrelativoDialog(true);
    }

	const updEstatusCorrelativo = async (e) => {
        e.preventDefault();
        values.txtContribuyente = userData.contribuyente;
        values.id = correlativo.id

        httpService.updateEstatusCorrelativo(values)
            .then((res) => {
                if (res.error) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                } else {
                    //Actualizacion exitosa
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                    setUptEstatusCorrelativoDialog(false);
                    setCorrelativo(emptyCorrelativo);
                    // resetForm();
                    httpService.getTableSelect("tabla_correlativos", userData.contribuyente, "").then(data => setCorrelativos(data));
                }
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
    };

	const [UptEstatusCorrelativoDialog, setUptEstatusCorrelativoDialog] = useState(false);
	
	const confirmUptEstatusCorrelativo = (correlativo) => {
        setCorrelativo(correlativo);
        setUptEstatusCorrelativoDialog(true);
    }

	const hideUpdEstatusCorrelativoDialog = () => {
        setUptEstatusCorrelativoDialog(false);
    }
    const updEstatusCorrelativoDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideUpdEstatusCorrelativoDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={updEstatusCorrelativo} />
        </>
    );

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCorrelativos || !selectedCorrelativos.length} />
            </React.Fragment>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Administrador de Correlativos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const correlativosDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmit} />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-mr-2" onClick={() => editCorrelativo(rowData)} />
                <Button icon={rowData.sEstatus === "A" ? "pi pi-check-circle" : "pi pi-times-circle"} className={rowData.sEstatus === "A" ? "p-button-rounded p-button-success" : "p-button-rounded p-button-danger"} onClick={() => confirmUptEstatusCorrelativo(rowData)} />
            </div>
        );
    };

    const [deleteCorrelativosDialog, setDeleteCorrelativosDialog] = useState(false);

    const [selectedCorrelativos, setSelectedCorrelativos] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const hideDeleteCorrelativosDialog = () => {
        setDeleteCorrelativosDialog(false);
    }

    const confirmDeleteSelected = () => {
        setDeleteCorrelativosDialog(true);
    }

    const deleteSelectedCorrelativos = async (e) => {
        e.preventDefault();
        httpService.deleteCorrelativos(selectedCorrelativos)
            .then((res) => {
                if (res.error) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                } else {
                    //Eliminacion exitosa
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                    setDeleteCorrelativosDialog(false);
                    setSelectedCorrelativos(null);
                    httpService.getTableSelect("tabla_correlativos", userData.contribuyente, "").then(data => setCorrelativos(data));
                }
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
    }

    const deleteCorrelativosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCorrelativosDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCorrelativos} />
        </>
    );

	const actualizarSig = (e) => {
		let val = { ...values };
		let valor = e.target.value;
		val.txtCorrelativoInicial = valor;
		if (disableCorrSig){
			val.txtCorrelativoSig = valor;
		}
		setValues(val);
	}

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable  ref={dt} value={correlativos} selection={selectedCorrelativos} onSelectionChange={(e) => setSelectedCorrelativos(e.value)}
                        dataKey="id" paginator rows={10} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Correlativos"
                        globalFilter={globalFilter} emptyMessage="No existen Correlativos" header={header} >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="sTipoDocumento" header="Tipo de Documento" sortable></Column>
                        <Column field="sNombreCampo" header="Campo del Documento" sortable></Column>
                        <Column field="txtNombre" header="Nombre" sortable></Column>
                        <Column field="txtSerie" header="Serie" sortable></Column>
                        <Column field="txtCorrelativoSig" header="Siguiente Correlativo" sortable></Column>
                        <Column headerStyle={{ width: "15%" }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={correlativoDialog} style={{ width: '550px' }}  header="Correlativo" modal className="p-fluid" footer={correlativosDialogFooter} onHide={hideDialog}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        
                        <div className="p-formgrid p-grid">
							<div className="p-field p-col">
                                <label htmlFor="cboTipoDocumento">Tipo de documento</label>
                                <Dropdown id="cboTipoDocumento" name="cboTipoDocumento" disabled={disableEdit} value={values.cboTipoDocumento} onChange={handleInputChange} optionValue="id" optionLabel="title" options={tDocElectronicoOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboTipoDocumento })}></Dropdown>
                                {submitted && !values.cboTipoDocumento && <small className="p-invalid">{errors.cboTipoDocumento}</small>}
                            </div>
							<div className="p-field p-col">
                                <label htmlFor="cboNombreCampo">Campo del documento</label>
                                <Dropdown id="cboNombreCampo" name="cboNombreCampo" disabled={disableEdit} value={values.cboNombreCampo} onChange={handleInputChange} optionValue="id" optionLabel="title" options={campoDocumentoOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboNombreCampo })}></Dropdown>
                                {submitted && !values.cboNombreCampo && <small className="p-invalid">{errors.cboNombreCampo}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
							
							<div className="p-field p-col">
                                <label htmlFor="txtNombre">Nombre</label>
                                <InputText name="txtNombre" value={values.txtNombre} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtNombre })} />
                                {submitted && !values.txtNombre && <small className="p-invalid">{errors.txtNombre}</small>}
                            </div>
                            <div className="p-field p-col">
                            	<label htmlFor="txtSerie">Serie</label>
                                <InputText name="txtSerie" value={values.txtSerie} onChange={handleInputChange} />
								<small style={{fontStyle: 'italic'}}>Opcional</small>
                            </div>
                            
                        </div>
                        <div className="p-formgrid p-grid">
							<div className="p-field p-col">
                            	<label htmlFor="txtCorrelativoInicial">Correlativo Inicial</label>
                                <InputNumber name="txtCorrelativoInicial" value={values.txtCorrelativoInicial} mode="decimal" min={0} showButtons useGrouping={false} onValueChange={(e) => { handleInputChange(e); actualizarSig(e); }} required className={classNames({ 'p-invalid': submitted && !values.txtCorrelativoInicial })} />
                                {submitted && !values.txtCorrelativoInicial && <small className="p-invalid">{errors.txtCorrelativoInicial}</small>}
                            </div>
							<div className="p-field p-col">
                            	<label htmlFor="txtCorrelativoSig">Correlativo Siguiente</label>
                                <InputNumber name="txtCorrelativoSig" value={values.txtCorrelativoSig} showButtons useGrouping={false} onValueChange={handleInputChange} disabled={disableCorrSig} />
								
                            </div>
							<div className="p-field p-col">
                            	<label htmlFor="txtCorrelativoFinal">Correlativo Final</label>
                                <InputNumber name="txtCorrelativoFinal" value={values.txtCorrelativoFinal} showButtons useGrouping={false} onValueChange={handleInputChange} />
								<small style={{fontStyle: 'italic'}}>Opcional</small>
                            </div>
                            
                        </div>

                    </Dialog>
					
					<Dialog visible={UptEstatusCorrelativoDialog} style={{ width: '450px' }} header="Confirmar" modal footer={updEstatusCorrelativoDialogFooter} onHide={hideUpdEstatusCorrelativoDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {correlativo && <span>Esta seguro de cambiarle el Estatus a  <b>{correlativo.txtNombre }</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCorrelativosDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteCorrelativosDialogFooter} onHide={hideDeleteCorrelativosDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {correlativo && <span>¿Esta Seguro de Eliminar el correlativo?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
}
