import React from 'react';
import {View, StyleSheet, Text } from '@react-pdf/renderer';
import FacturaItemsRows from './FacturaItemsRows'

const borderColor = '#EDF0F5'
const colorMain = '#EDF0F5';
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: colorMain,
    },
	container: {
        flexDirection: 'row',
        borderBottomColor: colorMain,
        backgroundColor: colorMain,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
		fontFamily: 'Helvetica-Bold',
        flexGrow: 1,
    },
    description: {
        width: '60%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    amount: {
        width: '15%'
    },
});

  const FacturaDetalles = ({factura}) => (
    <View style={styles.tableContainer}>
        <View style={styles.container}>
			<Text style={styles.qty}>Cant.</Text>
			<Text style={styles.description}>Item Descripción</Text>
			<Text style={styles.amount}>Precio Unit.</Text>
			<Text style={styles.amount}>Importe</Text>
    	</View>

        <FacturaItemsRows items={factura.items} moneda={factura.cboMoneda} txtMoneda={factura.txtMoneda} total_igtf={factura.txtTotalIGTF} total_igtf_con={factura.txtTotalIGTF_con} pct_igtf={factura.pct_igtf} />
		
    </View>
  );
  
  export default FacturaDetalles