import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData, configPermisosRW } from '../../service/AuthHelperMethods';
import { TabView, TabPanel } from 'primereact/tabview';
import swal from 'sweetalert';
import ShowLoading from "../../components/ShowLoading/ShowLoading";

const initialFValues = {
    txtContribuyente: '',
    caso: 0,
    nIdCliente_aux: 0,
    nIdUsuario_aux: 0,
    txtRazonSocial: '',
    txtRifCli: '',
    txtTelefono: '',
    txtMovil: '',
    txtPersonaContacto: '',
    txtEmail: '',
    txtWebSite: '',
    txtDireccion: '',
    txtObservacionCli: '',
	rbEsContribuyente: null,
    id: ''
};

export default function AdmClientes() {

    let emptyCliente = {
        txtRazonSocial: '',
        txtRifCli: '',
        txtTelefono: '',
        txtMovil: '',
        txtPersonaContacto: '',
        txtEmail: '',
        txtWebSite: '',
        txtDireccion: '',
        txtObservacionCli: '',
		rbEsContribuyente: null,
		id: ''
    };

	let location = useLocation();
	const [acceso, setAcceso] = useState("");

    const toast = useRef(null);
    const [ClienteDialog, setClienteDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [userData] = useState(getUserData() || null);
    const [httpService] = useState(new HttpService());
    const [clientes, setClientes] = useState(null);
    const [caso, setCaso] = useState(true);
    const [valiEmail, setValiEmail] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);

    useEffect(() => {
		setLoadingDialog(true);
		let acc = configPermisosRW(location, userData);
		setAcceso(acc);
        httpService.getTableSelect("tabla_clientes", userData.contribuyente)
		.then(data => {setClientes(data); setLoadingDialog(false);})
		.catch(err => setLoadingDialog(false));
    }, [httpService, userData, location]);

    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("txtRazonSocial" in fieldValues) temp.txtRazonSocial = fieldValues.txtRazonSocial ? "" : "Campo Requerido.";
        if ("txtRifCli" in fieldValues) temp.txtRifCli = fieldValues.txtRifCli ? "" : "Campo Requerido.";
        if ("txtTelefono" in fieldValues) temp.txtTelefono = fieldValues.txtTelefono ? "" : "Campo Requerido.";
        if ("txtMovil" in fieldValues) temp.txtMovil = fieldValues.txtMovil ? "" : "Campo Requerido.";
        if ("txtPersonaContacto" in fieldValues) temp.txtPersonaContacto = fieldValues.txtPersonaContacto ? "" : "Campo Requerido.";
        if ("txtEmail" in fieldValues) temp.txtEmail = fieldValues.txtEmail ? "" : "Campo Requerido.";
        if ("txtDireccion" in fieldValues) temp.txtDireccion = fieldValues.txtDireccion ? "" : "Campo Requerido.";
		if ("rbEsContribuyente" in fieldValues) temp.rbEsContribuyente = fieldValues.rbEsContribuyente ? "" : "Campo Requerido.";
        if ("txtEmail" in fieldValues) {
            if (fieldValues.txtEmail === "") {
                setValiEmail(true)
                temp.txtEmail = "Campo Requerido.";
            } else if (!(/$^|.+@.+..+/.test(fieldValues.txtEmail))) {
                setValiEmail(true)
                temp.txtEmail = "Email no valido.";
            } else {
                setValiEmail(false)
                temp.txtEmail = "";
            }
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    const handleSubmit = async (e) => {
        setSubmitted(true);
        e.preventDefault();

        if (validate()) {
            setButtonDisabled(true);
            values.txtContribuyente = userData.contribuyente;
            values.caso = caso;

            httpService.adminClientes(values)
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        let temp = { ...errors };
                        if (res.message.indexOf("Email") >= 0) {
                            temp.txtEmail = res.message;
                        }
                        setErrors({
                            ...temp,
                        });
                        swal({text: res.message,
                            icon: "error",
                            timer: "3000"});
                    } else {
                        //registro exitoso
                        swal({text: res.message,
                              icon: "success",
                            timer: "4000"});
                        values.nIdCliente_aux = res.nIdCliente_db;
                        setClienteDialog(false);
                        httpService.getTableSelect("tabla_clientes", userData.contribuyente).then(data => setClientes(data));
                        resetForm();
                    }
                })
                .catch((err) => {
                    swal({text: err.message,
                        icon: "error",
                        timer: "4000"});
                    setButtonDisabled(false);
                });
        }
    };

    const openNew = () => {
        // limpiarCB();
        setCaso(0);
        setValues(emptyCliente);
        // setValues(emptyCB);
        setSubmitted(false);
		setButtonDisabled(false);
        setClienteDialog(true);
    }

    const editCliente = (proveedor) => {
        setCaso(1);
        setValues(proveedor);
        setClienteDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setClienteDialog(false);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} disabled={ acceso && acceso === "W" ? false : true } />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={ acceso && acceso === "W" ? (!selectedClientes || !selectedClientes.length) : true  } />
            </React.Fragment>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Administrador de Clientes</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const ClientesDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmit} />
        </>
    );

    //Datos Para Clientes
	const RazonSocialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Razon Social</span>
                {rowData.txtRazonSocial}
            </>
        );
    }

    const RifBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Rif/Dni</span>
                {rowData.txtRifCli}
            </>
        );
    }

    const PContactoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Persona Contacto</span>
                {rowData.txtPersonaContacto}
            </>
        );
    }

    const TelefonoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Telefono</span>
                {rowData.txtTelefono}
            </>
        );
    }

    const EmailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.txtEmail}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-mr-2" onClick={() => editCliente(rowData)} disabled={ acceso && acceso === "W" ? false : true } />
            </div>
        );
    }

    const [deleteClientesDialog, setDeleteClientesDialog] = useState(false);
    const [selectedClientes, setSelectedClientes] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const hideDeleteClientesDialog = () => {
        setDeleteClientesDialog(false);
    }

    const confirmDeleteSelected = () => {
        setDeleteClientesDialog(true);
    }

    const deleteSelectedClientes = async (e) => {
        e.preventDefault();
        httpService.deleteClientes(selectedClientes)
            .then((res) => {
                if (res.error) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                } else {
                    //Eliminacion exitosa
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                    setDeleteClientesDialog(false);
                    setSelectedClientes(null);
                    httpService.getTableSelect("tabla_clientes", userData.contribuyente, "").then(data => setClientes(data));
                }
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
    }

    const deleteClientesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteClientesDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedClientes} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <DataTable ref={dt} value={clientes} selection={selectedClientes} onSelectionChange={(e) => setSelectedClientes(e.value)}
                        dataKey="id" paginator rows={10} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} clientes"
                        globalFilter={globalFilter} emptyMessage="No existen Clientes." header={header} >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="txtRazonSocial" header="Razon Social" sortable body={RazonSocialBodyTemplate}></Column>
                        <Column field="txtRifCli"  sortable header="Rif/Dni" body={RifBodyTemplate} ></Column>
                        <Column field="txtPersonaContacto" header="Persona Contacto" sortable body={PContactoBodyTemplate}></Column>
                        <Column field="txtTelefono" header="Telefono" sortable body={TelefonoBodyTemplate}></Column>
                        <Column field="txtEmail" header="Email" sortable body={EmailBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                    <Dialog visible={ClienteDialog} maximizable modal style={{ width: '50vw' }} header="Detalles del Cliente" className="p-fluid" footer={ClientesDialogFooter} onHide={hideDialog}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        <TabView className="tabview-custom">
                            <TabPanel header="Datos Básicos" leftIcon="pi pi-user">
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtRazonSocial">Nombre o Razón Social</label>
                                        <InputText name="txtRazonSocial" value={values.txtRazonSocial} onChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtRazonSocial })} />
                                        {submitted && !values.txtRazonSocial && <small className="p-invalid">{errors.txtRazonSocial}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtRifCli">RIF / DNI</label>
                                        <InputText name="txtRifCli" value={values.txtRifCli} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtRifCli })} />
                                        {submitted && !values.txtRifCli && <small className="p-invalid">{errors.txtRifCli}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
									<div className="p-field p-col">
										<label htmlFor="rbEsContribuyente">¿Es Contribuyente?</label>
										<div className="p-grid">
											<div className="p-col-12 p-md-6">
												<div className="p-field-radiobutton">
													<RadioButton inputId="rbEsContribuyenteSi" name="rbEsContribuyente" value="1" checked={values.rbEsContribuyente === 1} onChange={handleInputChange} />
													<label htmlFor="rbEsContribuyenteSi">Si</label>
												</div>
											</div>
											<div className="p-col-12 p-md-6">
												<div className="p-field-radiobutton">
													<RadioButton inputId="rbEsContribuyenteNo" name="rbEsContribuyente" value="0" checked={values.rbEsContribuyente === 0} onChange={handleInputChange} />
													<label htmlFor="rbEsContribuyenteNo">No</label>
												</div>
											</div>
											{submitted && !values.rbEsContribuyente && <small className="p-invalid">{errors.rbEsContribuyente}</small>}
										</div>
									</div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtPersonaContacto">Persona Contacto</label>
                                        <InputText name="txtPersonaContacto" value={values.txtPersonaContacto} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtPersonaContacto })} />
                                        {submitted && !values.txtPersonaContacto && <small className="p-invalid">{errors.txtPersonaContacto}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtTelefono">Teléfono</label>
                                        <InputText name="txtTelefono" value={values.txtTelefono} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtTelefono })} />
                                        {submitted && !values.txtTelefono && <small className="p-invalid">{errors.txtTelefono}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtMovil">Móvil</label>
                                        <InputText name="txtMovil" value={values.txtMovil} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtMovil })} />
                                        {submitted && !values.txtMovil && <small className="p-invalid">{errors.txtMovil}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtEmail">Email</label>
                                        <InputText name="txtEmail" value={values.txtEmail} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && valiEmail })} />
                                        {submitted && valiEmail && <small className="p-invalid">{errors.txtEmail}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtWebSite">Web Site</label>
                                        <InputText name="txtWebSite" value={values.txtWebSite} onChange={handleInputChange}  />
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtDireccion">Dirección</label>
                                        <InputTextarea name="txtDireccion" value={values.txtDireccion} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtDireccion })} />
                                        {submitted && !values.txtDireccion && <small className="p-invalid">{errors.txtDireccion}</small>}
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Notas" leftIcon="pi pi-comment">
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtObservacionCli">Observación</label>
                                        <InputTextarea name="txtObservacionCli" value={values.txtObservacionCli} onChange={handleInputChange} autoFocus />
                                    </div>
                                </div>
                            </TabPanel>
                        </TabView>
                    </Dialog>
                    <Dialog visible={deleteClientesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteClientesDialogFooter} onHide={hideDeleteClientesDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            { <span>Esta Seguro de eliminar el cliente seleccionado?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
}
