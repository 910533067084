import React, { useRef } from "react";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { Tooltip as TooltipMaterial, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "#f8f9fa",
  border: `1px solid #c6c7c8`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  background: "rgba( 75, 78, 89, 0.1 )",
  backdropFilter: "blur( 5.5px )",
  WebkitBackdropFilter: "blur(5.5px)",
  border: "1px solid rgba( 255, 255, 255, 0.18 )",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function TablaGeneral({
  data,
  fechaDesde,
  fechaHasta,
  razonSocial,
  direccion,
  rif,
  exportExcel,
  exportPdf
}) {
  const toast = useRef(null);
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <TooltipMaterial title="Exportar Excel">
          <Button
            type="button"
            icon="pi pi-file-excel"
            onClick={exportExcel}
            className="p-button-success p-mr-2"
            data-pr-tooltip="XLS"
          />
        </TooltipMaterial>
        <TooltipMaterial title="Exportar PDF">
          <Button
            type="button"
            icon="pi pi-file-pdf"
            onClick={exportPdf}
            className="p-button-warning p-mr-2"
            data-pr-tooltip="PDF"
          />
        </TooltipMaterial>
      </React.Fragment>
    );
  };

  const formatNumber = (value) => {
    const numberConv = Number(value);
    const numericValue = numberConv.toFixed(2);
    const parts = numericValue.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  };

  const formatDate = (dateStr) => {
    let [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <div style={{ width: "100%", padding: "7px" }}>
        <Box
          sx={{
            width: "100%",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "3px",
            boxShadow: "0 2px 3px rgb(0,0,0,0.2)",
          }}
        >
          <Toast ref={toast} />
          <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>
          <Tooltip target=".export-buttons>button" position="bottom" />
          <div style={{ width: "100%" }}>
          <Box sx={{
              borderRadius: "3px",
              border: "1px solid #0003",
              marginBottom: "2%",
              p: 2
            }}>
            <Box
              sx={{
                width: "100%",
                height: "4vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.15rem",
                fontWeight: 700,
                pr: 3,
                pl: 3,
              }}
            >
              {razonSocial.toUpperCase()}
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "4vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.15rem",
                fontWeight: 400,
                pr: 3,
                pl: 3,
              }}
            >
              {direccion.toUpperCase()}
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "4vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.15rem",
                fontWeight: 400,
                pr: 3,
                pl: 3,
              }}
            >
              {"RIF: " + rif.toUpperCase()}
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "4vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.15rem",
                fontWeight: 400,
                pr: 3,
                pl: 3,
              }}
            >
              {`Fecha Desde: ${formatDate(
                fechaDesde
              )} - Fecha Hasta: ${formatDate(fechaHasta)}`}
            </Box>
            </Box>

            {data.map((item) => (
              <Box sx={{ width: "100%" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                      {item.ctaNombre}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ boxShadow: "0 2px 3px rgb(0,0,0,0.2)"}}>
                      {data ? (
                        <>
                          <Box
                            sx={{
                              width: "100%",
                              height: "8vh",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              backgroundColor: "rgb(2,136,209, 0.8)",
                              border: "1px solid #0003",
                              borderRadius: "3px 3px 0px 0px",
                              fontSize: "1.15rem",
                              fontWeight: 700,
                              pr: 3,
                              pl: 3,
                            }}
                          >
                            Saldo Inicial: {formatNumber(item.saldoAnterior)}
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              height: "8vh",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#F8F9FA",
                              border: "1px solid #0003",
                              pr: 3,
                              pl: 3,
                            }}
                          >
                            <Box
                              sx={{
                                width: "30%",
                                fontWeight: 500,
                                fontSize: "1rem",
                              }}
                            >
                              Fecha Asiento
                            </Box>
                            <Box
                              sx={{
                                width: "30%",
                                fontWeight: 500,
                                fontSize: "1rem",
                              }}
                            >
                              Nº Asiento
                            </Box>
                            <Box
                              sx={{
                                width: "60%",
                                fontWeight: 500,
                                fontSize: "1rem",
                              }}
                            >
                              Concepto
                            </Box>
                            <Box
                              sx={{
                                width: "35%",
                                fontWeight: 500,
                                fontSize: "1rem",
                              }}
                            >
                              Debe
                            </Box>
                            <Box
                              sx={{
                                width: "35%",
                                fontWeight: 500,
                                fontSize: "1rem",
                              }}
                            >
                              Haber
                            </Box>
                            <Box
                              sx={{
                                width: "35%",
                                fontWeight: 500,
                                fontSize: "1rem",
                              }}
                            >
                              Saldo
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                      {data ? (
                        item.movimientos.map((a, index) => (
                          <Box
                            sx={{
                              width: "100%",
                              height: "8vh",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "white",
                              border: "1px solid #0003",
                              pr: 3,
                              pl: 3,
                            }}
                            key={index}
                          >
                            <Box
                              sx={{
                                width: "30%",
                                fontWeight: 500,
                                fontSize: "1rem",
                              }}
                            >
                              {formatDate(a.dtFechaAsiento)}
                            </Box>
                            <Box
                              sx={{
                                width: "30%",
                                fontWeight: 500,
                                fontSize: "1rem",
                              }}
                            >
                              {a.nNroAsiento}
                            </Box>
                            <Box
                              sx={{
                                width: "60%",
                                fontWeight: 500,
                                fontSize: "1rem",
                              }}
                            >
                              {a.sConcepto}
                            </Box>
                            <Box
                              sx={{
                                width: "35%",
                                fontWeight: 500,
                                fontSize: "1rem",
                              }}
                            >
                              {a.cTipoRegMov === "D"
                                ? formatNumber(a.deMontoMovCont)
                                : "0,00"}
                            </Box>
                            <Box
                              sx={{
                                width: "35%",
                                fontWeight: 500,
                                fontSize: "1rem",
                              }}
                            >
                              {a.cTipoRegMov === "H"
                                ? formatNumber(a.deMontoMovCont)
                                : "0,00"}
                            </Box>
                            <Box
                              sx={{
                                width: "35%",
                                fontWeight: 500,
                                fontSize: "1rem",
                              }}
                            >
                              {formatNumber(a.saldo)}
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <></>
                      )}
                      <Box
                        sx={{
                          width: "100%",
                          height: "8vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          backgroundColor: "rgb(2,136,209,0.8)",
                          border: "1px solid #0003",
                          borderRadius: "0px 0px 3px 3px",
                          fontSize: "1.15rem",
                          fontWeight: 700,
                          pr: 3,
                          pl: 3,
                        }}
                      >
                        Saldo Final: {formatNumber(item.saldoFinal)}
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}

          </div>
        </Box>
      </div>
    </>
  );
}
