import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register('../firebase-messaging-sw.js')
	.then(function(registration) {
		console.log('Registration successful, scope is:', registration.scope);
	}).catch(function(err) {
		console.log('Service worker registration failed, error:', err);
	});
}

ReactDOM.render(
    <Router>
        <ScrollToTop>
            <App></App>
        </ScrollToTop>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();