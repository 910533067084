import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import Swal from "sweetalert2";
import Constantes from "../../../../Constantes";
import { useHistory } from "react-router-dom";

const useFormikCreateAsiento = (data) => {
  const history = useHistory()

  const [loadingFormik] = useState(false);

  const [disabledFormik] = useState(false);

  const notifySuccess = () => {
    Swal.fire(
      'Asiento Guardado',
      'Creación de Asiento Éxitoso',
      'success'
    )
  }

  const notifyServerError = () => {
    Swal.fire({
      title: "Error!",
      text: "Ha ocurrido un error de Servidor",
      icon: "error",
      confirmButtonText: "Cool",
    });
  };

  const formik = useFormik({
    initialValues: {
      IdContribuyente: data.IdContribuyente,
      FechaAsiento: data.FechaAsiento,
      Descripcion: "",
      idUsuarioCreate: data.idUsuarioCreate,
      IdUsuarioUpdate: data.idUsuarioCreate,
      Detalle: data.Detalle
    },
    
    validate: (values) => {
      const errors = {};

      let fechaAsientoCheck = null;

      if (data.FechaAsiento === "") {
        errors.FechaAsiento = "Falta la fecha"
      } else {
        // eslint-disable-next-line no-unused-vars
        fechaAsientoCheck = true;
      }

      let descripcionCheck = null;
      if (!data.Detalle.length ) {
        errors.Detalle = "no hay items para crear"
      } else {
        descripcionCheck = true;
      }

      if (data.Descripcion === "") {
        errors.Descripcion = "*Debes ingresar una descripción";
      } else {
        // eslint-disable-next-line no-unused-vars
        descripcionCheck = true;
      }

      if (data.IdContribuyente === undefined || null) {
        errors.IdContribuyente = "falta el id contribuyente"
      } else {
        console.log("")
      }

      return errors;
    },
    onSubmit: (values) => {
      axios
        .post(
          `${Constantes.RUTA_API}/admin_asientos_contables.php`,
          {
            IdContribuyente: data.IdContribuyente,
            FechaAsiento: data.FechaAsiento,
            Descripcion: values.Descripcion,
            idUsuarioCreate: values.idUsuarioCreate,
            IdUsuarioUpdate: values.IdUsuarioUpdate,
            Detalle: data.Detalle,
          }
        )
        .then((res) => {
          notifySuccess(); 
          setTimeout(() => {
          history.push('/asientos_contables')
        }, 2500)})
        .catch((err) => notifyServerError());
    },
    validateOnBlur: true,
    validateOnChange: false,
  });
  return {
    formik,
    loadingFormik,
    disabledFormik,
  };
};

export default useFormikCreateAsiento;
