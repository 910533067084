import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import RetencionesDetallesISLR from './RetencionesDetallesISLR'

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        // paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 74,
        height: 66,
        marginTop: 24,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    titleContainer: {
        flexDirection: 'row',
        //marginTop: 24,
        textAlign: 'center',
    },
    titleTipoCompro: {
        flexDirection: 'row',
        marginTop: 24,
        textAlign: 'center',
    },
    titleContainer2: {
        flexDirection: 'row',
        //marginTop: 24,
        fontSize: 9,
        textAlign: 'center',
    },
    reportTitle: {
        // color: '#61dafb',
        letterSpacing: 4,
        fontSize: 11,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    reportTitle2: {
        // color: '#61dafb',
        letterSpacing: 4,
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    invoiceNoContainer: {
        marginTop: 24,
        flexDirection: 'row',
        //justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
        // width: "20%",
    },
    invoiceComp: {
        color: 'blue',
        fontSize: 12,
        fontStyle: 'bold',
        textAlign: 'left',
        // width: "20%",
    },
    label: {
        width: "80%",
        textAlign: 'left',
    },
    label_left: {
        width: "40%",
        textAlign: 'left',
        fontStyle: 'bold'
    },
    headerContainer: {
        marginTop: 24
    },
    billTo: {
        //marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
        fontStyle: 'bold',
    },
});

Font.register({
    family: "Oswald",
    src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

export default function RetencionesISLR(props) {
    let retencion = props.data;
    return (
        <Document>
            <Page size="letter" orientation="landscape" style={styles.page}>
                <Image style={styles.logo} src={retencion.Logo} />
                <View style={styles.titleContainer}>
                    <Text style={styles.reportTitle}>{retencion.sRazonSocial}</Text>
                </View>
                <View style={styles.titleTipoCompro}>
                    <Text style={styles.reportTitle}>Comprobante de Retención de {retencion.sTipoComprobante}</Text>
                </View>
                <View style={styles.invoiceNoContainer}>
                    <Text style={styles.label}>N° de Comprobante: {retencion.nNroComprobante}</Text>
                    <Text style={styles.label}>Fecha: {retencion.dtFechaComprobante}</Text>
                    <Text style={styles.label}>Fecha de Entrega:{retencion.dtFechaEntrega} </Text>
                </View >
                <View style={styles.headerContainer}>
                    <Text><p style={styles.label_left}>Nombre o Razón Social del Agente de Retención:</p> {retencion.sRazonSocial}</Text>
                    <Text><p style={styles.label_left}>Registro de Información Fiscal del Agente de Retención:</p> {retencion.sRif}</Text>
                    <Text><p style={styles.label_left}>Dirección Fiscal del Agente de Retención:</p> {retencion.sDireccion}</Text>
                    <Text><p style={styles.label_left}>Periddo Fiscal: </p> Año: {retencion.nAnoFiscal} / Mes: {retencion.nMesFiscal}</Text>
                    <Text><p style={styles.label_left}>Nombre o Razón Social del Sujeto Retenido:</p> {retencion.sRazonSocialProv}</Text>
                    <Text><p style={styles.label_left}>Registro de Información Fiscal del Sujeto Retenido:</p> {retencion.sRifProv}</Text>
                </View>
                <RetencionesDetallesISLR retencion={retencion} />

                {retencion.deTasaConversion !== "0.00" && (
                    <View style={styles.headerContainer}>
                        <Text><p style={styles.label_left}>(Tasa de cambio {retencion.deTasaConversion} por {retencion.sMoneda})</p></Text>
                    </View>
                )}
            </Page>
        </Document>
    );
}
