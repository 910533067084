import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { HttpService } from "../../service/HttpService";
import { getUserData } from "../../service/AuthHelperMethods";
import ShowLoading from "../../components/ShowLoading/ShowLoading";

export const DeberesFormales = () => {
    const [httpService] = useState(new HttpService());
    const [userData] = useState(getUserData() || null);
    const [deberesFormales, setDeberesFormales] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
	const [loadingDialog, setLoadingDialog] = useState(false);

    useEffect(() => {
		setLoadingDialog(true);
        let isSubscribed = true;
        httpService.getGeneralData("deberes_formales", userData.contribuyente)
		.then((data) => { setDeberesFormales(data); setLoadingDialog(false); })
		.catch(err => setLoadingDialog(false));
        return () => (!isSubscribed);
    }, [httpService, userData]);

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.nombre}
            </>
        );
    };

    const frecuenciaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Frecuencia</span>
                {rowData.frecuencia}
            </>
        );
    };

    /* const vencimientoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Vencimiento</span>
                {rowData.vencimiento}
            </>
        );
    }; */

    const tributoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tributo</span>
                {rowData.tributo}
            </>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Deberes Formales</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <DataTable
                        ref={dt}
                        value={deberesFormales}
                        dataKey="id"
                        paginator
                        rows={10}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} Deberes Formales"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron deberes formales."
                        header={header}
                    >
                        <Column field="id" header="ID" headerStyle={{ width: "30%", display: "none" }} bodyStyle={{ display: "none" }} body={codeBodyTemplate}></Column>
                        <Column field="nombre" header="Nombre" sortable headerStyle={{ width: "60%" }} body={nameBodyTemplate}></Column>
                        <Column field="tributo" header="Tributo" sortable headerStyle={{ width: "20%" }} body={tributoBodyTemplate}></Column>
                        <Column field="frecuencia" header="Frecuencia" sortable headerStyle={{ width: "20%" }} body={frecuenciaBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
};
