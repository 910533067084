import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";

function TextMaskCustom(props) {
    const { inputRef, type, ...other } = props;

    const firstLetterCedula = /[VE]/i;
    const firstLetterRif = /[VEPJG]/i;
    // const letter = /(?!.*[DFIOQU])[A-Z]/i;
    // const letter = /[A-Z]/i;
    const digit = /[0-9]/;

    const getMask = (tipo) => {
        switch (tipo) {
            case "cedula":
                return [firstLetterCedula, "-", digit, digit, digit, digit, digit, digit, digit, digit];
            case "rif":
                return [firstLetterRif, "-", digit, digit, digit, digit, digit, digit, digit, digit, "-", digit];
            default:
                break;
        }
    };

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={getMask(type)}
            placeholderChar={"\u2000"}
            //showMask
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export default function InputTextMask(props) {
    const { name, label, value, error = null, onChange, type, fullWidth, autoFocus, variant, size, className, placeholder, margin } = props;

    return (
        <TextField
            className={className}
            placeholder={placeholder}
            variant={variant}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            type={type}
            fullWidth={fullWidth}
            autoFocus={autoFocus}
            size={size}
			margin={margin}
            onClick={(e) => e.target.setSelectionRange(0, 0)}
            {...(error && { error: true, helperText: error })}
            InputProps={{
                inputComponent: TextMaskCustom,
            }}
        />
    );
}
