import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import Swal from "sweetalert2";

import Constantes from "../../../Constantes";
import { HttpService } from "../../../service/HttpService";
import { getUserData } from "../../../service/AuthHelperMethods";
import useFormikStates from "./states/useFormikStates";

import {
  Box,
  Typography,
  TextField,
  Divider,
  Button,
  IconButton,
  MenuItem,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/es"

export default function CrearAsientoContable() {
  const [montoAsiento, setMontoAsiento] = useState("");
  const [tipoMovimiento, setTipoMovimiento] = useState("");
  const [cuenta, setCuenta] = useState([]);
  const [selectCuenta, setSelectCuenta] = useState([]);
  const [items, setItems] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [date, setDate] = useState("");
  const [httpService] = useState(new HttpService());
  const [userData] = useState(getUserData() || null);
  const userId = userData.idUsuario;

  const asientoItems = items?.map((i) => ({
    Cuenta: i.Cuenta,
    MontoItems: i.MontoItems.replace(/[,.](?!\d{2}$)/g, "").replace(",", "."),
    TipoMov: i.TipoMov === "Debe" ? "D" : "H",
  }));
  const data = {
    FechaAsiento: date,
    Detalle: asientoItems,
    idUsuarioCreate: userId,
    IdContribuyente: cliente?.map((id) => id.nIdContribuyente).shift(),
  };
  const { formik } = useFormikStates(data);

  useEffect(() => {
    httpService
      .getTableSelect("tabla_clientes", userData.contribuyente)
      .then((res) => {
        setCliente(res);
      })
      .catch((error) => console.log(error));
  }, [httpService, userData]);

  useEffect(() => {
    const cuentasContablesCall = () => {
      axios
        .get(
          `${Constantes.RUTA_API}/get_options_select.php?tipoDato=lista_cuentas_contables&IdContribuyente=${cliente
            ?.map((id) => id.nIdContribuyente)
            .shift()}`
        )
        .then((res) => {
          setCuenta(res.data);
        })
        .catch((error) => console.log(error));
    };
    cuentasContablesCall();
  }, [cliente]);

  const formatNumber = (value) => {
    // Remove non-numeric characters from the input value
    const numericValue = value.toString().replace(/\D/g, "");
    // Convert the numeric value to a number and divide by 100 to move the decimal point two places to the left
    const numberValue = Number(numericValue) / 100;
    // Format the number value with a dot as a thousand separator and a comma as a decimal separator
    return numberValue.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleChange = (event) => {
    const newValue = formatNumber(event.target.value);
    setMontoAsiento(newValue);
  };

  const movimientos = [
    { value: "Debe", key: "D" },
    { value: "Haber", key: "H" },
  ];

  const handleAddItem = (data) => {
    const newItem = {
      Cuenta: data.selectCuenta.id,
      CuentaTitle: data.selectCuenta.title,
      MontoItems: data.montoAsiento,
      TipoMov: data.tipoMovimiento,
    };
    setItems([...items, newItem]);
    setTipoMovimiento("");
    setSelectCuenta("");
    setMontoAsiento("");
  };

  const handleDelete = (indexToDelete) => {
    // Make a copy of the array
    const newArr = [...items];
    // Remove the object at the specified index
    newArr.splice(indexToDelete, 1);
    // Update the state of the component with the new array
    setItems(newArr);
  };

  const handleChangeDate = (e) => {
    const newDate = dayjs(e);
    const formattedDate = newDate ? newDate.format("YYYY-MM-DD") : "";
    setDate(formattedDate);
  };

  const montosD = items?.map((m) =>
    m.TipoMov === "Debe" ? Number(m.MontoItems.replace(/[.,]/g, "")) : 0
  );
  const montosH = items?.map((m) =>
    m.TipoMov === "Haber" ? Number(m.MontoItems.replace(/[.,]/g, "")) : 0
  );
  const totalAmountsD = montosD.length
    ? montosD?.reduce((a, b) => a + b)
    : null;
  const totalAmountsH = montosH.length
    ? montosH?.reduce((a, b) => a + b)
    : null;

  const notifyEqualAmountsError = () => {
    Swal.fire({
      title: "Aviso!",
      text: "Los Montos de Debe y Haber no son iguales",
      icon: "info",
      confirmButtonText: "Cool",
    });
  };

  const notifyItemsError = () => {
    Swal.fire({
      title: "Error!",
      text: "Debes Agregar un Item",
      icon: "error",
      confirmButtonText: "Cool",
    });
  };
  const notifyDateError = () => {
    Swal.fire({
      title: "Error!",
      text: "Debes Agregar una Fecha",
      icon: "error",
      confirmButtonText: "Cool",
    });
  };
  const notifyDescriptionError = () => {
    Swal.fire({
      title: "Error!",
      text: "Debes Agregar una Descripción",
      icon: "error",
      confirmButtonText: "Cool",
    });
  };
  
  /* const notifyServerError = () => {
    Swal.fire({
      title: "Error!",
      text: "Ha ocurrido un error de Servidor",
      icon: "error",
      confirmButtonText: "Cool",
    });
  }; */

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        borderRadius: "3px",
        border: "1px solid #0002",
        p: 3,
      }}
    >
      <Typography sx={{ fontWeight: "500", fontSize: "1.25rem", pb: 2 }}>
        Nuevo Asiento Contable
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 2,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker
            sx={{ width: "48%" }}
            label="Fecha Asiento Contable"
            name="FechaAsiento"
            onChange={(e) => handleChangeDate(e)}
            inputFormat="DD/MM/YYYY"
          />
        </LocalizationProvider>
        <TextField
          label="Descripción"
          placeholder="Ingrese una descripción"
          name="Descripcion"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.Descripcion}
          error={formik.errors.Descripcion ? true : false}
          helperText={
            formik.errors.Descripcion && formik.touched.Descripcion
              ? formik.errors.Descripcion
              : undefined
          }
          sx={{ width: "48%" }}
        />
      </Box>
      <Divider sx={{ width: "100%", mt: 2 }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          p: 2,
          mt: 2,
          backgroundColor: "#F8F9FA",
          border: "1px solid #0002",
          borderRadius: "3px 3px 3px 3px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", gap: 2, width: "80%" }}
        >
          <TextField
            label="Cuenta"
            select
            sx={{ width: "100%" }}
            value={selectCuenta?.title || ""}
          >
            {cuenta?.map((acc) => (
              <MenuItem
                key={acc.id}
                value={acc.title}
                onClick={() => setSelectCuenta(acc)}
              >
                {acc.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Tipo de Movimiento"
            select
            sx={{ width: "100%" }}
            value={tipoMovimiento}
          >
            {movimientos.map((option) => (
              <MenuItem
                key={option.key}
                value={option.value}
                onClick={() => setTipoMovimiento(option.value)}
              >
                {option.value}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Monto Asiento"
            type="text"
            value={montoAsiento}
            onChange={handleChange}
            sx={{ width: "60%" }}
          />
        </Box>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          sx={{ backgroundColor: "#0288D1F", width: "15%" }}
          onClick={() =>
            handleAddItem({ selectCuenta, tipoMovimiento, montoAsiento })
          }
        >
          Agregar Item
        </Button>
      </Box>

      <Box sx={{ mt: 2, borderRadius: "3px 3px 0px 0px" }}>
        <Box
          sx={{
            width: "100%",
            height: "9vh",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F8F9FA",
            border: "1px solid #0003",
            pr: 3,
            pl: 3,
          }}
        >
          <Box sx={{ width: "40%", fontWeight: 500, fontSize: "1rem" }}>
            Cuenta
          </Box>
          <Box sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}>
            Debe
          </Box>
          <Box sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}>
            Haber
          </Box>
          <Box sx={{ width: "5%" }}></Box>
        </Box>
        {items &&
          items?.map((i, index) => (
            <>
              <Box
                sx={{
                  width: "100%",
                  height: "8vh",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  border: "1px solid #0003",
                  pr: 3,
                  pl: 3,
                }}
                key={index}
              >
                <Box sx={{ width: "40%", fontWeight: 500, fontSize: "1rem" }}>
                  {i.CuentaTitle}
                </Box>
                <Box sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}>
                  {i.TipoMov === "Debe" ? formatNumber(i.MontoItems) : 0}
                </Box>
                <Box sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}>
                  {i.TipoMov === "Haber" ? formatNumber(i.MontoItems) : 0}
                </Box>
                <Box sx={{ width: "5%", fontWeight: 500, fontSize: "1rem" }}>
                  <Tooltip title="Eliminar Item">
                    <IconButton>
                      <DeleteIcon onClick={() => handleDelete(index)} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </>
          ))}
        <Box
          sx={{
            width: "100%",
            height: "9vh",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F8F9FA",
            border: "1px solid #0003",
            pr: 3,
            pl: 3,
          }}
        >
          <Box sx={{ width: "40%", fontWeight: 500, fontSize: "1rem" }}>
            Total Asiento
          </Box>
          <Box sx={{ width: "30%", fontWeight: 700, fontSize: "1rem" }}>
            {totalAmountsD ? formatNumber(totalAmountsD) : ""}
          </Box>
          <Box sx={{ width: "30%", fontWeight: 700, fontSize: "1rem" }}>
            {totalAmountsH ? formatNumber(totalAmountsH) : ""}
          </Box>
          <Box sx={{ width: "5%" }}></Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <Button
          variant="contained"
          sx={{
            mt: 2,
          }}
          onClick={() => {
            totalAmountsD !== totalAmountsH
              ? notifyEqualAmountsError()
              : !items.length
              ? notifyItemsError()
              : !date
              ? notifyDateError()
              : formik.values.Descripcion === ""
              ? notifyDescriptionError()
              : formik.handleSubmit();
          }}
        >
          Guardar
        </Button>
      </Box>
    </Box>
  );
}
