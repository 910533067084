import React from 'react';

export const AppFooter = () => {

    return (
        <div className="layout-footer">
            <span className="footer-text" style={{ 'marginRight': '5px' }}>Sistema de Asesoria Contable</span>
            <img src="assets/layout/images/logo-black.png" alt="Logo" />
            <span className="footer-text" style={{ 'marginLeft': '5px' }}>© Copyright</span>
        </div>
    );
}
