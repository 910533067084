import React, { useState, useEffect, useRef } from 'react';
import { getTokenFirebase, firebase_messaging } from './firebase';
import { onMessage } from "firebase/messaging";
import classNames from 'classnames';
import { Route, Switch, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { loggedIn, logout, getUserData, getTokenFCM } from './service/AuthHelperMethods';
import PrivateRoute from './routers/PrivateRoute';

//components
import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';

// Pages
import Login from "./pages/Login/index.js";
import Registro from './pages/Registro/Registro';
import OlvideClave from './pages/OlvideClave/OlvideClave';
import SelectContribuyente from './pages/SelectContribuyente/SelectContribuyente';
import { Dashboard } from './pages/Dashboard/Index.js';
import { FormLayoutDemo } from './pages/FormLayoutDemo/Index.js';
import { InputDemo } from './pages/InputDemo/Index.js';
import { FloatLabelDemo } from './pages/FloatLabelDemo/Index.js';
import { InvalidStateDemo } from './pages/InvalidStateDemo/Index.js';
import { ButtonDemo } from './pages/ButtonDemo/Index.js';
import { TableDemo } from './pages/TableDemo/Index.js';
import { ListDemo } from './pages/ListDemo/Index.js';
import { TreeDemo } from './pages/TreeDemo/Index.js';
import { PanelDemo } from './pages/PanelDemo/Index.js';
import { OverlayDemo } from './pages/OverlayDemo/Index.js';
import { MenuDemo } from './pages/MenuDemo/Index.js';
import { MessagesDemo } from './pages/MessagesDemo/Index.js';
import { FileDemo } from './pages/FileDemo/Index.js';
import { ChartDemo } from './pages/ChartDemo/Index.js';
import { MiscDemo } from './pages/MiscDemo/Index.js';
import { Documentation } from './pages/Documentation/Index.js';
import AdmProductos from './pages/AdmProductos/AdmProductos';
import AdmProveedores from './pages/AdmProveedores/index.js';
import AdmUsuario from './pages/AdmUsuario/AdmUsuario';
import { AdmPerfiles } from './pages/AdmPerfiles/AdmPerfiles';
import AdmTipoProductos from './pages/AdmTipoProductos/AdmTipoProductos';
import AdmClientes from './pages/AdmClientes/AdmClientes';
import AdmTasaCambio from './pages/AdmTasaCambio/AdmTasaCambio';
import AdmCorrelativos from './pages/AdmCorrelativos';
import AdmDeclaraciones from './pages/AdmDeclaraciones';
import { CambioDatos } from './pages/CambioDatos/CambioDatos';
import CambioClave from './pages/CambioClave/index.js';
import { Calendar } from './pages/Calendar';
import { Crud } from './pages/Crud';
import { EmptyPage } from './pages/EmptyPage';
import { DeberesFormales } from './pages/DeberesFormales/DeberesFormales';
import { Notificaciones } from './pages/Notificaciones/Notificaciones';
import { OrdenDeCompra } from './pages/OrdenDeCompra/OrdenDeCompra.js';
import ConsultaDeCompras from './pages/ConsultaDeCompras/ConsultaDeCompras';
import {LibroDeCompra} from './pages/LibroDeCompra/LibroDeCompra';
import { LibroDeVenta } from './pages/LibroDeVenta/LibroDeVenta';
import { LibroDiario } from './pages/LibroDiario/LibroDiario';
import LibroMayor from './pages/LibroMayor/LibroMayor';
import {LibroDeInventario} from './pages/LibroDeInventario/LibroDeInventario';
import RecepcionDeCompras from './pages/RecepcionDeCompras/RecepcionDeCompras';
import { RegistroVenta } from './pages/RegistroVenta/RegistroVenta';
import RetencionesDeCompras from './pages/RetencionesDeCompras/RetencionesDeCompras';
import ConsultaDeVentas from './pages/ConsultaDeVentas';
import { ResumenVentas } from './pages/ResumenVentas/ResumenVentas';
import AsientosContables from './pages/AsientosContables/AsientosContables';
import CrearAsientoContable from './pages/AsientosContables/CrearAsientoContable/CrearAsientoContable';
import PlanCuentas from './pages/PlanCuentas/PlanCuentas';


//utilities
import { ElevationDemo } from './utilities/ElevationDemo';
import { DisplayDemo } from './utilities/DisplayDemo';
import { FlexBoxDemo } from './utilities/FlexBoxDemo';
import { GridDemo } from './utilities/GridDemo';
import { IconsDemo } from './utilities/IconsDemo';
import { SpacingDemo } from './utilities/SpacingDemo';
import { TextDemo } from './utilities/TextDemo';
import { TypographyDemo } from './utilities/TypographyDemo';
import { TimelineDemo } from './utilities/TimelineDemo';

/* import PrimeReact from 'primereact/api'; */

//Styles
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/flags/flags.css';
import './layout/layout.scss';
import './App.scss';

import { Toast } from 'primereact/toast';

const Principal = (props) => {
	const toastFCM = useRef(null);
	const [/* isTokenFound */, setTokenFound] = useState(false);
		 
	onMessage(firebase_messaging, (payload) => {
		toastFCM.current.show({ severity: payload.data.severity, summary: payload.notification.title, detail: payload.notification.body, sticky: true });	
    });

    const [/* urlCurrent */, seturlCurrent] = useState(document.location.href);
    const [layoutMode, /* setLayoutMode */] = useState('static');
    const [layoutColorMode, /* setLayoutColorMode */] = useState('dark')
    const [inputStyle, /* setInputStyle */] = useState('outlined');
    const [ripple, /* setRipple */] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(true);

	const sidebar = useRef();
	const menu = [];
    const history = useHistory();
    let menuClick = false;

	useEffect(() => {
		function checkSesion() {
			if (!loggedIn()) {
				const userData = getUserData() || null;
				if (userData!==null)
					logout(userData);
				
				setTokenFound(false);
				history.push("/login");
				return;
			} else {
				const userData = getUserData() || null;
				const tokenFCM = getTokenFCM();
				getTokenFirebase(setTokenFound, userData, tokenFCM);
			}
		}
        checkSesion();

    }, [history])

    useEffect(() => {
        if (sidebarActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [sidebarActive]);

 /*    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    } */

    const onWrapperClick = (event) => {
        if (!menuClick && layoutMode === "overlay") {
            setSidebarActive(false);
        }
        menuClick = false;
    }

    const onToggleMenu = (event) => {
        menuClick = true;

        setSidebarActive((prevState) => !prevState);

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items && layoutMode === "overlay") {
            setSidebarActive(false);
        }
        seturlCurrent(document.location.href);
    }

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const isSidebarVisible = () => {
        return sidebarActive;
    };

    const logo = layoutColorMode === 'dark' ? 'assets/layout/images/logo-new.png' : 'assets/layout/images/logo.svg';

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-active': sidebarActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false
    });

    const sidebarClassName = classNames('layout-sidebar', {
        'layout-sidebar-dark': layoutColorMode === 'dark',
        'layout-sidebar-light': layoutColorMode === 'light'
    });

    return (
        <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/registro" exact component={Registro} />
            <Route path="/olvide_clave" exact component={OlvideClave} />
            <Route path="/seleccionar_contribuyente" exact component={SelectContribuyente} />
            <>
                <div className={wrapperClass} onClick={onWrapperClick}>
					
					<Toast ref={toastFCM} />
                    <AppTopbar onToggleMenu={onToggleMenu} />

                    <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                        <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
                            <div className="layout-logo" style={{ cursor: 'pointer' }} onClick={() => history.push('/')}>
                                <img alt="Logo" src={logo} />
                            </div>
                            <AppProfile />
                            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                        </div>
                    </CSSTransition>

                    <div className="layout-main">
                        {/* pages */}
                        <PrivateRoute path="/" exact component={Dashboard} />
                        <PrivateRoute path="/dashboard" exact component={Dashboard} />
                        <PrivateRoute path="/formlayout" component={FormLayoutDemo} />
                        <PrivateRoute path="/input" component={InputDemo} />
                        <PrivateRoute path="/floatlabel" component={FloatLabelDemo} />
                        <PrivateRoute path="/invalidstate" component={InvalidStateDemo} />
                        <PrivateRoute path="/button" component={ButtonDemo} />
                        <PrivateRoute path="/table" component={TableDemo} />
                        <PrivateRoute path="/list" component={ListDemo} />
                        <PrivateRoute path="/tree" component={TreeDemo} />
                        <PrivateRoute path="/panel" component={PanelDemo} />
                        <PrivateRoute path="/overlay" component={OverlayDemo} />
                        <PrivateRoute path="/menu" component={MenuDemo} />
                        <PrivateRoute path="/messages" component={MessagesDemo} />
                        <PrivateRoute path="/file" component={FileDemo} />
                        <PrivateRoute path="/misc" component={MiscDemo} />
                        <PrivateRoute path="/chart" component={ChartDemo} />
                        <PrivateRoute path="/documentation" component={Documentation} />
                        <PrivateRoute path="/admin_productos" component={AdmProductos} />
                        <PrivateRoute path="/admin_proveedores" component={AdmProveedores}/>
                        <PrivateRoute path="/adm_usuario" component={AdmUsuario}/>
						<PrivateRoute path="/adminperfiles" exact component={AdmPerfiles} />
                        <PrivateRoute path="/admin_tipo_productos" component={AdmTipoProductos}/>
						<PrivateRoute path="/admin_clientes" component={AdmClientes}/>
						<PrivateRoute path="/tasa_conversion" exact component={AdmTasaCambio}/>
                        <PrivateRoute path="/correlativos" exact component={AdmCorrelativos} />
						<PrivateRoute path="/declaraciones" exact component={AdmDeclaraciones} />
                        <PrivateRoute path="/cambio_clave" component={CambioClave} />
                        <PrivateRoute path="/cambiodatos" exact component={CambioDatos} />
                        <PrivateRoute path="/calendar" component={Calendar} />
                        <PrivateRoute path="/crud" component={Crud} />
                        <PrivateRoute path="/empty" component={EmptyPage} />
						<PrivateRoute path="/deberesformales" exact component={DeberesFormales} />
						<PrivateRoute path="/notificaciones" component={Notificaciones}/>
                        <PrivateRoute path="/orden_de_compra" exact component={OrdenDeCompra} />
                        <PrivateRoute path="/consulta_de_compras" exact component={ConsultaDeCompras}/>
                        <PrivateRoute path="/libro_de_compras" exact component={LibroDeCompra} />
						<PrivateRoute path="/libro_de_ventas" exact component={LibroDeVenta} />
						<PrivateRoute path="/libro_diario" exact component={LibroDiario} />
						<PrivateRoute path="/libro_mayor" exact component={LibroMayor} />
                        <PrivateRoute path="/libro_de_inventario" exact component={LibroDeInventario} />
                        <PrivateRoute path="/recepcion_de_compras" exact component={RecepcionDeCompras}/>
						<PrivateRoute path="/registro_venta" component={RegistroVenta}/>
						<PrivateRoute path="/consulta_de_ventas" exact component={ConsultaDeVentas}/>
                        <PrivateRoute path="/retencion_en_compras" exact component={RetencionesDeCompras} />
                        <PrivateRoute path="/resumen_ventas" exact component={ResumenVentas} />
						<PrivateRoute path="/asientos_contables" exact component={AsientosContables} />
						<PrivateRoute path="/crear_asiento" exact component={CrearAsientoContable} />
						<PrivateRoute path="/plan_de_cuentas" exact component={PlanCuentas} />

                        {/* Utilities?? */}
                        <PrivateRoute path="/elevation" component={ElevationDemo} />
                        <PrivateRoute path="/display" component={DisplayDemo} />
                        <PrivateRoute path="/flexbox" component={FlexBoxDemo} />
                        <PrivateRoute path="/grid" component={GridDemo} />
                        <PrivateRoute path="/icons" component={IconsDemo} />
                        <PrivateRoute path="/spacing" component={SpacingDemo} />
                        <PrivateRoute path="/text" component={TextDemo} />
                        <PrivateRoute path="/typography" component={TypographyDemo} />
                        <PrivateRoute path="/timeline" component={TimelineDemo} />

                    </div>
                    <AppFooter />
                </div>
            </> 
        </Switch>
    );

}

export default Principal;
