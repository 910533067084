import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function DatePicker(props) {

    const {name, label, value,  error = null, onChange, size, variant, margin, disabled=null } = props


    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker 
				disableToolbar 
                format="dd/MM/yyyy"
                label={label}
                name={name}
                value={value}
				variant="inline" 
				inputVariant={variant}
				size={size} 
				margin={margin}
				disabled={disabled}
                onChange={date =>onChange(convertToDefEventPara(name,date))}
				{...(error && {error:true,helperText:error})}
            />
			
        </MuiPickersUtilsProvider>
    )
}
