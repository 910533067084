import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData, configPermisosRW } from '../../service/AuthHelperMethods';
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {Tooltip} from 'primereact/tooltip';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import swal from 'sweetalert';
import ShowLoading from "../../components/ShowLoading/ShowLoading";
import { formatExcel } from '../../service/utils';


const initialFValues = {
	txtMesConsulta: '',
    txtFechaDesde: '',
    txtFechaHasta: '',
    txtContribuyente: '',
    id: ''
};
export const LibroDeVenta = () => {
	
	let location = useLocation();
	const [acceso, setAcceso] = useState("");
    const toast = useRef(null);
    const [ventas, setVentas] = useState([]);
    const [httpService] = useState(new HttpService());
    const [userData] = useState(getUserData() || null);
    const [submitted, setSubmitted] = useState(false);
    const [fdesde, setFDesde] = useState("");
    const [fhasta, setFHasta] = useState("");
    const [displayLibro, setDisplayLibro] = useState(false);
	const [/* impuestosOptions */, setImpuestosOptions] = useState([]);
	const [impuestos, setImpuestos] = useState([]);
	const [exportColumns, setExportColumns] = useState([]);
	const [maquinasFiscales, setMaquinasFiscales] = useState([]);
	const [generarLibroDialog, setGenerarLibroDialog] = useState(false);
	const [loadingDialog, setLoadingDialog] = useState(false);
	const [buttonPDFDisable, setButtonPDFDisable] = useState(true);

    let razon_social_aux = userData.razon_social;
    let direccion_aux = userData.direccion;
    let rif_aux = userData.rif;

    useEffect(() => {
		let acc = configPermisosRW(location, userData);
		setAcceso(acc);
		httpService.getOptionSelect("lista_impuestos").then(data => {
			setImpuestosOptions(data)
		});
		if (userData.usaMF) {
			httpService.getOptionSelect("lista_maquinasFiscales", userData.contribuyente)
			.then(data => {setMaquinasFiscales(data); })
		}

    }, [userData, httpService, location]);

    const [/* ButtonDisabled */, setButtonDisabled] = useState(false);
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboProducto" in fieldValues) temp.cboProducto = fieldValues.cboProducto ? "" : "Campo Requerido.";
        if ("cboUoM" in fieldValues) temp.cboUoM = fieldValues.cboUoM ? "" : "Campo Requerido.";

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

    const cols = [
        { field : 'txtNroOperacion', header: 'N° Op.' },
		{ field : 'txtFechaFactura', header : 'Fecha de la Factura'},
		{ field : 'txtRif', header : 'RIF'},
		{ field : 'txtRazonSocial', header : 'Razón Social'},
		{ field : 'sMaquinaFiscalRz', header : 'Máquina Fiscal'},
		{ field : 'sNroReporteRz', header : 'Nro Reporte Z'},
		{ field : 'sFacturaDesdeRz', header : 'Fac. Desde'},
		{ field : 'sFacturaHastaRz', header : 'Fac. Hasta'},
		{ field : 'txtNroDoc', header : 'N° Factura'},
		{ field : 'txtNroControl', header : 'Núm. Ctrol. Factura'},
		{ field : 'txtNroNotaCredito', header : 'N° Nota Crdto.'},
		{ field : 'txtNroNotaDebito', header : 'N° Nota Debito.'},
		{ field : 'txtNroDocNotaCD', header : 'N° Factura Afectada'},
		{ field : 'total_txtTotalConImp', header : 'Total Ventas con IVA'},
		{ field : 'total_txtSinDeCret', header : 'Ventas No Gravadas'},
		{ field : 'total_txtBaseImponible', header : 'Base Imponible'},
		{ field : 'nIdImpuesto', header : '% Alic.'},
		{ field : 'total_txtImpuesto', header : 'Impuesto IVA'},
    ];

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    }

    const exportPdf = () => {
		// const doc = new jsPDF('landscape');
		const doc = new jsPDF('landscape', 'mm', 'a3');
		const sfont = 11;
		doc.setFontSize(sfont);
		doc.setFont("helvetica", "bold");
		doc.text(userData.razon_social, 15, 10);
		doc.setFont("helvetica", "normal");
		doc.text(userData.direccion, 15, 15);
		doc.text("RIF: "+ userData.rif, 15, 20);
		
		if (maquinasFiscales.length === 1) {
			var iterator = maquinasFiscales.values();
			doc.text("Máquina Fiscal: "+ iterator.next().value.title, 15, 25);
		}

		doc.setFont("helvetica", "bold");
		doc.text("LIBRO DE VENTAS", 209, 30, null, null, "center");
		doc.text(`DEL ${fdesde} AL ${fhasta}`, 209, 35, null, null, "center");
		
		var footer = `\n TOTAL VENTAS CON IVA = ${TotalConImp()} \n` +
            `\n VENTAS NO GRAVADAS = ${SinDeCret()} \n` +
			`\n TOTAL BASE IMPONIBLE = ${TotalbaseImponible()} \n` +
			`\n TOTAL IMPUESTO = ${TotalImpuesto()} \n`
			
		impuestos.map((imp) => (
	    	footer += ` \n${imp.m_header.toUpperCase()} = ${Sumar(imp)} \n`
		))
          
        footer += `\n TOTAL IVA RETENIDO = ${TotalIvaRetenido()}`;

		var body = [...ventas, 
		[{content: footer, colSpan: exportColumns.length-1, 
			styles: { fontStyle: 'bold', halign: 'right' }
		}]]

		doc.autoTable({
			margin: { top: 40 },
			pageBreak: 'auto',
			columns: exportColumns,
			body: body,
			headStyles: {
				fontSize: sfont,
				halign: 'center'
			},
			bodyStyles: {
				fontSize: sfont,
				halign: 'right',
			},
			columnStyles: {
				txtNroOperacion: { halign: 'center', },
				txtFechaFactura : { halign: 'center', },
				txtRif: { halign: 'center', },
				txtRazonSocial: { halign: 'left', },
				txtNroDoc: { halign: 'center', },
				txtNroControl: { halign: 'center', },
				txtNroNotaDebito: { halign: 'center', },
				txtNroNotaCredito: { halign: 'center', },
				txtNroDocNotaCD: { halign: 'center', },
			},
		});

		doc.save('libro_ventas.pdf');
    }

    const exportExcel = () => {
		const data = formatExcel(ventas, cols);
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'ventas');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="p-button-secondary p-mr-2" tooltipOptions={{position: 'top'}} data-pr-tooltip="Exportar a CSV" />
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" tooltipOptions={{position: 'top'}} data-pr-tooltip="Exportar a XLS" />
                <Button type="button" icon="pi pi-file-pdf" disabled={buttonPDFDisable} onClick={exportPdf} className="p-button-danger p-mr-2" tooltipOptions={{position: 'top'}} data-pr-tooltip="Exportar a PDF" />
                <Button type="button" label="Generar Libro" onClick={showGenerarLibroDialog} className="p-button" tooltipOptions={{position: 'top'}} data-pr-tooltip="Generar Libro" disabled={ acceso && acceso === "W" ? false : true } />
            </React.Fragment>
        )
    }

	const dt = useRef(null);

    const ConsultarLibroVenta = () => {
		if (values.txtMesConsulta !== ""){
			setLoadingDialog(true);
			crearColumnas();
			var fc = new Date(values.txtMesConsulta);
			var fd = new Date(values.txtMesConsulta);
			setFDesde(`${(fd.getDate())}`.padStart(2,'0') + "/"+ `${(fd.getMonth()+1)}`.padStart(2,'0')+ "/" + fd.getFullYear());

			var fh = new Date(fc.getFullYear(), fc.getMonth() + 1, 0);
			setFHasta(`${(fh.getDate())}`.padStart(2,'0') + "/"+ `${(fh.getMonth()+1)}`.padStart(2,'0')+ "/" + fh.getFullYear());

			values.txtFechaDesde = fd;
			values.txtFechaHasta = fh;
			values.txtContribuyente = userData.contribuyente;
			httpService.getGeneralData("existe_libro_venta", values)
			.then(data2 => {
				if (!data2.error){
					if  (data2.valor_r !== 0) {
						setButtonPDFDisable(false);
					} else {
						setButtonPDFDisable(true);
					}
				}
			});
			httpService.getTableSelect("libro_de_ventas", userData.contribuyente, fd, fh)
			.then(data => {
				setVentas(data);
				setLoadingDialog(false);
				setDisplayLibro(true);
			});
			


		} else {
			swal({title: "Atención!", text: "Debe indicar un periodo a consultar", icon: "warning"});
		}
    }

	const generarLibroVentas = async (e) => {
        e.preventDefault();
		setSubmitted(true);
		values.txtContribuyente = userData.contribuyente;
		setGenerarLibroDialog(false);
		setLoadingDialog(true);
		httpService.generarLibroVentas(values)
		.then((res) => {
			setButtonDisabled(false);
			setLoadingDialog(false);
			if (res.error) {
				swal({title: "Ups...", text: res.message, icon: "error"});
			} else {
				swal("Exito", res.message, "success");
				httpService.getTableSelect("libro_de_ventas", userData.contribuyente, values.txtFechaDesde, values.txtFechaHasta)
				.then(data => {
					setVentas(data);
					setDisplayLibro(true);
					setButtonPDFDisable(false);
				});
				
			}
		})
		.catch((err) => {
			setLoadingDialog(false);
			swal({ title: "Ups...", text: err.message, icon: "error" });
			setButtonDisabled(false);
		});
    }

    /* const formatCurrency = function (number) {
        var numeral = require('numeral');
        var myNumeral = numeral(number);
        return myNumeral.format('0,0.00');
    }; */

	const parseBsFloat = (number) =>{
		return parseFloat(number.replaceAll(".","").replaceAll(",", "."));
	}

	const parseFloatBs = (number) =>{
		const options = { minimumFractionDigits: 2}
		const numberFormat1 = new Intl.NumberFormat('es-VE' , options);
		const numero = numberFormat1.format(number);
		return numero;
	}

    const TotalConImp = () => {
        let total = 0;
        for (let mont of ventas) {
			let tot = mont.total_txtTotalConImp === null ? 0 : parseBsFloat(mont.total_txtTotalConImp);
            total += tot;
        }
        return parseFloatBs(total);
    }

    const SinDeCret = () => {
        let total = 0;
        for (let mont of ventas) {
			let tot = mont.total_txtSinDeCret === null ? 0 : parseBsFloat(mont.total_txtSinDeCret);
            total += tot;
        }
        return parseFloatBs(total);
    }

	const TotalbaseImponible = () => {
        let total = 0;
        for (let mont of ventas) {
			let tot = mont.total_txtBaseImponible === null ? 0 : parseBsFloat(mont.total_txtBaseImponible);
            total += tot;
        }
        return parseFloatBs(total);
    }

	const TotalImpuesto = () => {
        let total = 0;
        for (let mont of ventas) {
			let tot = mont.total_txtImpuesto === null ? 0 : parseBsFloat(mont.total_txtImpuesto);
            total += tot;
        }
        return parseFloatBs(total);
    }

    const TotalIvaRetenido = () => {
        let total = 0;
        for (let mont of ventas) {
			let tot = mont.total_txtImpuestoRetenido === null ? 0 : parseBsFloat(mont.total_txtImpuestoRetenido);
            total += tot;
        }
        return parseFloatBs(total);
    }

    const Sumar = (imp) => {
        let total = 0;
        for (let mont of ventas) {
			let tot = mont[imp.m_field] === null ? 0 : parseBsFloat(mont[imp.m_field]);
            total += tot;
        }
        return parseFloatBs(total);
    }

    let footerGroup = <ColumnGroup>
    <Row>
        <Column footer="TOTALES:" colSpan={maquinasFiscales.length > 1 ? 9 : 12} footerStyle={{ textAlign: 'right' }} />
        <Column footer={TotalConImp} footerStyle={{ textAlign: 'right' }}/>
        <Column footer={SinDeCret} footerStyle={{ textAlign: 'right' }}/>
		<Column footer={TotalbaseImponible} footerStyle={{ textAlign: 'right' }}/>
		<Column footer={0} footerStyle={{ textAlign: 'right' }}/>
		<Column footer={TotalImpuesto} footerStyle={{ textAlign: 'right' }}/>
	
		{/*{impuestos.map((imp) => (
	    		<Column footer={Sumar(imp)} footerStyle={{ textAlign: 'right' }}/>
		))} */}

		<Column footer={TotalIvaRetenido} footerStyle={{ textAlign: 'right' }}/>
    </Row>
    </ColumnGroup>;

	const crearColumnas = () => {
		let arr2 = [];
		let c_arr = [];
		let arr_col = cols;

		if (!userData.usaMF || maquinasFiscales.length === 1) {
			arr_col.splice(4,1);
		}

		c_arr = {
			field : "total_txtImpuestoRetenido",
			header : "IVA Retenido"
		}
		arr_col.push(c_arr);

		let exportColumns = arr_col.map(col => ({ title: col.header, dataKey: col.field }));
		setExportColumns(exportColumns);
		setImpuestos(arr2)
	}

	/* function headerMaquina() {
		if (maquinasFiscales.length == 1) 
			return (<><span>Máquina Fiscal: {maquinasFiscales.values().next().value.title}</span><br />	</>)	
	} */

	const hideGenerarLibroDialog = () => {
        setGenerarLibroDialog(false);
    }

	const showGenerarLibroDialog = () => {
        setGenerarLibroDialog(true);
    }

	const generarLibroDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideGenerarLibroDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={generarLibroVentas} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <h5>Libro de Ventas</h5>
					<div className="p-formgroup-inline">
                        <div className="p-field">
							<label htmlFor="txtMesConsulta">Mes/Año: </label>
							<Calendar id="txtMesConsulta" name="txtMesConsulta" value={values.txtMesConsulta} onChange={handleInputChange}  view="month" dateFormat="mm/yy" yearNavigator={true} yearRange="2010:2050" className={classNames({ 'p-invalid': submitted && !values.txtMesConsulta })} />
                        </div>
						{submitted && !values.txtMesConsulta && <small className="p-invalid">{errors.txtMesConsulta}</small>}
                        <Button label="Consultar Libro de Ventas" icon="pi pi-add" className="p-button-info p-mr-2" onClick={ConsultarLibroVenta} />
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <div className="card"  hidden={!displayLibro}>
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <Tooltip target=".export-buttons>button" position="bottom" />
					<div className="card">
						<div className="p-col-12" style={{ textAlign: 'center', fontSize: "15px" }}>
                            <span style={{ fontWeight: "bold" }}>{razon_social_aux.toUpperCase()}</span><br />
							<span>{direccion_aux.toUpperCase()}</span><br />
							<span>{'RIF: ' + rif_aux.toUpperCase()}</span><br />
							{ (maquinasFiscales.length === 1) &&
								<><span>Máquina Fiscal: {maquinasFiscales.values().next().value.title}</span><br /></>
							}
							<br /><span style={{ fontStyle: "italic", fontSize: "14px" }}>{'VENTAS DEL: ' + fdesde + ' AL ' + fhasta}</span><br />
						</div>
					</div>
                    <DataTable id="my-table" ref={dt} value={ventas} dataKey="id" footerColumnGroup={footerGroup} className={"p-datatable-gridlines"} >
						<Column key="f1" exportable={false} header="Fecha de la Factura" field="txtFechaFactura" headerStyle={{textAlign: "center"}} />
						<Column key="f2" header="RIF" field="txtRif" headerStyle={{textAlign: "center"}} />
						<Column key="f3" header="Nombre o Razón Social" field="txtRazonSocial" headerStyle={{textAlign: "center"}} />
						<Column key="f4" header="Máquina Fiscal" field="sMaquinaFiscalRz" headerStyle={{textAlign: "center"}} />
						<Column key="f5" header="Nro Reporte Z" field="sNroReporteRz" headerStyle={{textAlign: "center"}} />
						<Column key="f6" header="Fac. Desde" field="sFacturaDesdeRz" headerStyle={{textAlign: "center"}} />
						<Column key="f7" header="Fac. Hasta" field="sFacturaHastaRz" headerStyle={{textAlign: "center"}} />
						<Column key="f8" header="N° Factura" field="txtNroDoc" headerStyle={{textAlign: "center"}} />
						<Column key="f9" header="Núm. Ctrol. Factura" field="txtNroControl" headerStyle={{textAlign: "center"}} />
						<Column key="f10" header="N° Nota Débit." field="txtNroNotaDebito" headerStyle={{textAlign: "center"}} />
						<Column key="f11" header="N° Nota Crdto." field="txtNroNotaCredito" headerStyle={{textAlign: "center"}} />
						<Column key="f12" header="N° Factura Afectada" field="txtNroDocNotaCD" headerStyle={{textAlign: "center"}} />
						<Column key="f13" header="Total Ventas con IVA" field="total_txtTotalConImp" headerStyle={{textAlign: "center"}} bodyStyle={{ textAlign: 'right' }} />
						<Column key="f14" header="Ventas No Gravadas" field="total_txtSinDeCret" headerStyle={{textAlign: "center"}} bodyStyle={{ textAlign: 'right' }}/>
						<Column key="f15" header="Base Imponible" field="total_txtBaseImponible" headerStyle={{textAlign: "center"}} bodyStyle={{ textAlign: 'right' }}/>
						<Column key="f16" header="% Alic." field="nIdImpuesto" headerStyle={{textAlign: "center"}} bodyStyle={{ textAlign: 'right' }}/>
						<Column key="f17" header="Impuesto IVA" field="total_txtImpuesto" headerStyle={{textAlign: "center"}} bodyStyle={{ textAlign: 'right' }}/>
						{/* {dinamicColumns} */}
						<Column key="f55" header="IVA Retenido" field="total_txtImpuestoRetenido" headerStyle={{textAlign: "center"}} bodyStyle={{ textAlign: 'right' }}/>
                    </DataTable>
					<Dialog visible={generarLibroDialog} style={{ width: '450px' }} header="Confirm" modal footer={generarLibroDialogFooter} onHide={hideGenerarLibroDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span>Esta acción asignará un Nro. de operación a cada venta registrada de forma cronológica. 
								Tome en cuenta que al generar el libro, ya no podrá registrar facturas para el periodo (mes/año) indicado.</span>
                        </div>
                    </Dialog>
                </div>
            </div>
            <div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
};
