import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";

function NumberMaskCustom(props) {
    const { inputRef, type, ...other } = props;

    const digit = /[0-9]/;

    const getMask = (tipo) => {
        switch (tipo) {
            case "tel":
                return ["+", digit, digit, digit, digit, digit, digit, digit, digit, digit, digit, digit, digit];
			case "num":
				return [digit,digit,digit,digit];
            default:
                break;
        }
    };

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={getMask(type)}
            placeholderChar={"\u2000"}
            //showMask
        />
    );
}

NumberMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export default function InputNumberMask(props) {
    const { name, label, value, error = null, onChange, type, fullWidth, autoFocus, variant, size, className, placeholder, disabled } = props;

    return (
        <TextField
            className={className}
            placeholder={placeholder}
            variant={variant}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            type={type}
            fullWidth={fullWidth}
            autoFocus={autoFocus}
			disabled={disabled} 
            size={size}
            onClick={(e) => e.target.setSelectionRange(1, 0)}
            {...(error && { error: true, helperText: error })}
            InputProps={{
                inputComponent: NumberMaskCustom,
            }}
        />
    );
}
