import React, { useState, useEffect } from "react";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import axios from "axios";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import Constantes from "../../Constantes";
import TablaCuenta from "./Tablas/TablaCuenta";
import TablaGeneral from "./Tablas/TablaGeneral";

import { useForm } from "../../components/useForm";
import { getUserData } from "../../service/AuthHelperMethods";
import {
  Button as ButtonMaterial,
  MenuItem,
  TextField,
  IconButton,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/es";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import jsPDF from "jspdf";
import ShowLoading from "../../components/ShowLoading/ShowLoading";
import ClearIcon from "@mui/icons-material/Clear";

const initialFValues = {
  txtFechaDesde: "",
  txtFechaHasta: "",
  txtContribuyente: "",
  id: "",
};
export default function LibroDeCompra() {
  const [userData] = useState(getUserData() || null);
  const [fdesde, setFDesde] = useState();
  const [fhasta, setFHasta] = useState();
  const [response, setResponse] = useState();
  console.log(
    "🚀 ~ file: LibroMayor.jsx ~ line 37 ~ LibroDeCompra ~ response",
    response
  );
  const [loadingDialog, /* setLoadingDialog */] = useState(false);
  const [cuenta, setCuenta] = useState([]);
  const [cuentaID, setCuentaID] = useState();
  let razon_social_aux = userData.razon_social;
  let direccion_aux = userData.direccion;
  let rif_aux = userData.rif;
  let idContribuyente = userData.contribuyente;

  

  useEffect(() => {
    const cuentasContablesCall = () => {
      axios
        .get(
          `${Constantes.RUTA_API}/get_options_select.php?tipoDato=lista_cuentas_contables&IdContribuyente=${idContribuyente}`
        )
        .then((res) => {
          setCuenta(res.data);
        })
        .catch((error) => console.log(error));
    };
    cuentasContablesCall();
  }, [idContribuyente]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("cboProducto" in fieldValues)
      temp.cboProducto = fieldValues.cboProducto ? "" : "Campo Requerido.";
    if ("cboUoM" in fieldValues)
      temp.cboUoM = fieldValues.cboUoM ? "" : "Campo Requerido.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors } = useForm(initialFValues, true, validate);

  const cols = [
    { field: "txtFechaCompra", header: "Fecha Asiento" },
    { field: "txtRif", header: "Nº Asiento" },
    { field: "txtRazonSocial", header: "Concepto" },
    { field: "txtNroDoc", header: "Debe" },
    { field: "txtTotalConImp", header: "Haber" },
    { field: "txtNroControl", header: "Saldo" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const formatDate = (dateStr) => {
    let [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year}`;
  };

  const formatNumber = (value) => {
    const numberConv = Number(value);
    const numericValue = numberConv.toFixed(2);
    const parts = numericValue.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  };

  const handleChangeFDesde = (e) => {
    const newDate = dayjs(e);
    const formattedDate = newDate ? newDate.format("YYYY-MM-DD") : "";
    setFDesde(formattedDate);
  };
  const handleChangeFHasta = (e) => {
    const newDate = dayjs(e);
    const formattedDate = newDate ? newDate.format("YYYY-MM-DD") : "";
    setFHasta(formattedDate);
  };

  const data = response?.map((data) => ({
    SaldoAnterior: formatNumber(data.saldoAnterior),
    SaldoFinal: formatNumber(data.saldoFinal),
    Cuenta: data.ctaNombre,
    Movimientos: data.movimientos.map((mov) => ({
      FechaAsiento: formatDate(mov.dtFechaAsiento),
      NroAsiento: mov.nNroAsiento,
      Concepto: mov.sConcepto,
      Debe: mov.cTipoRegMov === "D" ? formatNumber(mov.deMontoMovCont) : "0.00",
      Haber:
        mov.cTipoRegMov === "H" ? formatNumber(mov.deMontoMovCont) : "0.00",
      Saldo: formatNumber(mov.saldo),
    })),
  }));

  const exportPdf = () => {
    const doc = new jsPDF("landscape");
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(userData.razon_social, 15, 20);
    doc.setFont("helvetica", "normal");
    doc.text(userData.direccion, 15, 25);
    doc.text("RIF: " + userData.rif, 15, 30);

    // doc.text("This is right aligned text", 200, 100, null, null, "right");
    doc.setFont("helvetica", "bold");
    doc.text("LIBRO MAYOR", 150, 40, null, null, "center");
    doc.text(
      `DEL ${formatDate(fdesde)} AL ${formatDate(fhasta)}`,
      150,
      45,
      null,
      null,
      "center"
    );

    var body = [];

    // Iterate over each object in the response
    data.forEach((obj) => {
      // Add saldoInicial row
      body.push([
        {
          content: `\n Cuenta Contable: ${obj.Cuenta}`,
          colSpan: 6,
          styles: { fontStyle: "bold", halign: "left" },
        },
      ]);
      body.push([
        {
          content: `\n Saldo Anterior: ${obj.SaldoAnterior}`,
          colSpan: 6,
          styles: { fontStyle: "bold", halign: "right" },
        },
      ]);

      // Iterate over each movimiento in the current object
      obj.Movimientos.forEach((mov) => {
        body.push([
          mov.FechaAsiento,
          mov.NroAsiento,
          mov.Concepto,
          mov.Debe,
          mov.Haber,
          mov.Saldo,
        ]);
      });

      // Add saldoFinal row
      body.push([
        {
          content: `\n Saldo Final: ${obj.SaldoFinal}`,
          colSpan: 6,
          styles: { fontStyle: "bold", halign: "right" },
        },
      ]);
    });

    // ];

    doc.autoTable({
      margin: { top: 50 },
      columns: exportColumns,
      body: body,
      styles: { overflow: "linebreak", fontSize: 9 },
    });
    doc.save("libro_mayor.pdf");
  };

  const exportExcel = () => {
    XlsxPopulate.fromBlankAsync()
      .then((workbook) => {
        const sheet = workbook.sheet("Sheet1");
        const styles = {
          bold: true,
          fontColor: "000000",
          horizontalAlignment: "center",
        };
        sheet.cell("A1").value(razon_social_aux.toUpperCase()).style(styles);
        sheet.cell("A2").value(direccion_aux.toUpperCase()).style(styles);
        sheet
          .cell("A3")
          .value("RIF: " + rif_aux.toUpperCase())
          .style(styles);
        sheet
          .cell("A4")
          .value(
            `Fecha desde: ${formatDate(fdesde)} - Fecha hasta: ${formatDate(
              fhasta
            )}`
          )
          .style(styles);

        sheet.range("A1:F1").merged(true);
        sheet.range("A2:F2").merged(true);
        sheet.range("A3:F3").merged(true);
        sheet.range("A4:F4").merged(true);

        //define the headers
        const headers = [
          "FechaAsiento",
          "NroAsiento",
          "Concepto",
          "Debe",
          "Haber",
          "Saldo",
        ];

        let headersIndex = 5;
        // Set the headers and apply formatting styles
        headers.forEach((header, index) => {
          sheet
            .cell(`${String.fromCharCode(65 + index)}${headersIndex}`)
            .value(header);
          sheet
            .cell(`${String.fromCharCode(65 + index)}${headersIndex}`)
            .style({
              bold: true,
              fill: "0288d1",
              border: true,
              borderColor: "202020",
              fontColor: "ffffff",
            });
        });

        // Initialize the rowIndex variable to keep track of the current row index
        let rowIndex = 6; // Start from the second row (assuming headers are in the first row)

        // Iterate over each object in response
        data.forEach((obj, objIndex) => {
          // Add saldoInicial row
          const cuentaRowIndex = rowIndex;
          sheet
            .cell(`A${cuentaRowIndex}`)
            .value(`Cuenta Contable: ${obj.Cuenta}`)
            .style({
              bold: true,
              fill: "B4B4B4",
            });
          sheet.range(`A${cuentaRowIndex}:F${cuentaRowIndex}`).merged(true);
          rowIndex++;

          const saldoInicialRowIndex = rowIndex;
          sheet
            .cell(`A${saldoInicialRowIndex}`)
            .value(`Saldo Anterior: ${obj.SaldoAnterior}`)
            .style({
              bold: true,
              fill: "E9E9E9",
              horizontalAlignment: "right"
            });
          sheet
            .range(`A${saldoInicialRowIndex}:F${saldoInicialRowIndex}`)
            .merged(true);
          rowIndex++;

          // Iterate over each movimiento in the current object
          obj.Movimientos.forEach((data) => {
            headers.forEach((header, colIndex) => {
              sheet
                .cell(`${String.fromCharCode(65 + colIndex)}${rowIndex}`)
                .value(data[header]);
              sheet
                .cell(`${String.fromCharCode(65 + colIndex)}${rowIndex}`)
                .style({
                  fill: "ffffff",
                  border: true,
                  borderColor: "A3A3A3",
                });
            });
            rowIndex++;
          });

          // Add saldoFinal row
          const saldoFinalRowIndex = rowIndex;
          sheet
            .cell(`A${saldoFinalRowIndex}`)
            .value(`Saldo Final: ${obj.SaldoFinal}`)
            .style({
              bold: true,
              fill: "E9E9E9",
              horizontalAlignment: "right"
            });
          sheet
            .range(`A${saldoFinalRowIndex}:F${saldoFinalRowIndex}`)
            .merged(true);
          rowIndex = rowIndex + 2;
        });

        // Set column widths
        headers.forEach((header, index) => {
          sheet.column(`${String.fromCharCode(71 + index)}`).width(30);
        });

        // Convert the workbook to a Blob
        return workbook.outputAsync("blob");
      })
      .then((blob) => {
        // Create a download link for the Excel file
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        const fileName = "libro_mayor";
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const notifyNotFound = (textIn) => {
    Swal.fire({
      title: "Aviso!",
      text: textIn,
      icon: "info",
      confirmButtonText: "Cerrar",
    });
  };

  const generarLibroCall = () => {
    if (fdesde === undefined || fhasta === undefined) {
      notifyNotFound("Debe indicar el periodo a consultar");
    } else if (fdesde > fhasta) {
      notifyNotFound(
        "La fecha inicial no puede ser mayor a la fecha final de consulta"
      );
    } else {
      axios
        .post(`${Constantes.RUTA_API}/generar_libro_mayor.php`, {
          txtContribuyente: idContribuyente,
          txtCuentaContable: cuentaID === undefined ? null : cuentaID.id,
          txtFechaDesde: fdesde,
          txtFechaHasta: fhasta,
        })
        .then((res) => {
          console.log(res);
          if (!res?.data?.data?.length) {
            notifyNotFound("No se encontraron Movimientos");
            setResponse(null);
          } else {
            setResponse(res.data.data);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="card">
          <h5>Libro Mayor</h5>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: 20,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
              <DatePicker
                sx={{ width: "100%" }}
                label="Fecha Desde*"
                name="FechaAsiento"
                onChange={(e) => handleChangeFDesde(e)}
                inputFormat="DD/MM/YYYY"
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
              <DatePicker
                sx={{ width: "100%" }}
                label="Fecha Hasta*"
                name="FechaAsiento"
                onChange={(e) => handleChangeFHasta(e)}
                inputFormat="DD/MM/YYYY"
              />
            </LocalizationProvider>

            <TextField
              label="Cuenta (opcional)"
              select
              sx={{ width: "100%" }}
              value={cuentaID?.title || ""}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{
                      visibility: cuentaID ? "visible" : "hidden",
                      marginRight: "5px",
                    }}
                    onClick={() => setCuentaID("")}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            >
              {cuenta?.map((acc) => (
                <MenuItem
                  key={acc.id}
                  value={acc.title}
                  onClick={() => setCuentaID(acc)}
                >
                  {acc.title}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div style={{ marginTop: 20, width: "100%" }}>
            <React.Fragment>
              <ButtonMaterial
                onClick={generarLibroCall}
                sx={{
                  width: "100%",
                  textTransform: "none",
                  backgroundColor: "#0288D1",
                  color: "white",
                  fontSize: "1rem",
                  fontWeight: 400,
                  "&:hover": { backgroundColor: "#0288D1" },
                  letterSpacing: "1px",
                }}
              >
                Generar Libro Mayor
              </ButtonMaterial>
            </React.Fragment>
          </div>
        </div>
      </div>
      {response && response?.length === 1 && (
        <>
          <TablaCuenta
            data={response}
            fechaDesde={fdesde}
            fechaHasta={fhasta}
            razonSocial={razon_social_aux}
            direccion={direccion_aux}
            rif={rif_aux}
            exportExcel={exportExcel}
            exportPdf={exportPdf}
          />
        </>
      )}
      {response && response?.length > 1 && (
        <TablaGeneral
          data={response}
          fechaDesde={fdesde}
          fechaHasta={fhasta}
          razonSocial={razon_social_aux}
          direccion={direccion_aux}
          rif={rif_aux}
          exportExcel={exportExcel}
          exportPdf={exportPdf}
        />
      )}

      <div className="p-col-12">
        <div>
          <ShowLoading data={loadingDialog} />
        </div>
      </div>
    </div>
  );
}
