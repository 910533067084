import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import Swal from "sweetalert2";

import { HttpService } from "../../service/HttpService";
import { getUserData } from "../../service/AuthHelperMethods";
import ModalDetalleAsiento from "./ModalDetalleAsiento";
import UpdateAsientoContable from "./UpdateAsientoContable/UpdateAsientoContable";
import Constantes from "../../Constantes";

import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/es"
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function CrearAsientoContable() {
  const history = useHistory();

  const [asientos, setAsientos] = useState([]);
  console.log("🚀 ~ file: AsientosContables.jsx ~ line 36 ~ CrearAsientoContable ~ asientos", asientos)
  const [detalleAsiento, setDetalleAsiento] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [date, setDate] = useState("");
  const [nroAsiento, setNroAsiento] = useState("");
  const [concepto, setConcepto] = useState("");
  const [httpService] = useState(new HttpService());
  const [userData] = useState(getUserData() || null);

  const asientosCall = () => {
    axios
      .get(
        `${Constantes.RUTA_API}/admin_asientos_contables.php?IdContribuyente=${cliente
          ?.map((id) => id.nIdContribuyente)
          .shift()}&nroAsiento=${nroAsiento ? nroAsiento : null}&concepto=${
          concepto ? concepto : null
        }&fechaAsiento=${date ? date : null}`
      )
      .then((res) => {
        if (res.data.length === 0) {
          notifyNotFound();
        } else {
          setAsientos(res.data);
        }
        console.log(res);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    httpService
      .getTableSelect("tabla_clientes", userData.contribuyente)
      .then((res) => {
        setCliente(res);
      })
      .catch((error) => console.log(error));
  }, [httpService, userData]);

  const formatNumber = (value) => {
    // Remove non-numeric characters from the input value
    const numericValue = value.toString().replace(/\D/g, "");
    // Convert the numeric value to a number and divide by 100 to move the decimal point two places to the left
    const numberValue = Number(numericValue) / 100;
    // Format the number value with a dot as a thousand separator and a comma as a decimal separator
    return numberValue.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatDate = (dateStr) => {
    let [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year}`;
  };

  const handleChangeDate = (e) => {
    const newDate = dayjs(e);
    const formattedDate = newDate ? newDate.format("YYYY-MM-DD") : "";
    setDate(formattedDate);
  };

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const [isOpenDialogUpdate, setIsOpenDialogUpdate] = useState(false);
  const handleOpenDialogUpdate = () => {
    setIsOpenDialogUpdate(true);
  };

  const notifyNotFound = () => {
    Swal.fire({
      title: "Aviso!",
      text: "No se encontraron Asientos Contables",
      icon: "info",
      confirmButtonText: "Ok",
    });
  };
  const notifyBadRequest = () => {
    Swal.fire({
      title: "Aviso!",
      text: "Para realizar la busqueda por favor ingresa una opción",
      icon: "info",
      confirmButtonText: "Ok",
    });
  };

  return (
    <>
      <ModalDetalleAsiento
        asientoData={detalleAsiento}
        isOpenDialog={isOpenDialog}
        handleCloseDialog={() => setIsOpenDialog(false)}
      />
      <UpdateAsientoContable 
        asientoData={detalleAsiento}
        isOpenDialogUpdate={isOpenDialogUpdate}
        handleCloseDialogUpdate={() => setIsOpenDialogUpdate(false)}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          borderRadius: "3px",
          border: "1px solid #0002",
          p: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          <Typography sx={{ fontWeight: "500", fontSize: "1.25rem", pb: 2 }}>
            Asientos Contables
          </Typography>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => history.push("/crear_asiento")}
            sx={{
              backgroundColor: "#689F38",
              "&:hover": { backgroundColor: "#689f38" },
            }}
          >
            Nuevo
          </Button>
        </Box>
        <Typography sx={{ fontWeight: "400", fontSize: "1rem", pb: 2, mt: 3 }}>
          Para buscar un Asiento Contable por favor introduzca una alguna de las
          siguientes opciones: Fecha, Nº de Asiento, Concepto
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 2,
            pb: 2,
          }}
        >
          <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "22%" }}
              label="Busqueda por Fecha"
              name="FechaAsiento"
              onChange={(e) => handleChangeDate(e)}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
          <TextField
            label="Nº de Asiento"
            sx={{ width: "22%" }}
            type="number"
            value={nroAsiento}
            onChange={(e) => setNroAsiento(e.target.value)}
          />
          <TextField
            label="Concepto"
            sx={{ width: "40%" }}
            value={concepto}
            onChange={(e) => setConcepto(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={() => {
              date || nroAsiento || concepto
                ? asientosCall()
                : notifyBadRequest();
            }}
            startIcon={<SearchIcon />}
          >
            Buscar
          </Button>
        </Box>
        {asientos.length ? (
          <Box sx={{ mt: 2, borderRadius: "3px 3px 0px 0px" }}>
            <Box
              sx={{
                width: "100%",
                height: "9vh",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F8F9FA",
                border: "1px solid #0003",
                pr: 3,
                pl: 3,
              }}
            >
              <Box sx={{ width: "40%", fontWeight: 500, fontSize: "1rem" }}>
                Fecha Asiento
              </Box>
              <Box sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}>
                Nº Asiento
              </Box>
              <Box sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}>
                Monto Total
              </Box>
              <Box sx={{ width: "9%", fontWeight: 500, fontSize: "1rem" }}> Acciones</Box>
            </Box>
          </Box>
        ) : (
          <></>
        )}
        {asientos ? (
          asientos.map((a, index) => (
            <Box
              sx={{
                width: "100%",
                height: "8vh",
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                border: "1px solid #0003",
                pr: 3,
                pl: 3,
              }}
              key={index}
            >
              <Box sx={{ width: "40%", fontWeight: 500, fontSize: "1rem" }}>
                {formatDate(a.dtFechaAsiento)}
              </Box>
              <Box sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}>
                {a.nNroAsiento}
              </Box>
              <Box sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}>
                {formatNumber(a.deMonto)}
              </Box>
              <Box sx={{width: "9%", display: "flex", gap: 1}}>
                <Tooltip title="Ver Detalle">
                  <IconButton
                    onClick={() => {
                      handleOpenDialog();
                      setDetalleAsiento(a);
                    }}
                    sx={{backgroundColor: "#2196F3", "&:hover": {backgroundColor: "#2196F3"}}}
                  >
                    <RemoveRedEyeIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Modificar Asiento">
                  <IconButton
                  onClick={() => {
                    handleOpenDialogUpdate();
                    setDetalleAsiento(a);
                  }}
                  sx={{backgroundColor: "#FBC02D", "&:hover": {backgroundColor: "#FBC02D"}}}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ))
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}
