import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData, configPermisosRW } from '../../service/AuthHelperMethods';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import swal from 'sweetalert';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import ShowLoading from "../../components/ShowLoading/ShowLoading";

const initialFValues = {
    txtFechaOrden: '',
    cboMoneda: '',
    cboProveedor: '',
    txtObservacion: '',
    txtReferenciaV: '',
    txtContribuyente: '',
    sEstatus: '',
    caso: 0,
    id: '',
    txtNroControl: '',
    nIdProveedor_aux: 0,
    nIdUsuario_aux: 0,

    cboProducto: '',
    txtCantidadRecepcion: 0,
    txtObservacionRecep: '',
    txtFechaRecepcion: ''

};

export default function RecepcionDeCompras() {

    let emptyCompra = {
        txtFechaOrden: '',
        cboMoneda: '',
        cboProveedor: '',
        txtObservacion: '',
        txtReferenciaV: '',
        id: ''
    };

    let location = useLocation();
    const [acceso, setAcceso] = useState("");
    const [MonedaOptions, setMonedaOptions] = useState([]);
    const [/* ProveedorOptions */, setProveedorOptions] = useState([]);

    const toast = useRef(null);
    const [recibirProductoDialog, setRecibirProductoDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const userData = getUserData() || null;
    const [httpService] = useState(new HttpService());
    const [compras, setCompras] = useState([]);
    const [compra, /* setCompra */] = useState(emptyCompra);
    const [/* disabled */, setDisabled] = useState(true);
    const [/* caso */, setCaso] = useState(true);
    const [productos, setProductos] = useState([]);
    const [productosRecepOptions, setProductosRecepOptions] = useState([]);
    const [recibidos, setRecibidos] = useState([]);
    const [recepcionDialog, setRecepcionDialog] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);

    useEffect(() => {
        let acc = configPermisosRW(location, userData);
        setAcceso(acc);
    }, [location, userData]);

    useEffect(() => {
        setLoadingDialog(true);
        httpService.getOptionSelect("lista_Monedas").then(data => setMonedaOptions(data));
        httpService.getOptionSelect("lista_proveedores", userData.contribuyente, '').then(data => setProveedorOptions(data));
        httpService.getTableSelect("tabla_compras_rec", userData.contribuyente, '')
            .then(data => { setCompras(data); setLoadingDialog(false); })
            .catch(err => setLoadingDialog(false));
    }, [httpService, userData.contribuyente]);

    const [ButtonDisabledRecepcion, setButtonDisabledRecepcion] = useState(false);
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("txtRazonSocial" in fieldValues) temp.txtRazonSocial = fieldValues.txtRazonSocial ? "" : "Campo Requerido.";
        if ("txtRifProv" in fieldValues) temp.txtRifProv = fieldValues.txtRifProv ? "" : "Campo Requerido.";
        if ("txtRepresentanteLegal" in fieldValues) temp.txtRepresentanteLegal = fieldValues.txtRepresentanteLegal ? "" : "Campo Requerido.";
        if ("txtTelefono" in fieldValues) temp.txtTelefono = fieldValues.txtTelefono ? "" : "Campo Requerido.";
        if ("txtMovil" in fieldValues) temp.txtMovil = fieldValues.txtMovil ? "" : "Campo Requerido.";
        if ("txtPersonaContacto" in fieldValues) temp.txtPersonaContacto = fieldValues.txtPersonaContacto ? "" : "Campo Requerido.";
        if ("txtEmail" in fieldValues) temp.txtEmail = fieldValues.txtEmail ? "" : "Campo Requerido.";
        if ("txtDireccion" in fieldValues) temp.txtDireccion = fieldValues.txtDireccion ? "" : "Campo Requerido.";
        if ("cboMoneda" in fieldValues) temp.cboMoneda = fieldValues.cboMoneda ? "" : "Campo Requerido.";


        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };


    const validate2 = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboProducto" in fieldValues) temp.cboProducto = fieldValues.cboProducto ? "" : "Campo Requerido.";
        if ("txtCantidadRecepcion" in fieldValues) temp.txtCantidadRecepcion = fieldValues.txtCantidadRecepcion ? "" : "Campo Requerido.";
        if ("txtFechaRecepcion" in fieldValues) temp.txtFechaRecepcion = fieldValues.txtFechaRecepcion ? "" : "Campo Requerido.";
        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

    const handleSubmitRecepcion = async (e) => {
        setSubmitted(true);
        hideRecepcionProducDialog();
        console.log(values);
        if (validate2()) {
            setButtonDisabledRecepcion(true);
            values.nIdUsuario_aux = userData.idUsuario;
            httpService.recibirProductos(values)
                .then((res) => {
                    if (res.error) {

                        let temp = { ...errors };
                        setErrors({
                            ...temp,
                        });
                        setButtonDisabledRecepcion(false);
                        swal({ text: res.message, icon: "error", timer: "3000" });
                    } else {
                        setButtonDisabledRecepcion(false);
                        setRecepcionDialog(false);
                        httpService.getTableSelect("tabla_compras_rec", userData.contribuyente, '').then(data => setCompras(data));
                        httpService.getTableSelect("tabla_recibidos", userData.contribuyente, values.id).then(data => setRecibidos(data));
                        swal({ text: res.message, icon: "success", timer: "7000" });
                    }
                })
                .catch((err) => {
                    swal({ text: err.message, icon: "error", mtimer: "3000" });
                    setButtonDisabledRecepcion(false);
                });
        }

    };

    const editCompra = (compra) => {
        setCaso(1);
        setDisabled(true);
        setValues(compra);
        httpService.getTableSelect("tabla_item_compraCV", userData.contribuyente, compra.id).then(data => setProductos(data));
        httpService.getTableSelect("tabla_recibidos", userData.contribuyente, compra.id).then(data => setRecibidos(data));
        setRecibirProductoDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setRecibirProductoDialog(false);
    }


    const openNewRecepcion = () => {
        httpService.getOptionSelect("lista_productosXCompra", userData.contribuyente, values.id).then(data => setProductosRecepOptions(data));
        console.log(values);
        setRecepcionDialog(true);
    }

    const leftToolbarTemplateP = () => {
        return (
            <React.Fragment>
                <Button label="Registrar Ingreso" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={openNewRecepcion} disabled={acceso && acceso === "W" ? false : true} />
            </React.Fragment>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Consulta de Compras</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );


    const ProductoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sProducto}
            </>
        );
    }


    const DescripcionBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtDescripcion}
            </>
        );
    }

    const CantidadBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtCantidad)} ${rowData.sUoM}`;
    }


    const PrecioUnitBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtPrecioUnit)} ${rowData.sUoM}`;
    }

    const ImpuestoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sImpuesto}
            </>
        );
    }

    const IgtfBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtIgtf}
            </>
        );
    }

    const BaseImponibleBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtBaseImponible)} ${rowData.sUoM}`;
    }

    const SubTotalBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtSubTotal)} ${rowData.sUoM} ${rowData.sUoM}`;
    }

    const formatCurrency = function (number) {
        var numeral = require('numeral');
        var myNumeral = numeral(number);
        return myNumeral.format('0,0.00');
    };

    const NumeroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nro Orden</span>
                <span><b>{rowData.txtNumero}</b></span>
            </>
        );
    }

    const FechaCompraTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha</span>
                {rowData.txtFechaCompra}
            </>
        );
    }

    const ProveedorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Proveedor</span>
                {rowData.txtProveedor}
            </>
        );
    }

    const ItemsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Items</span>
                {rowData.txtItems}
            </>
        );
    }

    const EstatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"></span>
                {/* <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.txtEstatus}</span> */}
                <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.txtEstatus}</span>
            </>
        );
    }

    const EstatusRecepcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"></span>
                <span className={`product-badge status-${rowData.inventoryStatusRecepcion.toLowerCase()}`}>{rowData.txtEstatusRecepcion}</span>
            </>
        );
    }



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-search" className="p-button-rounded p-button-success p-mr-2" onClick={() => editCompra(rowData)} />
            </div>
        );
    }
    const [RecepcionProducDialog, setRecepcionProducDialog] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);



    const hideRecepcionProducDialog = () => {
        setRecepcionProducDialog(false);
    }

    const hideDialogRecepcion = () => {
        setSubmitted(false);
        setRecepcionDialog(false);
    }

    const confirmRecepcion = () => {
        setRecepcionProducDialog(true);
    }


    const recepcionProducDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRecepcionProducDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={handleSubmitRecepcion} />
        </>
    );


    const RecepcionDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialogRecepcion} />
            <Button label="Guardar" icon="pi pi-plus" className="p-button-text" disabled={ButtonDisabledRecepcion} onClick={confirmRecepcion} />
        </>
    );


    const ProductoAuxBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sProducto}
            </>
        );
    }

    const FechaRecepTemplate = (rowData) => {
        return (
            <>
                {rowData.dtFechaRecepcion}
            </>
        );
    }

    const CantRecibidaBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.deMontoRecibido + " " + rowData.sUoM}
            </>
        );
    }

    const ObservacionAuxBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sObservacion}
            </>
        );
    }
    const Nsi = () => {
        let total = 0;
        let sMoneda = "";
        for (let mont of productos) {
            total += parseFloat(mont.txtBaseImponible);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency(total) + " " + sMoneda;
    }

    var groupBy = function (miarray, prop) {
        return miarray.reduce(function (groups, item) {
            var val = item[prop];
            groups[val] = groups[val] || { sImpuesto: item.sImpuesto, deImpuesto: 0, deBaseImpon: 0, sPctRetenido: item.sPctRetenido, dePctRetenido: 0, deRetenidoISLR: 0, sPctTarifaRetenidaISLR: item.sPctTarifaRetenidaISLR };
            groups[val].deImpuesto += parseFloat(item.deImpuesto);
            groups[val].sMoneda = item.sMoneda;
            groups[val].deBaseImpon += parseFloat(item.txtSubTotalBaseImpo);
            groups[val].dePctRetenido += parseFloat(item.dePctRetenido);
            groups[val].deRetenidoISLR += parseFloat(item.deRetenidoISLR);
            return groups;
        }, {});
    };

    const grupoImpuesto = () => {
        let grImp = groupBy(productos, "sImpuesto");
        let refinado = Object.values(grImp).filter((i) => i.sImpuesto !== "Exento");

        return refinado;
    };

    const grupoPctRetenido = () => {
        let grImp = groupBy(productos, "sPctRetenido");
        let refinado = Object.values(grImp).filter((i) => i.sPctRetenido !== "Exento");

        return refinado;
    };

    const grupoPctRetenidoISLR = () => {
        let grImp = groupBy(productos, "sPctTarifaRetenidaISLR");
        let refinado = Object.values(grImp);

        return refinado;
    };


    const Total = () => {
        let subtotal = 0;
        let dePctRetenido_aux = 0;
        let deRetenidoISLR_aux = 0;
        let total = 0;
        let sMoneda = "";

        for (let mont of productos) {
            subtotal += parseFloat(mont.txtSubTotal);
            dePctRetenido_aux += parseFloat(mont.dePctRetenido);
            deRetenidoISLR_aux += parseFloat(mont.deRetenidoISLR);
            sMoneda = mont.sMoneda;
        }
        //total = parseFloat(nsi) + parseFloat(impt);
        total = (subtotal - deRetenidoISLR_aux) - dePctRetenido_aux;
        return formatCurrency(total) + " " + sMoneda;
    }

    const TotalIgtf = () => {
        let total = 0;
        let sMoneda = "";
        for (let mont of productos) {
            total += parseFloat(mont.txtIgtf);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency(total) + " " + sMoneda;
    }


    const TotalRecibidos = () => {
        let total = 0;
        for (let mont of recibidos) {
            total += parseFloat(mont.deMontoRecibido);
        }
        return formatCurrency(total);
    }

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer="Base Imponible:" colSpan={7} footerStyle={{ textAlign: 'right' }} />
            <Column footer={Nsi} footerStyle={{ textAlign: "right" }} />
        </Row>

        {grupoImpuesto().map((post) => (
            <Row>
                <Column footer={`${post.sImpuesto} (B.I. = ${formatCurrency(post.deBaseImpon)} ${post.sMoneda}): `} colSpan={7} footerStyle={{ textAlign: "right" }} />
                <Column footer={formatCurrency(post.deImpuesto) + " " + post.sMoneda} footerStyle={{ textAlign: "right" }} />
            </Row>
        ))}

        {grupoPctRetenidoISLR().map((post) => (
            <Row>
                <Column footer={`Porcentaje Retenido ISRL: (${post.sPctTarifaRetenidaISLR}): `} colSpan={7} footerStyle={{ textAlign: "right" }} />
                <Column footer={formatCurrency((-1 * post.deRetenidoISLR)) + " " + post.sMoneda} footerStyle={{ textAlign: "right" }} />
            </Row>
        ))}


        {grupoPctRetenido().map((post) => (
            <Row>
                <Column footer={`Porcentaje Retenido (${post.sPctRetenido}): `} colSpan={7} footerStyle={{ textAlign: "right" }} />
                <Column footer={formatCurrency((-1 * post.dePctRetenido)) + " " + post.sMoneda} footerStyle={{ textAlign: "right" }} />
            </Row>
        ))}
        <Row>
            <Column footer="Total IGTF:" colSpan={7} footerStyle={{ textAlign: 'right' }} />
            <Column footer={TotalIgtf} footerStyle={{ textAlign: "right" }} />
        </Row>
        <Row>
            <Column footer="Total:" colSpan={7} footerStyle={{ textAlign: 'right' }} />
            <Column footer={Total} footerStyle={{ textAlign: "right" }} />
        </Row>
    </ColumnGroup>;

    let footerGroupRecibidos = <ColumnGroup>
        <Row>
            <Column footer="Total Recibido:" colSpan={2} footerStyle={{ textAlign: 'right' }} />
            <Column footer={TotalRecibidos} />
        </Row>
    </ColumnGroup>;

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <DataTable ref={dt} value={compras} dataKey="id" paginator rows={10} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} compras"
                        globalFilter={globalFilter} emptyMessage="No existen compras." header={header} >
                        <Column field="txtNumero" header="Nro Orden" sortable body={NumeroBodyTemplate}></Column>
                        <Column field="txtFechaCompra" sortable header="Fecha" body={FechaCompraTemplate} ></Column>
                        <Column field="txtProveedor" header="Proveedor" sortable body={ProveedorBodyTemplate}></Column>
                        <Column field="txtItems" header="Productos" sortable body={ItemsBodyTemplate}></Column>
                        <Column field="txtEstatus" header="Estatus" sortable body={EstatusBodyTemplate}></Column>
                        <Column field="txtEstatusRecepcion" header="Recepcion" sortable body={EstatusRecepcionBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={recibirProductoDialog} maximizable modal style={{ width: '50vw' }} header="Detalles del Proveedor" className="p-fluid" onHide={hideDialog}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        <TabView className="tabview-custom">
                            <TabPanel header="Datos Básicos" leftIcon="pi pi-user">
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="cboProveedor">Proveedor</label>
                                        <InputText disabled name="txtProveedor" value={values.txtProveedor} onChange={handleInputChange} autoFocus />
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtFechaOrden">Fecha de la orden</label>
                                        <InputText disabled name="txtFechaOrden" value={values.txtFechaOrden} onChange={handleInputChange} autoFocus />
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtReferenciaV">Referencia del Vendedor</label>
                                        <InputText disabled name="txtReferenciaV" value={values.txtReferenciaV} onChange={handleInputChange} autoFocus />
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtNroControl">Nro Control del Vendedor</label>
                                        <InputText disabled name="txtNroControl" value={values.txtNroControl} onChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtNroControl })} />
                                        {submitted && !values.txtNroControl && <small className="p-invalid">{errors.txtNroControl}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-flied p-col">
                                        <label htmlFor="cboMoneda">Moneda</label>
                                        <Dropdown disabled id="cboMoneda" name="cboMoneda" value={values.cboMoneda} onChange={handleInputChange} optionValue="id" optionLabel="title" options={MonedaOptions} placeholder="Selecciona uno" ></Dropdown>
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtObservacion">Observación</label>
                                        <InputTextarea disabled name="txtObservacion" value={values.txtObservacion} onChange={handleInputChange} autoFocus />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Items de la Compra" leftIcon="pi pi-dollar">
                                <div className="p-grid crud-demo">
                                    <div className="p-col-12">
                                        <div className="card">
                                            <DataTable ref={dt} value={productos} dataKey="id" footerColumnGroup={footerGroup}>
                                                <Column field="sProducto" header="Producto" body={ProductoBodyTemplate}></Column>
                                                <Column field="txtDescripcion" header="Descripcion" body={DescripcionBodyTemplate}></Column>
                                                <Column field="txtCantidad" header="Cant." body={CantidadBodyTemplate}></Column>
                                                <Column field="txtPrecioUnit" header="Precio Unit." body={PrecioUnitBodyTemplate}    ></Column>
                                                <Column field="cboImpuesto" header="Alicuota" body={ImpuestoBodyTemplate}></Column>
                                                <Column field="txtIgtf" header="ITGF (3%)" body={IgtfBodyTemplate}></Column>
                                                <Column field="txtBaseImponible" header="Base Imponible" body={BaseImponibleBodyTemplate}></Column>
                                                <Column field="txtSubTotal" header="Sub-Total" body={SubTotalBodyTemplate}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Recepcion de Productos" leftIcon="pi pi-dollar">
                                <div className="p-grid crud-demo">
                                    <div className="p-col-12">
                                        <div className="card">
                                            <Toast ref={toast} />
                                            <Toolbar className="p-mb-4" left={leftToolbarTemplateP} ></Toolbar>
                                            <DataTable ref={dt} value={recibidos} dataKey="id" footerColumnGroup={footerGroupRecibidos}>
                                                <Column field="dtFechaRecepcion" sortable header="Fecha Recep." body={FechaRecepTemplate} ></Column>
                                                <Column field="sProducto" header="Producto / Serv." sortable body={ProductoAuxBodyTemplate}></Column>
                                                <Column field="deMontoRecibido" header="Cantidad" sortable body={CantRecibidaBodyTemplate}></Column>
                                                <Column field="sObservacion" header="Observacion" body={ObservacionAuxBodyTemplate}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </TabView>
                    </Dialog>

                    {/* Modal Recipcion de Productos */}
                    <Dialog visible={recepcionDialog} style={{ width: '550px' }} header="Detalle de Recepcion" modal className="p-fluid" footer={RecepcionDialogFooter} onHide={hideDialogRecepcion}>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtFechaRecepcion">Fecha de Recepción</label>
                                <Calendar showIcon showButtonBar dateFormat="dd/m/yy" id="txtFechaRecepcion" name="txtFechaRecepcion" value={values.txtFechaRecepcion} onChange={handleInputChange} monthNavigator yearNavigator yearRange="2010:2030" required className={classNames({ 'p-invalid': submitted && !values.txtFechaRecepcion })} />
                                {submitted && !values.txtFechaRecepcion && <small className="p-invalid">{errors.txtFechaRecepcion}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cboProducto">Producto / Servicio</label>
                                <Dropdown id="cboProducto" name="cboProducto" value={values.cboProducto} onChange={handleInputChange} optionValue="id" optionLabel="title" options={productosRecepOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboProducto })}></Dropdown>
                                {submitted && !values.cboProducto && <small className="p-invalid">{errors.cboProducto}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtCantidadRecepcion">Cant. Recibida</label>
                                <InputNumber name="txtCantidadRecepcion" mode="decimal" minFractionDigits={2} value={values.txtCantidadRecepcion} onValueChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtCantidadRecepcion })} />
                                {submitted && !values.txtCantidadRecepcion && <small className="p-invalid">{errors.txtCantidadRecepcion}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtObservacionRecep">Observación</label>
                                <InputTextarea name="txtObservacionRecep" value={values.txtObservacionRecep} onChange={handleInputChange} autoFocus />
                            </div>
                        </div>
                    </Dialog>


                    <Dialog visible={RecepcionProducDialog} style={{ width: '450px' }} header="Confirm" modal footer={recepcionProducDialogFooter} onHide={hideRecepcionProducDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {compra && <span>Esta operacion no podrá ser reversada o eliminada. Verifique que los datos sean correctos. ¿Esta Seguro de Recibir este producto / Servicio?</span>}
                        </div>
                    </Dialog>

                </div>
            </div>
            <div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
}
