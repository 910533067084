import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import FacturaDetalles from './FacturaDetalles'

export default function Factura(props) {
	
	const factura = props.data;
	const tipo_imp = props.tipoImp;
	const datosContrib = props.datosContrib
	
	const margenFactura = props.margenes;
	let logo = props.datosContrib.logo;
	let recorte = logo.indexOf("assets");
	const logoC = logo.substring(recorte);
	const saltoLinea = 1.5;
	const saltoBloque = 20;
	const fontSizeInvoice = 11;
	const colorMain = '#4B4E59';

	const styles = StyleSheet.create({
		page: {
			fontFamily: 'Helvetica',
			fontSize: 11,
			paddingTop: margenFactura.sup,
			paddingLeft:50,
			paddingRight:50,
			lineHeight: 1.5,
			flexDirection: 'column',
			
		}, 
		logo: {
			width: 74,
			height: 66,
		}, 
		headerContainer: {
			// marginTop: 36
			flexDirection: 'row',
			lineHeight: 1.2,
			marginBottom: saltoBloque
		},
		reportTitle:{
			color: colorMain,
			letterSpacing: 4,
			fontSize: 20,
			textAlign: 'left',
			textTransform: 'uppercase',
			fontStyle:  'bold',
			fontFamily: 'Helvetica-Bold',
			paddingTop: saltoLinea
		},
		
		headerContribContainer: {
			// marginTop: 36
			flexDirection: 'column',
			color: colorMain,
			fontSize: 10,
			textAlign: 'left',
			width: '75%'
		},
		logoContainer: {
			width: '25%',
			flexDirection: 'row',
			justifyContent: 'flex-end'
		},
		contribName:{
			fontSize: 12,
			textTransform: 'uppercase',
			fontStyle:  'bold',
			fontFamily: 'Helvetica-Bold',
		},
		invoiceNoContainer: {
			marginTop: saltoLinea*2
		},
		invoiceData: {
			fontSize: fontSizeInvoice,
			fontStyle: 'normal'
			// width: "20%",
		},
		label: {
			width: "25%",
			textAlign: 'left',
			marginRight: 10,
			fontSize: fontSizeInvoice,
			fontStyle: 'bold',
			fontFamily: 'Helvetica-Bold'
		},
		labelRight: {
			width: "85%",
			textAlign: 'right',
			marginRight: 10,
			fontSize: fontSizeInvoice,
			fontStyle: 'bold',
			fontFamily: 'Helvetica-Bold'
		},
		headerClienteContainer: {
			marginTop: saltoBloque,
		},
		borde: {
			borderStyle: 'solid',
			borderWidth: '1px',
			borderColor: 'red',
		},
		row:{
			flexDirection: 'row',
		}
	  });

	return (
		<Document>
			<Page size="LETTER" style={[styles.page ]}>	
				{ tipo_imp !== 'FL' &&
				<View style={[styles.headerContainer]}>
					<View style={[styles.headerContribContainer]}>
						<Text style={styles.reportTitle}>FACTURA</Text>
						<Text style={styles.contribName}>{ datosContrib.nombreC }</Text>
						<Text>RIF: { datosContrib.rifC }</Text>
						<Text>{ datosContrib.direccionC }</Text>
						<Text>Telf: { datosContrib.telefonoC }</Text>
					</View>
					<View style={[styles.logoContainer]}>
						<Image style={styles.logo} src={logoC} />
					</View>
				</View>	
				}
				<View style={styles.invoiceNoContainer}>
					{ tipo_imp !== 'FL' &&
					<View style={styles.row}>
						<Text style={styles.labelRight}>N° de Control: </Text>
						<Text style={styles.invoiceData}>{factura.txtNroControl}</Text>
					</View >
					}
					<View style={styles.row}>
						<Text style={styles.labelRight}>N° de Factura: </Text>
						<Text style={styles.invoiceData}>{factura.txtNroFactura}</Text>
					</View >
					
					<View style={styles.row}>
						<Text style={styles.labelRight}>Fecha Emisión: </Text>
						<Text style={styles.invoiceData}>{factura.txtFechaFactura}</Text>
					</View >
				</View>
				<View style={styles.headerClienteContainer}>
					<View style={styles.row}> 
						<Text style={styles.label}>Nombre o Razón Social: </Text>
						<Text style={styles.invoiceData}>{factura.txtCliente}</Text>
					</View>
					<View style={styles.row}> 
						<Text style={styles.label}>RIF: </Text>
						<Text style={styles.invoiceData}>{factura.txtRifCliente}</Text>
					</View>
					<View style={styles.row}> 
						<Text style={styles.label}>Dirección: </Text>
						<Text style={styles.invoiceData}>{factura.txtDireccionCliente}</Text>
					</View>
					<View style={styles.row}> 
						<Text style={styles.label}>Teléfono: </Text>
						<Text style={styles.invoiceData}>{factura.txtTelefonoCliente}</Text>
					</View>
				</View>
				<FacturaDetalles factura={factura} />
			</Page>
		</Document>
    );
}
