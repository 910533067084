import { Redirect, Route } from 'react-router-dom';
import { loggedIn } from '../service/AuthHelperMethods';

export default function PrivateRoute({ component: Component, ...rest}) {
	
	return (
		<Route {...rest}>
			{ 
			loggedIn() ? <Component /> : <Redirect to="/login" />
			}
		</Route>
	)
}
