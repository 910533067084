import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData, configPermisosRW } from '../../service/AuthHelperMethods';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import ShowLoading from "../../components/ShowLoading/ShowLoading";

const initialFValues = {
    cboTipoDeclaracion : '',
	txtFechaDeclaracion : '',
	txtInicioPeriodo : '',
	txtFinPeriodo : '',
	txtMontoDeclarado : 0,
	txtNroCertificado: '',
	txtFileCertificado: '',
	txtContribuyente: '',
	txtUsuario: '',
    caso: 0,
    id: '', 
	txtFechaPago : '',
	txtNroSoporte : '',
	txtMontoPago : 0,
	txtObservacionPago : 0,
};

export default function AdmDeclaraciones() {
    let emptyDeclaracion = {
		cboTipoDeclaracion : '',
		txtFechaDeclaracion : '',
		txtInicioPeriodo : '',
		txtFinPeriodo : '',
		txtMontoDeclarado : 0,
		txtNroCertificado: '',
		txtFileCertificado: '',
    	txtFechaPago : '',
		txtNroSoporte : '',
		txtMontoPago : 0,
		txtObservacionPago : '',
    };

	let emptyPago = {
		txtFechaPago : '',
		txtNroSoporte : '',
		txtMontoPago : 0,
		txtObservacionPago : '',
	}

	let location = useLocation();
	const [acceso, setAcceso] = useState("");
    const toast = useRef(null);
    const [declaracionDialog, setDeclaracionDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [userData] = useState(getUserData() || null);
    const [httpService] = useState(new HttpService());
    const [declaraciones, setDeclaraciones] = useState([]);
    const [declaracion, setDeclaracion] = useState(emptyDeclaracion);
    const [tipoDeclaracionOptions, setTipoDeclaracionOptions] = useState([]);
    const [caso, setCaso] = useState(true);
    const [disableEdit, setDisableEdit] = useState(false);
    const [certificado, setCertificado] = useState(null);
	const [pagosDeclaracionDialog, setPagosDeclaracionDialog] = useState(false);
	const [addPagosDeclaracionDialog, setAddPagosDeclaracionDialog] = useState(false);
	const [pagos, setPagos] = useState([]);
	const [loadingDialog, setLoadingDialog] = useState(false);
	const [deberFormal, setDeberFormal] = useState([]);
	const [txtInicioPeriodo, setTxtInicioPeriodo] = useState(null);
	const [txtFinPeriodo, setTxtFinPeriodo] = useState(null);
	const [disableEditP, setDisableEditP] = useState(false);

    useEffect(() => {
		setLoadingDialog(true);
		let acc = configPermisosRW(location, userData);
		setAcceso(acc);
        httpService.getOptionSelect("tipo_declaracion").then(data => setTipoDeclaracionOptions(data));
        httpService.getTableSelect("tabla_declaraciones", userData.contribuyente)
		.then(data => {setDeclaraciones(data); setLoadingDialog(false);})
		.catch(err => setLoadingDialog(false));
    }, [httpService, location, userData]);

    const [ButtonDisabled, setButtonDisabled] = useState(false);
    
	const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboTipoDeclaracion" in fieldValues) temp.cboTipoDeclaracion = fieldValues.cboTipoDeclaracion ? "" : "Campo Requerido.";
        if ("txtFechaDeclaracion" in fieldValues) temp.txtFechaDeclaracion = fieldValues.txtFechaDeclaracion ? "" : "Campo Requerido.";
        if ("txtInicioPeriodo" in fieldValues) temp.txtInicioPeriodo = fieldValues.txtInicioPeriodo ? "" : "Campo Requerido.";
        if ("txtFinPeriodo" in fieldValues) temp.txtFinPeriodo = fieldValues.txtFinPeriodo ? "" : "Campo Requerido.";
        if ("txtNroCertificado" in fieldValues) temp.txtNroCertificado = fieldValues.txtNroCertificado ? "" : "Campo Requerido.";
        if ("txtFileCertificado" in fieldValues) temp.txtFileCertificado = fieldValues.txtFileCertificado ? "" : "Campo Requerido.";
        
		setErrors({
            ...temp,
        });
        if (fieldValues === values) {
			return Object.values(temp).every((x) => x === "");
		}
    };
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    const handleSubmit = async (e) => {
        setSubmitted(true);
        e.preventDefault();
		values.txtContribuyente = userData.contribuyente;
		values.caso = caso;
		values.txtUsuario = userData.idUsuario;
		values.txtInicioPeriodo = txtInicioPeriodo;
		values.txtFinPeriodo = txtFinPeriodo;
		values.txtIdNotificacion = deberFormal.idNotificacion;
        if (validate()) {
			setLoadingDialog(true);
			setButtonDisabled(true);
			const f = new FormData();
			f.append("cboTipoDeclaracion", values.cboTipoDeclaracion);
			f.append("txtFechaDeclaracion", values.txtFechaDeclaracion);
			f.append("txtInicioPeriodo", values.txtInicioPeriodo);
			f.append("txtFinPeriodo", values.txtFinPeriodo);
			f.append("txtMontoDeclarado", values.txtMontoDeclarado);
			f.append("txtContribuyente", values.txtContribuyente);
			f.append("txtUsuario", values.txtUsuario);
			f.append("caso", values.caso);
			f.append("id", values.id);
			f.append("txtNroCertificado", values.txtNroCertificado);
			f.append("txtIdNotificacion", values.txtIdNotificacion);
			
			if (certificado){
				for (let index = 0; index < certificado.length; index++) {
					f.append("txtFileCertificado", certificado[index]);
				}
			}

            httpService.registrarDeclaracion(f)
			.then((res) => {
				setButtonDisabled(false);
				if (res.error) {
					setLoadingDialog(false);
					toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
				} else {
					setDeclaracionDialog(false);
					resetForm();
					httpService.getTableSelect("tabla_declaraciones", userData.contribuyente)
					.then(data => {setDeclaraciones(data); setLoadingDialog(false); toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });})
					.catch(err => {setLoadingDialog(false); toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });});
				}
			})
			.catch((err) => {
				setLoadingDialog(false);
				setButtonDisabled(false);
				toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });

			});
        } else {
			console.log("Formulario no válido.");
		}
    };

	const validate2 = (fieldValues = values) => {
        let temp = { ...errors };
        if ("txtMontoPago" in fieldValues) temp.txtMontoPago = fieldValues.txtMontoPago ? "" : "Campo Requerido.";
        if ("txtFechaPago" in fieldValues) temp.txtFechaPago = fieldValues.txtFechaPago ? "" : "Campo Requerido.";
        if ("txtNroSoporte" in fieldValues) temp.txtNroSoporte = fieldValues.txtNroSoporte ? "" : "Campo Requerido.";
        setErrors({...temp,});

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };
	
	const [setButtonDisabledPagos] = useState(false);
	const handleSubmitPagos = async (e) => {
        setSubmitted(true);
		if (validate2()) {
			setAddPagosDeclaracionDialog(false);
			setLoadingDialog(true);
			setButtonDisabledPagos(true);
			values.nIdUsuario_aux = userData.idUsuario;
			values.id = declaracion.id;
			values.nIdContribuyente = declaracion.nIdContribuyente;
			values.txtMontoDeclarado = declaracion.txtMontoDeclarado
			httpService.agregarPagoDeclaracion(values)
				.then((res) => {
					setSubmitted(false);
					if (res.error) {
						setLoadingDialog(false);
						toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
					} else {
						setButtonDisabledPagos(false);
						httpService.getTableSelect("tabla_declaraciones", userData.contribuyente).then(data => setDeclaraciones(data));
						httpService.getTableSelect("tabla_pagos_declaraciones", userData.contribuyente, values.id).then(data => {
							setPagos(data); 
							setLoadingDialog(false);
							toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
						})
						.catch(err => {
							setLoadingDialog(false);
							toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
						});
					}
				})
				.catch((err) => {
					setLoadingDialog(false);
					toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
					setButtonDisabledPagos(false);
				});
		}

    };

    const openNew = () => {
        setCaso(0);
		setDisableEdit(false);
		setDisableEditP(true);
		setTxtInicioPeriodo(null);
		setTxtFinPeriodo(null);
        setValues(emptyDeclaracion);
        setSubmitted(false);
        setDeclaracionDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setDeclaracionDialog(false);
    }

	const editDeclaracion = (declaracion) => {
        setCaso(1);
		setDisableEdit(true);
		setDisableEditP(true);
        setValues(declaracion);
        setDeclaracionDialog(true);
    }
	
	const openPagosDeclaracionDialog = (declaracion) => {
		setLoadingDialog(true);
        setDeclaracion(declaracion);
		setValues(declaracion);
		httpService.getTableSelect("tabla_pagos_declaraciones", userData.contribuyente, declaracion.id)
		.then(data => {
			setPagos(data);
			setLoadingDialog(false);
			setPagosDeclaracionDialog(true);
		});
        
    }
	const openNewPagoDeclaracion = () => {
		setValues(emptyPago);
        setAddPagosDeclaracionDialog(true);
    }
	
	/* const confirmUptEstatusDeclaracion = (declaracion) => {
        setDeclaracion(declaracion);
        setPagosDeclaracionDialog(true);
    } */

	const onHide = (stateMethod) => {
        stateMethod(false);
    }

    const pagosDeclaracionDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={() => onHide(setPagosDeclaracionDialog)} />
        </>
    );

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} disabled={ acceso && acceso === "W" ? false : true } />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={ acceso && acceso === "W" ? (!selectedDeclaraciones || !selectedDeclaraciones.length) : true } />
            </React.Fragment>
        )
    }

	

	const detallPagoDeclaracionDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={() => onHide(setAddPagosDeclaracionDialog)} />
            <Button label="Guardar" icon="pi pi-plus" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmitPagos} />
        </>
    );
    
	const leftToolbarTemplateP = () => {
        return (
            <React.Fragment>
                <Button label="Agregar" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={openNewPagoDeclaracion} disabled={ acceso && acceso === "W" ? false : true } />
            </React.Fragment>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Administrador de Declaraciones</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const declaracionesDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmit} />
        </>
    );

	const enlaceBodyTemplate = (rowData) => {
		let recorte = rowData.certificadoRuta.indexOf("assets");
		const fileDec = rowData.certificadoRuta.substring(recorte);
		
		if (rowData.sArchivoCertificado !== "" && rowData.sArchivoCertificado !==null){
			return (
				<a href={fileDec} target="_blank" rel="noreferrer">{rowData.txtNroCertificado}</a>
			);
			
		} else {
			return (
				<></>
			)
		}
      
    };

	const estatusBodyTemplate = (rowData) => {
        return (
			 <>
                <span className="p-column-title"></span>
                <span className={`product-badge ${rowData.sEstilo}`}>{rowData.sEstatus}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
		let disableEditar = (rowData.sEstatus === "PAGADA" || rowData.sEstatus === "PARCIALMENTE PAGADA") ? true : false;
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-mr-2" onClick={() => editDeclaracion(rowData)} tooltip="Editar Declaración" tooltipOptions={{position: "top"}} disabled={ acceso && acceso === "W" ? disableEditar : true } />
				<Button icon="pi pi-dollar" className="p-button-rounded p-button-info p-mr-2" onClick={() => openPagosDeclaracionDialog(rowData)} tooltip="Registrar Pago a Declaración" tooltipOptions={{position: "top"}}  />
            </div>
        );
    };

    const [deleteDeclaracionesDialog, setDeleteDeclaracionesDialog] = useState(false);

    const [selectedDeclaraciones, setSelectedDeclaraciones] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const hideDeleteDeclaracionesDialog = () => {
        setDeleteDeclaracionesDialog(false);
    }

    const confirmDeleteSelected = () => {
        setDeleteDeclaracionesDialog(true);
    }

    const deleteSelectedDeclaraciones = async (e) => {
        e.preventDefault();
        httpService.deleteDeclaraciones(selectedDeclaraciones)
            .then((res) => {
                if (res.error) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                } else {
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                    setDeleteDeclaracionesDialog(false);
                    setSelectedDeclaraciones(null);
                    httpService.getTableSelect("tabla_declaraciones", userData.contribuyente, "").then(data => setDeclaraciones(data));
                }
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
    }

    const deleteDeclaracionesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDeclaracionesDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedDeclaraciones} />
        </>
    );

	const formatCurrency = function (number) {
		const options = { minimumFractionDigits: 2}
		const numberFormat1 = new Intl.NumberFormat('es-VE' , options);
		const numero = numberFormat1.format(number);
		return numero;
    };

	const TotalPagos = () => {
        let total = 0;
        for (let mont of pagos) {
            total += parseFloat(mont.txtMontoPago);
        }
        return formatCurrency(total);
    }

	const TotalDeclaracion = () => {
        let total = parseFloat(declaracion.txtMontoDeclarado);
        return formatCurrency(total);
    }

	const RestaPorPagar = () => {
        let totalPagos = 0;
        let totalDeclaracion = 0;
        let total = 0;
		totalDeclaracion = parseFloat(declaracion.txtMontoDeclarado);
		for (let mont of pagos) {
            totalPagos += parseFloat(mont.txtMontoPago);
        }
		total = totalDeclaracion - totalPagos;
        return formatCurrency(total);
    }

	let footerGroupPagos = <ColumnGroup>
        <Row>
            <Column footer="Total Pagos:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
            <Column footer={TotalPagos} footerStyle={{ textAlign: 'center' }}/>
        </Row>
        <Row>
            <Column footer="Total Declaración:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
            <Column footer={TotalDeclaracion} footerStyle={{ textAlign: 'center' }} />
        </Row>
        <Row>
            <Column footer="Restan por pagar:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
            <Column footer={RestaPorPagar} footerStyle={{ textAlign: 'center' }} />
        </Row>
    </ColumnGroup>;
	
	const subirArchivos = e=>{
        setCertificado(e);
    }

	const cargarPeriodo = e => {
		let idTipoDeclaracion = e.target.value;
		let nombreDF = "";

		if (idTipoDeclaracion !== undefined) {
			const resultado = tipoDeclaracionOptions.find( td => td.id === idTipoDeclaracion );
			nombreDF = resultado.valor;
			let datos = {
				txtContribuyente: userData.contribuyente,
				tipoDeclaracion: nombreDF
			}
			httpService.getGeneralData("declaracion_pendiente", datos).then((data) => {
				console.log("PERIODO ", data)
				setDeberFormal(data);
				setTxtInicioPeriodo(data.txtInicioPeridoImposicion);
				setTxtFinPeriodo(data.txtFinPeridoImposicion);
				if (data.txtInicioPeridoImposicion === null || data.txtInicioPeridoImposicion === "" || data.txtInicioPeridoImposicion === undefined) {
					setDisableEditP(false);
				}
			});

		}
		console.log(nombreDF);
	}

	const sortDate = (arr, e) => {
		var valores = arr;
		valores.sort(function(a,b){
			var aFecha1 = b[e.field].split("/");
			var aFecha2 = a[e.field].split("/");
			var fecha1 = aFecha1[2] + "-" +   aFecha1[1] + "-" + aFecha1[0];
			var fecha2 = aFecha2[2] + "-" +   aFecha2[1] + "-" + aFecha2[0];
			if (e.order < 0) {
				return new Date(fecha1) - new Date(fecha2)
			} else {
				return new Date(fecha2) - new Date(fecha1);
			}
		  });
		return valores;
	}

	return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable  ref={dt} value={declaraciones} selection={selectedDeclaraciones} onSelectionChange={(e) => setSelectedDeclaraciones(e.value)}
                        dataKey="id" paginator rows={10} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Declaraciones"
                        globalFilter={globalFilter} emptyMessage="No existen Declaraciones" header={header} >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="sTipoDeclaracion" header="Tipo de Declaración" sortable></Column>
                        <Column field="sPeriodo" header="Periodo" sortable headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column field="txtFechaDeclaracion" header="Fecha" sortable sortFunction={(e) => sortDate(declaraciones, e)} headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column field="txtMontoDeclarado" header="Monto" sortable headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column field="txtNroCertificado" header="Certificado"  body={enlaceBodyTemplate} sortable headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column field="sEstatus" header="Estatus" sortable  body={estatusBodyTemplate} headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column headerStyle={{ width: "15%", textAlign: 'center' }} body={actionBodyTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>

                    <Dialog visible={declaracionDialog} style={{ width: '550px' }}  header="Declaración" modal className="p-fluid" footer={declaracionesDialogFooter} onHide={hideDialog}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        
                        <div className="p-formgrid p-grid">
							<div className="p-field p-col">
                                <label htmlFor="cboTipoDeclaracion">Tipo de Declaración</label>
                                <Dropdown id="cboTipoDeclaracion" name="cboTipoDeclaracion" disabled={disableEdit} value={values.cboTipoDeclaracion} onChange={(e) => {handleInputChange(e); cargarPeriodo(e)}} optionValue="id" optionLabel="title" options={tipoDeclaracionOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboTipoDeclaracion })}></Dropdown>
                                {submitted && !values.cboTipoDeclaracion && <small className="p-invalid">{errors.cboTipoDeclaracion}</small>}
                            </div>
							<div className="p-field p-col">
                                <label htmlFor="txtFechaDeclaracion">Fecha de Declaración</label>
								{/* <Calendar id="sPeriodo" name="sPeriodo" value={values.sPeriodo} onChange={handleInputChange} dateFormat="dd/mm/yy" yearNavigator yearRange="2010:2050" showIcon /> */}
								<div className="p-inputgroup">
									<InputMask id="txtFechaDeclaracion" name="txtFechaDeclaracion" mask="99/99/9999" value={values.txtFechaDeclaracion} slotChar="dd/mm/yyyy" onChange={handleInputChange} className={classNames({ 'p-invalid': submitted && !values.txtFechaDeclaracion })}></InputMask> 
									<span className="p-inputgroup-addon"><i className="pi pi-calendar"></i></span>
								</div>
                                {submitted && !values.txtFechaDeclaracion && <small className="p-invalid">{errors.txtFechaDeclaracion}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
							<div className="p-field p-col">
                                <label htmlFor="txtNombre">Fecha inicial del Periodo</label>
                                <div className="p-inputgroup">
									<InputMask id="txtInicioPeriodo" name="txtInicioPeriodo" mask="99/99/9999" disabled={disableEditP} value={txtInicioPeriodo} slotChar="dd/mm/yyyy" onChange={(e) => setTxtInicioPeriodo(e.target.value)} className={classNames({ 'p-invalid': submitted && !values.txtInicioPeriodo })}></InputMask> 
									<span className="p-inputgroup-addon"><i className="pi pi-calendar"></i></span>
								</div>
                                {submitted && !values.txtInicioPeriodo && <small className="p-invalid">{errors.txtInicioPeriodo}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="txtNombre">Fecha final del Periodo</label>
                                <div className="p-inputgroup">
									<InputMask id="txtFinPeriodo" name="txtFinPeriodo" mask="99/99/9999" disabled={disableEditP} value={txtFinPeriodo} slotChar="dd/mm/yyyy" onChange={(e) => setTxtFinPeriodo(e.target.value)} className={classNames({ 'p-invalid': submitted && !values.txtFinPeriodo })}></InputMask> 
									<span className="p-inputgroup-addon"><i className="pi pi-calendar"></i></span>
								</div>
                                {submitted && !values.txtFinPeriodo && <small className="p-invalid">{errors.txtFinPeriodo}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
							<div className="p-field p-col-6">
                            	<label htmlFor="txtMontoDeclarado">Monto a Pagar</label>
                                <InputNumber name="txtMontoDeclarado" value={values.txtMontoDeclarado} mode="decimal" minFractionDigits={2} onValueChange={handleInputChange} required />
                                {/* {submitted && !values.txtMontoDeclarado && <small className="p-invalid">{errors.txtMontoDeclarado}</small>} */}
                            </div>
							<div className="p-field p-col-6">
                            	<label htmlFor="txtNroCertificado">Nro. Planilla</label>
                                <InputText name="txtNroCertificado" value={values.txtNroCertificado} onChange={handleInputChange} className={classNames({ 'p-invalid': submitted && !values.txtNroCertificado })}/>
								{submitted && !values.txtNroCertificado && <small className="p-invalid">{errors.txtNroCertificado}</small>}
                            </div>
                        </div>
						<div className="p-formgrid p-grid">
							<div className="p-field p-col">
								<label htmlFor="txtFileCertificado">Planilla de Declaración</label>
								<input name="txtFileCertificado" type="file" onChange={(e) => {handleInputChange(e); subirArchivos(e.target.files)}} className={classNames("form-control", { 'p-invalid': submitted && !values.txtFileCertificado })}/>
								{submitted && !values.txtFileCertificado && <small className="p-invalid">{errors.txtFileCertificado}</small>}
							</div>
						</div>
                    </Dialog>
					
					<Dialog visible={pagosDeclaracionDialog} style={{ width: '650px' }} header="Pagos" modal footer={pagosDeclaracionDialogFooter} onHide={() => onHide(setPagosDeclaracionDialog)}>
						<div className="p-grid crud-demo">
							<div className="p-col-12">
								<div className="card">
									<Toast ref={toast} />
									<Toolbar className="p-mb-4" left={leftToolbarTemplateP} ></Toolbar>
									<DataTable ref={dt} value={pagos} dataKey="id" footerColumnGroup={footerGroupPagos} >
										<Column field="txtFechaPago" sortable header="Fecha"></Column>
										<Column field="txtNroSoporte" header="Nro Soporte" sortable bodyStyle={{textAlign: "center"}} headerStyle={{textAlign: "center"}}></Column>
										<Column field="txtObservacionPago" header="Observacion" bodyStyle={{textAlign: "center"}} headerStyle={{textAlign: "center"}}></Column>
										<Column field="txtMontoPago" header="Monto" sortable bodyStyle={{textAlign: "center"}}  headerStyle={{textAlign: "center"}}></Column>
									</DataTable>
								</div>
							</div>
						</div>
                    </Dialog>

                    <Dialog visible={addPagosDeclaracionDialog} style={{ width: '550px' }} header="Detalle de Pagos" modal className="p-fluid" footer={detallPagoDeclaracionDialogFooter} onHide={() => onHide(setAddPagosDeclaracionDialog)}>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtFechaPago">Fecha de Pago</label>
                                <div className="p-inputgroup">
									<InputMask id="txtFechaPago" name="txtFechaPago" mask="99/99/9999" value={values.txtFechaPago} slotChar="dd/mm/yyyy" onChange={handleInputChange} className={classNames({ 'p-invalid': submitted && !values.txtFechaPago })}></InputMask> 
									<span className="p-inputgroup-addon"><i className="pi pi-calendar"></i></span>
								</div>
                                {submitted && !values.txtFechaPago && <small className="p-invalid">{errors.txtFechaPago}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="txtNroSoporte">Nro. de Soporte</label>
                                <InputText name="txtNroSoporte" value={values.txtNroSoporte} onChange={handleInputChange} className={classNames({ 'p-invalid': submitted && !values.txtNroSoporte })} />
								{submitted && !values.txtNroSoporte && <small className="p-invalid">{errors.txtNroSoporte}</small>}
                            </div>
                        </div>
						<div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtMontoPago">Monto</label>
                                <InputNumber name="txtMontoPago" value={values.txtMontoPago} mode="decimal" minFractionDigits={2} onValueChange={handleInputChange} required className={classNames({ 'p-invalid': submitted && !values.txtMontoPago })} />
								{submitted && !values.txtMontoPago && <small className="p-invalid">{errors.txtMontoPago}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtObservacionPago">Observación</label>
                                <InputTextarea name="txtObservacionPago" value={values.txtObservacionPago} onChange={handleInputChange} />
                            </div>
                        </div>
                    </Dialog>
					
                    <Dialog visible={deleteDeclaracionesDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteDeclaracionesDialogFooter} onHide={hideDeleteDeclaracionesDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {declaracion && <span>¿Esta Seguro de Eliminar la Declaración?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
}
