import React from 'react';
import Principal from './Principal';
import { initAxiosInterceptors } from './service/AuthHelperMethods';

initAxiosInterceptors();

const App = () => {

    return (
		<Principal />
    );
}

export default App;
