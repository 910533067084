import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData, configPermisosRW } from '../../service/AuthHelperMethods';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import swal from 'sweetalert';
import ShowLoading from "../../components/ShowLoading/ShowLoading";

const initialFValues = {
    /*     cboBanco: '',
        txtTitular: '',
        txtRif: '',
        cboMoneda: 0,
        txtCuenta: '',
        txtObservacion: '', */
    txtContribuyente: '',
    caso: 0,
    idCb: '',
    nIdProveedor_aux: 0,
    nIdUsuario_aux: 0,
    txtRazonSocial: '',
    txtRifProv: '',
    txtRepresentanteLegal: '',
    txtTelefono: '',
    txtMovil: '',
    txtPersonaContacto: '',
    txtEmail: '',
    txtWebSite: '',
    txtDireccion: '',
    txtObservacionProv: '',
    cboPctRetencion: 0,
    cboTipoProveedor: 0,
    id: ''
};

export default function AdmProveedores() {

    let emptyProvee = {
        txtRazonSocial: '',
        txtRifProv: '',
        txtRepresentanteLegal: '',
        txtTelefono: '',
        txtMovil: '',
        txtPersonaContacto: '',
        txtEmail: '',
        txtWebSite: '',
        txtDireccion: '',
        txtObservacionProv: '',
        cboPctRetencion: 0,
        cboTipoProveedor: 0,
        id: ''
    };

    let emptyCB = {
        cboBanco: '',
        txtTitular: '',
        txtRif: '',
        cboMoneda: 0,
        txtCuenta: '',
        txtObservacion: '',
        idCb: '',
        nIdProveedor_aux: 0,
        nIdUsuario_aux: 0
    };

    let location = useLocation();
    const [acceso, setAcceso] = useState("");

    const myToast = useRef(null);
    const [ProveedorDialog, setProveedorDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [userData] = useState(getUserData() || null);
    const [httpService] = useState(new HttpService());
    const [proveedores, setProveedores] = useState(null);
    const [caso, setCaso] = useState(true);
    const [casoCB, setCasoCB] = useState(true);
    const [ProveedorCbDialog, setProveedorCbDialog] = useState(false);
    const [BancoOptions, setBancoOptions] = useState([]);
    const [MonedaOptions, setMonedaOptions] = useState([]);
    const [pctRetencionOptions, setPctRetencionOptions] = useState([]);
    const [cuentasBancarias, setCuentasBancarias] = useState(null);
    const [labelBoton, setLabelBoton] = useState(null);
    const [valiEmail, setValiEmail] = useState(false);
    const [valiPctRetencion, setValiPctRetencion] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [tipoProveedorOptions, setTipoProveedorOptions] = useState([]);

    useEffect(() => {
        let acc = configPermisosRW(location, userData);
        setAcceso(acc);
    }, [location, userData]);

    useEffect(() => {
        setLoadingDialog(true);
        httpService.getOptionSelect("lista_TipoProveedor").then(data => setTipoProveedorOptions(data));
        httpService.getOptionSelect("lista_bancos").then(data => setBancoOptions(data));
        httpService.getOptionSelect("lista_Monedas").then(data => setMonedaOptions(data));
        httpService.getOptionSelect("lista_pct_retencion").then(data => setPctRetencionOptions(data));
        httpService.getTableSelect("tabla_proveedores", userData.contribuyente, '')
            .then(data => { setProveedores(data); setLoadingDialog(false); })
            .catch(err => setLoadingDialog(false));
    }, [httpService, userData]);

    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const [pctRetenidoDisabled, setPctRetenidoDisabled] = useState(false);

	const showSuccess = (message) => {
		myToast.current.show({severity: 'success', summary: 'Éxito', detail: message, life: 3000});
	}

	const showError = (message) => {
		myToast.current.show({severity:'error', summary: 'Error', detail: message, life: 3000});
	}

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        /*if ("cboBanco" in fieldValues) temp.cboBanco = fieldValues.cboBanco ? "" : "Campo Requerido.";
        if ("txtTitular" in fieldValues) temp.txtTitular = fieldValues.txtTitular ? "" : "Campo Requerido.";
        if ("txtRif" in fieldValues) temp.txtRif = fieldValues.txtRif ? "" : "Campo Requerido.";
        if ("cboMoneda" in fieldValues) temp.cboMoneda = fieldValues.cboMoneda ? "" : "Campo Requerido.";
        if ("txtCuenta" in fieldValues) temp.txtCuenta = fieldValues.txtCuenta ? "" : "Campo Requerido."; */

        if ("txtRazonSocial" in fieldValues) temp.txtRazonSocial = fieldValues.txtRazonSocial ? "" : "Campo Requerido.";
        if ("txtRifProv" in fieldValues) temp.txtRifProv = fieldValues.txtRifProv ? "" : "Campo Requerido.";
        if ("txtRepresentanteLegal" in fieldValues) temp.txtRepresentanteLegal = fieldValues.txtRepresentanteLegal ? "" : "Campo Requerido.";
        if ("txtTelefono" in fieldValues) temp.txtTelefono = fieldValues.txtTelefono ? "" : "Campo Requerido.";
        if ("txtMovil" in fieldValues) temp.txtMovil = fieldValues.txtMovil ? "" : "Campo Requerido.";
        if ("txtPersonaContacto" in fieldValues) temp.txtPersonaContacto = fieldValues.txtPersonaContacto ? "" : "Campo Requerido.";
        if ("txtEmail" in fieldValues) temp.txtEmail = fieldValues.txtEmail ? "" : "Campo Requerido.";
        if ("txtDireccion" in fieldValues) temp.txtDireccion = fieldValues.txtDireccion ? "" : "Campo Requerido.";
        if ("cboTipoProveedor" in fieldValues) temp.cboTipoProveedor = fieldValues.cboTipoProveedor ? "" : "Campo Requerido.";

        if ("txtEmail" in fieldValues) {
            if (fieldValues.txtEmail === "") {
                setValiEmail(true)
                temp.txtEmail = "Campo Requerido.";
            } else if (!(/$^|.+@.+..+/.test(fieldValues.txtEmail))) {
                setValiEmail(true)
                temp.txtEmail = "Email no valido.";
            } else {
                setValiEmail(false)
                temp.txtEmail = "";
            }
        }

        if ("cboPctRetencion" in fieldValues) {
            if (fieldValues.cboPctRetencion === 36 && userData.IdTipoContribuyente === 1) {
                setValiPctRetencion(true)
                temp.cboPctRetencion = "Campo Requerido. Seleccione un porcetaje valido";
            } else if (fieldValues.cboPctRetencion === 0 && userData.IdTipoContribuyente === 1) {
                setValiPctRetencion(true)
                temp.cboPctRetencion = "Campo Requerido.";
            } else {
                setValiPctRetencion(false)
                temp.cboPctRetencion = "";
            }
        }
        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const validate2 = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboBanco" in fieldValues) temp.cboBanco = fieldValues.cboBanco ? "" : "Campo Requerido.";
        if ("txtTitular" in fieldValues) temp.txtTitular = fieldValues.txtTitular ? "" : "Campo Requerido.";
        if ("txtRif" in fieldValues) temp.txtRif = fieldValues.txtRif ? "" : "Campo Requerido.";
        if ("cboMoneda" in fieldValues) temp.cboMoneda = fieldValues.cboMoneda ? "" : "Campo Requerido.";
        if ("txtCuenta" in fieldValues) temp.txtCuenta = fieldValues.txtCuenta ? "" : "Campo Requerido.";

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);


    const handleSubmit = async (e) => {
        setSubmitted(true);
        e.preventDefault();

        if (validate()) {
            setButtonDisabled(true);
            values.txtContribuyente = userData.contribuyente;
            values.caso = caso;
            httpService.registrarNuevoProveedor(values)
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        swal({
                            text: res.message,
                            icon: "error",
                            timer: "3000"
                        });
                    } else {
                        //registro exitoso
                        swal({
                            text: res.message,
                            icon: "success",
                            timer: "4000"
                        });
                        values.nIdProveedor_aux = res.nIdProveedor_db;
                        setProveedorDialog(false);
                        AsociarCB();
                        httpService.getTableSelect("tabla_proveedores", userData.contribuyente, "").then(data => setProveedores(data));
                        resetForm();
                    }
                })
                .catch((err) => {
                    swal({
                        text: err.message,
                        icon: "error",
                        timer: "4000"
                    });
                    setButtonDisabled(false);
                });
        }
    };

    const handleSubmitCB = async (e) => {
        setSubmitted(true);
        //e.preventDefault();
        if (validate2()) {
            setButtonDisabled(true);
            values.txtContribuyente = userData.contribuyente;
            values.nIdUsuario_aux = userData.idUsuario;
            values.caso = casoCB;
            httpService.registrarCBancariaProv(values)
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        swal({
                            text: res.message,
                            icon: "error",
                            timer: "3000"
                        });

                    } else {
                        //registro exitoso
                        if (values.nIdProveedor_aux === 0) {
                            httpService.getTableSelect("tabla_cuentas_bancarias", userData.contribuyente, "").then(data => setCuentasBancarias(data));
                        } else {
                            httpService.getTableSelect("tabla_cuentas_bancarias", userData.contribuyente, values.nIdProveedor_aux).then(data => setCuentasBancarias(data));
                        }
                        swal({
                            text: res.message,
                            icon: "success",
                            timer: "4000"
                        });
                        setProveedorCbDialog(false);
                    }
                })
                .catch((err) => {
                    swal({
                        text: err.message,
                        icon: "error",
                        timer: "3000"
                    });
                    setButtonDisabled(false);
                });
        }
    };

    const limpiarCB = async () => {
        values.txtContribuyente = userData.contribuyente;
        values.nIdUsuario_aux = userData.idUsuario;

        httpService.limpiarCB(values)
            .then((res) => {
                if (res.error) {
					showError(res.message);
                } else {
                    //registro exitoso
                    resetForm();
                    httpService.getTableSelect("tabla_cuentas_bancarias", userData.contribuyente, "").then(data => setCuentasBancarias(data));
                }
            })
            .catch((err) => {
				showError(err.message);
                setButtonDisabled(false);
            });
    };

    const AsociarCB = async () => {
        values.txtContribuyente = userData.contribuyente;
        values.nIdUsuario_aux = userData.idUsuario;
        httpService.AsociarCB(values)
            .then((res) => {
                if (res.error) {
					console.log("ERROR cuentas bancarias Asociadas");
                } else {
                    //registro exitoso
                    console.log("Ok cuentas bancarias Asociadas");
                }
            })
            .catch((err) => {
                setButtonDisabled(false);
            });
    };

    const openNew = () => {
        limpiarCB();
        setCaso(0);
        setValues(emptyProvee);
        setValues(emptyCB);
        setSubmitted(false);
        setProveedorDialog(true);
        if (userData.IdTipoContribuyente === 1) {
            setPctRetenidoDisabled(false);
        } else {
            setPctRetenidoDisabled(true);
        }
    }

    const openNewCB = () => {
        setCasoCB(0);
        setLabelBoton("Agregar")
        values.cboBanco = '';
        values.txtTitular = '';
        values.txtRif = '';
        values.cboMoneda = 0;
        values.txtCuenta = '';
        values.txtObservacion = '';
        values.idCb = '';
        //values.nIdProveedor_aux = 0;
        values.nIdUsuario_aux = 0;
        setSubmitted(false);
        setProveedorCbDialog(true);
    }

    const editProveedor = (proveedor) => {
        setCaso(1);
        setValues(proveedor);
        httpService.getTableSelect("tabla_cuentas_bancarias", userData.contribuyente, proveedor.id).then(data => setCuentasBancarias(data));
        setProveedorDialog(true);
    }

    const editCB = (proveedor) => {
        setCasoCB(1);
        setLabelBoton("Actualizar")
        setValues(proveedor);
        setProveedorCbDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProveedorDialog(false);
    }

    const hideDialogCB = () => {
        setSubmitted(false);
        setProveedorCbDialog(false);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} disabled={acceso && acceso === "W" ? false : true} />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={acceso && acceso === "W" ? (!selectedProveedores || !selectedProveedores.length) : true} />
            </React.Fragment>
        )
    }

    const leftToolbarTemplateCB = () => {
        return (
            <React.Fragment>
                <Button label="Agregar" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={openNewCB} disabled={acceso && acceso === "W" ? false : true} />
                <Button label="Borrar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelectedCB} disabled={acceso && acceso === "W" ? (!selectedCuentasBancarias || !selectedCuentasBancarias.length) : true} />
            </React.Fragment>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Administrador de Proveedores</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const headerCB = (
        <div className="table-header">
            <h5 className="p-m-0">Cuentas Bancarias</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const ProveedoresDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmit} />
        </>
    );

    const ProveedoresCBDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialogCB} />
            <Button label={labelBoton} icon="pi pi-plus" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmitCB} />
        </>
    );

    //Datos para Cuentas Bancarias
    const BancoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Banco</span>
                {rowData.sBanco}
            </>
        );
    }


    const TitularBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Titular</span>
                {rowData.txtTitular}
            </>
        );
    }


    const MonedaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Moneda</span>
                {rowData.sMoneda}
            </>
        );
    }

    const CuentaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cuenta</span>
                {rowData.sCuenta}
            </>
        )
    }

    //Datos Para Proveedores
    const RazonSocialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Razon Social</span>
                {rowData.txtRazonSocial}
            </>
        );
    }

    const RifBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Rif/Dni</span>
                {rowData.txtRifProv}
            </>
        );
    }

    const PContactoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Persona Contacto</span>
                {rowData.txtPersonaContacto}
            </>
        );
    }

    const TelefonoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Telefono</span>
                {rowData.txtTelefono}
            </>
        );
    }

    const EmailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.txtEmail}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-mr-2" onClick={() => editProveedor(rowData)} disabled={acceso && acceso === "W" ? false : true} />
            </div>
        );
    }

    const actionBodyTemplateCB = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-mr-2" onClick={() => editCB(rowData)} disabled={acceso && acceso === "W" ? false : true} />
            </div>
        );
    }

    const [deleteProveedoresDialog, setDeleteProveedoresDialog] = useState(false);
    const [deleteCBDialog, setDeleteCBDialog] = useState(false);

    const [selectedProveedores, setSelectedProveedores] = useState(null);
    const [selectedCuentasBancarias, setSelectedCuentasBancarias] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);



    const hideDeleteProveedoresDialog = () => {
        setDeleteProveedoresDialog(false);
    }

    const hideDeleteCBDialog = () => {
        setDeleteCBDialog(false);
    }


    const confirmDeleteSelected = () => {
        setDeleteProveedoresDialog(true);
    }

    const confirmDeleteSelectedCB = () => {
        setDeleteCBDialog(true);
    }

    const deleteSelectedProveedores = async (e) => {

        e.preventDefault();
        httpService.deleteProveedores(selectedProveedores)
            .then((res) => {
                if (res.error) {
                    showError(res.message);
                } else {
                    //Eliminacion exitosa
					showSuccess(res.message);
                    setDeleteProveedoresDialog(false);
                    setSelectedProveedores(null);
                    httpService.getTableSelect("tabla_proveedores", userData.contribuyente, "").then(data => setProveedores(data));
                }
            })
            .catch((err) => {
				console.error("Error: ", err);
				// showError(err);
            });
    }

    const deleteSelectedCB = async (e) => {
        e.preventDefault();
        httpService.deleteCB(selectedCuentasBancarias)
            .then((res) => {
                if (res.error) {
                    showError(res.message)
                } else {
                    //Eliminacion exitosa
					showSuccess(res.message)
                    setDeleteCBDialog(false);
                    if (selectedCuentasBancarias[0]["nIdProveedor_aux"] === 0) {
                        httpService.getTableSelect("tabla_cuentas_bancarias", userData.contribuyente, "").then(data => setCuentasBancarias(data));
                    } else {
                        httpService.getTableSelect("tabla_cuentas_bancarias", userData.contribuyente, selectedCuentasBancarias[0]["nIdProveedor_aux"]).then(data => setCuentasBancarias(data));
                    }
                    setSelectedCuentasBancarias(null);
                }
            })
            .catch((err) => {
				showError(err);
            });


    };

    const deleteProveedoresDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProveedoresDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProveedores} />
        </>
    );

    const deleteCBDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCBDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCB} />
        </>
    );

    const BancosOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div> <img alt={option.title} src={option.flag} width="15px" /> {option.title}</div>
            </div>
        );
    }

    const selectedBancoTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div> <img alt={option.title} src={option.flag} width="15px" /> {option.title}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    return (
		<>
		<Toast ref={myToast} />
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <DataTable ref={dt} value={proveedores} selection={selectedProveedores} onSelectionChange={(e) => setSelectedProveedores(e.value)}
                        dataKey="id" paginator rows={10} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} proveedores"
                        globalFilter={globalFilter} emptyMessage="No existen Proveedores." header={header} >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="txtRazonSocial" header="Razon Social" sortable body={RazonSocialBodyTemplate}></Column>
                        <Column field="txtRifProv" sortable header="Rif/Dni" body={RifBodyTemplate} ></Column>
                        <Column field="txtPersonaContacto" header="Persona Contacto" sortable body={PContactoBodyTemplate}></Column>
                        <Column field="txtTelefono" header="Telefono" sortable body={TelefonoBodyTemplate}></Column>
                        <Column field="txtEmail" header="Email" sortable body={EmailBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={ProveedorDialog} maximizable modal style={{ width: '50vw' }} header="Detalles del Proveedor" className="p-fluid" footer={ProveedoresDialogFooter} onHide={hideDialog}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        <TabView className="tabview-custom">
                            <TabPanel header="Datos Básicos" leftIcon="pi pi-user">
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="cboTipoProveedor">Tipo de Proveedor</label>
                                        <Dropdown id="cboTipoProveedor" name="cboTipoProveedor" value={values.cboTipoProveedor} onChange={handleInputChange} optionValue="id" optionLabel="title" options={tipoProveedorOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboTipoProveedor })}></Dropdown>
                                        {submitted && !values.cboTipoProveedor && <small className="p-invalid">{errors.cboTipoProveedor}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtRazonSocial">Razón Social</label>
                                        <InputText name="txtRazonSocial" value={values.txtRazonSocial} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtRazonSocial })} />
                                        {submitted && !values.txtRazonSocial && <small className="p-invalid">{errors.txtRazonSocial}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtRifProv">RIF / DNI</label>
                                        <InputText name="txtRifProv" value={values.txtRifProv} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtRifProv })} />
                                        {submitted && !values.txtRifProv && <small className="p-invalid">{errors.txtRifProv}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtRepresentanteLegal">Representante</label>
                                        <InputText name="txtRepresentanteLegal" value={values.txtRepresentanteLegal} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtRepresentanteLegal })} />
                                        {submitted && !values.txtRepresentanteLegal && <small className="p-invalid">{errors.txtRepresentanteLegal}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtPersonaContacto">Persona Contacto</label>
                                        <InputText name="txtPersonaContacto" value={values.txtPersonaContacto} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtPersonaContacto })} />
                                        {submitted && !values.txtPersonaContacto && <small className="p-invalid">{errors.txtPersonaContacto}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtTelefono">Teléfono</label>
                                        <InputText name="txtTelefono" value={values.txtTelefono} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtTelefono })} />
                                        {submitted && !values.txtTelefono && <small className="p-invalid">{errors.txtTelefono}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtMovil">Móvil</label>
                                        <InputText name="txtMovil" value={values.txtMovil} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtMovil })} />
                                        {submitted && !values.txtMovil && <small className="p-invalid">{errors.txtMovil}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtEmail">Email</label>
                                        <InputText name="txtEmail" value={values.txtEmail} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && valiEmail })} />
                                        {submitted && valiEmail && <small className="p-invalid">{errors.txtEmail}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtWebSite">Pct de Retención</label>
                                        <Dropdown disabled={pctRetenidoDisabled} id="cboPctRetencion" name="cboPctRetencion" value={values.cboPctRetencion} onChange={handleInputChange} optionValue="id" optionLabel="title" options={pctRetencionOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && valiPctRetencion })}></Dropdown>
                                        {submitted && valiPctRetencion && <small className="p-invalid">{errors.cboPctRetencion}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtWebSite">Web Site</label>
                                        <InputText name="txtWebSite" value={values.txtWebSite} onChange={handleInputChange}  />
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtDireccion">Dirección</label>
                                        <InputTextarea name="txtDireccion" value={values.txtDireccion} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtDireccion })} />
                                        {submitted && !values.txtDireccion && <small className="p-invalid">{errors.txtDireccion}</small>}
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Cuentas Bancarias" leftIcon="pi pi-dollar">
                                <div className="p-grid crud-demo">
                                    <div className="p-col-12">
                                        <div className="card">
                                            {/* <Toast ref={toast} /> */}
                                            <Toolbar className="p-mb-4" left={leftToolbarTemplateCB} ></Toolbar>
                                            <DataTable ref={dt} value={cuentasBancarias} selection={selectedCuentasBancarias} onSelectionChange={(e) => setSelectedCuentasBancarias(e.value)}
                                                dataKey="idCb" paginator rows={10} className="datatable-responsive"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Cuentas Bancarias"
                                                globalFilter={globalFilter} emptyMessage="No existen Cuentas Bancarias para este proveedor." header={headerCB} >
                                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                                <Column field="sBanco" header="Banco" sortable body={BancoBodyTemplate}></Column>
                                                <Column field="txtTitular" header="Titular" sortable body={TitularBodyTemplate}></Column>
                                                <Column field="sMoneda" header="Moneda" sortable body={MonedaBodyTemplate}></Column>
                                                <Column field="sCuenta" header="Cuenta" sortable body={CuentaBodyTemplate}></Column>
                                                <Column body={actionBodyTemplateCB}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Notas" leftIcon="pi pi-comment">
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtObservacionProv">Observación</label>
                                        <InputTextarea name="txtObservacionProv" value={values.txtObservacionProv} onChange={handleInputChange}  />
                                    </div>
                                </div>
                            </TabPanel>
                        </TabView>
                    </Dialog>

                    <Dialog visible={deleteProveedoresDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProveedoresDialogFooter} onHide={hideDeleteProveedoresDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Esta Seguro de eliminar el proveedor seleccionado?</span>}
                        </div>
                    </Dialog>

                    {/* Modal Agregar Cuentas Bancarias */}
                    <Dialog visible={ProveedorCbDialog} style={{ width: '550px' }} header="Detalles de la Cuenta Bancaria" modal className="p-fluid" footer={ProveedoresCBDialogFooter} onHide={hideDialogCB}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cboBanco">Banco</label>
                                <Dropdown id="cboBanco" name="cboBanco" value={values.cboBanco} onChange={handleInputChange} optionValue="id" optionLabel="title" options={BancoOptions} filter showClear filterBy="title" placeholder="Selecciona uno" itemTemplate={BancosOptionTemplate} valueTemplate={selectedBancoTemplate} required className={classNames({ 'p-invalid': submitted && !values.cboBanco })}></Dropdown>
                                {submitted && !values.cboBanco && <small className="p-invalid">{errors.cboBanco}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtTitular">Titular</label>
                                <InputText name="txtTitular" value={values.txtTitular} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtTitular })} />
                                {submitted && !values.txtTitular && <small className="p-invalid">{errors.txtTitular}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtRif">DNI / RIF</label>
                                <InputText name="txtRif" value={values.txtRif} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtRif })} />
                                {submitted && !values.txtRif && <small className="p-invalid">{errors.txtRif}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cboMoneda">Moneda</label>
                                <Dropdown id="cboMoneda" name="cboMoneda" value={values.cboMoneda} onChange={handleInputChange} optionValue="id" optionLabel="title" options={MonedaOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboMoneda })}></Dropdown>
                                {submitted && !values.cboMoneda && <small className="p-invalid">{errors.cboMoneda}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="txtCuenta">Número Cuenta</label>
                                <InputText name="txtCuenta" value={values.txtCuenta} onChange={handleInputChange}  required className={classNames({ 'p-invalid': submitted && !values.txtCuenta })} />
                                {submitted && !values.txtCuenta && <small className="p-invalid">{errors.txtCuenta}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtObservacion">Observación</label>
                                <InputTextarea name="txtObservacion" value={values.txtObservacion} onChange={handleInputChange}  />
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCBDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCBDialogFooter} onHide={hideDeleteCBDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Esta Seguro de Eliminar la cuenta(s) seleccionada?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
            <div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
		</>
    );
}
