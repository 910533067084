import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData } from '../../service/AuthHelperMethods';
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {Tooltip} from 'primereact/tooltip';
import jsPDF from 'jspdf';
import ShowLoading from "../../components/ShowLoading/ShowLoading";
import { formatExcel } from '../../service/utils';

const initialFValues = {
    txtFechaDesde: '',
    txtFechaHasta: '',
    txtContribuyente: '',
    id: ''
};
export const LibroDeCompra = () => {

    const toast = useRef(null);
    const [compras, setCompras] = useState([]);
    const httpService = new HttpService();
    const userData = getUserData() || null;
    const [submitted, /* setSubmitted */] = useState(false);
    const [fdesde, setFDesde] = useState('_/_/_');
    const [fhasta, setFHasta] = useState('_/_/_');
	const [loadingDialog, setLoadingDialog] = useState(false);

    let razon_social_aux = userData.razon_social;
    let direccion_aux = userData.direccion;
    let rif_aux = userData.rif;

    useEffect(() => {
        
    }, []);

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboProducto" in fieldValues) temp.cboProducto = fieldValues.cboProducto ? "" : "Campo Requerido.";
        if ("cboUoM" in fieldValues) temp.cboUoM = fieldValues.cboUoM ? "" : "Campo Requerido.";

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

    const cols = [
        { field: 'txtFechaCompra', header: 'Fech. Compra' },
        { field: 'txtRif', header: 'Rif' },
        { field: 'txtRazonSocial', header: 'Razon Social' },
		{ field: 'txtNroDoc', header: 'Nro Factura' },
        { field : 'txtNroNotaCredito', header : 'N° Nota Crdto.'},
		{ field : 'txtNroNotaDebito', header : 'N° Nota Debito.'},
		{ field : 'txtNroDocNotaCD', header : 'N° Factura Afectada'},
		{ field: 'txtNroControl', header: 'Nro Control' },
		{ field: 'txtTotalConImp', header: 'Total c/Alicuota' },
		{ field: 'txtSinDeCret', header: 'Sin Der c/Fiscal' },
		{ field: 'txtBaseImponible', header: 'Base Impo.' },
		{ field: 'txtAlicuota', header: 'Alicuota' },
		{ field: 'txtImpuesto', header: 'Impuesto' },
        { field: 'sComprobanteRetencion', header: 'Comprob Retencion' },
        { field: 'dFechaRetencion', header: 'Fecha de Retencion' }
    ];

    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
        
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    }

    const exportPdf = () => {

        const doc = new jsPDF('landscape');
		// doc.autoTable({ html: '#my-table' })
		console.log(userData);
		doc.setFontSize(12);
		doc.setFont("helvetica", "bold", );
		doc.text(userData.razon_social, 15, 20);
		doc.setFont("helvetica", "normal");
		doc.text(userData.direccion, 15, 25);
		doc.text("RIF: "+ userData.rif, 15, 30);
		doc.setFont("helvetica", "bold");
		doc.text("LIBRO DE  COMPRAS", 150, 40, null, null, "center");
		doc.text(`DEL ${fdesde} AL ${fhasta}`, 150, 45, null, null, "center");

        var totalConImp = 0;
        var totalSinDeCret = 0;
        var totalBaseImponible = 0;
        var totalImpuesto = 0;

        for (let mont of compras) {
            totalConImp += parseFloat(mont.txtTotalConImp);
        }
        
        for (let mont of compras) {
            totalSinDeCret += parseFloat(mont.txtSinDeCret);
        }

        for (let mont of compras) {
            totalBaseImponible += parseFloat(mont.txtBaseImponible);
        }

        for (let mont of compras) {
            totalImpuesto += parseFloat(mont.txtImpuesto);
        }

        //var total = compras.reduce((sum, el) => sum + el.txtTotalConImp, 0);

        var body = [...compras.map(el => [el.txtFechaCompra, el.txtRif, el.txtRazonSocial, el.txtNroDoc,
                el.txtNroNotaCredito, el.txtNroNotaDebito, el.txtNroDocNotaCD, 
                el.txtNroControl, formatCurrency(el.txtTotalConImp), 
                formatCurrency(el.txtSinDeCret),
                formatCurrency(el.txtBaseImponible), el.txtAlicuota, formatCurrency(el.txtImpuesto),
                el.sComprobanteRetencion, el.dFechaRetencion]), 
        [{content: 
            `\n TOTAL CON ALICUOTA = ${formatCurrency(totalConImp)} \n` +
            `\n TOTAL SIN DERECHO A CREDITO FISCAL = ${formatCurrency(totalSinDeCret)} \n` +
            `\n TOTAL BASE IMPONIBLE = ${formatCurrency(totalBaseImponible)} \n` +
            `\n TOTAL ALICUOTA = ${formatCurrency(totalImpuesto)}`, colSpan: 11, 
            styles: { fontStyle: 'bold', halign: 'right' }
        }]]
		
		doc.autoTable({
			margin: { top: 50 }, 
			columns: exportColumns, 
			body: body,
            styles: {overflow: 'linebreak', fontSize: 9}
		});
		doc.save('libro_compras.pdf');
    }
    
    const exportExcel = () => {
        const data = formatExcel(compras, cols);
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'compras');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="p-mr-2" data-pr-tooltip="CSV" />
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />
                <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" />
            </React.Fragment>
        )
    }

    const FechaCompraBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtFechaCompra}
            </>
        );
    }

    const RifBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtRif}
            </>
        );
    }

    const RazonSocialBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtRazonSocial}
            </>
        );
    }

    const NroDocBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtNroDoc}
            </>
        );
    }

    const NotaDebitoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtNroNotaDebito}
            </>
        );
    }

    const NotaCreditoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtNroNotaCredito}
            </>
        );
    }

    const FacturaAfectadaBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtNroDocNotaCD}
            </>
        );
    }

    const NroControlBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtNroControl}
            </>
        );
    }

    const TotalConImpBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtTotalConImp)}`;
    }

    const SinDeCretBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtSinDeCret)}`;
    }

    const BaseImponibleBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtBaseImponible)}`;
    }

    const AlicuotaBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtAlicuota}
            </>
        );
    }

    const ImpuestoBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtImpuesto)}`;
    }

    const ComprobRetencionBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sComprobanteRetencion}
            </>
        );
    }

    const FechaRetencion = (rowData) => {
        return (
            <>
                {rowData.dFechaRetencion}
            </>
        );
    }

    const dt = useRef(null);

    const GenerarLibroCompra = () => {
		setLoadingDialog(true);
        console.log(compras)
        var fd = new Date(values.txtFechaDesde);
        setFDesde(`${(fd.getDate())}`.padStart(2,'0') + "/"+ `${(fd.getMonth()+1)}`.padStart(2,'0')+ "/" + fd.getFullYear());
        
        var fh = new Date(values.txtFechaHasta);
        setFHasta(`${(fh.getDate())}`.padStart(2,'0') + "/"+ `${(fh.getMonth()+1)}`.padStart(2,'0')+ "/" + fh.getFullYear());

        console.log(values);
        httpService.getTableSelect("libro_de_compras", userData.contribuyente, values.txtFechaDesde, values.txtFechaHasta)
		.then(data => {setCompras(data); setLoadingDialog(false);})
		.catch(err => setLoadingDialog(false));
    }


    const formatCurrency = function (number) {
        var numeral = require('numeral');
        var myNumeral = numeral(number);
        return myNumeral.format('0,0.00');
    };

    const TotalConImp = () => {
        let total = 0;
        for (let mont of compras) {
            total += parseFloat(mont.txtTotalConImp);
        }
        return formatCurrency(total);
    }

    const SinDeCret = () => {
        let total = 0;
        for (let mont of compras) {
            total += parseFloat(mont.txtSinDeCret);
        }
        return formatCurrency(total);
    }

    const BaseImponible = () => {
        let total = 0;
        for (let mont of compras) {
            total += parseFloat(mont.txtBaseImponible);
        }
        return formatCurrency(total);
    }

    const Impuesto = () => {
        let total = 0;
        for (let mont of compras) {
            total += parseFloat(mont.txtImpuesto);
        }
        return formatCurrency(total);
    }

    let headerGroup = 
    <ColumnGroup>
        <Row>
            <Column header={razon_social_aux.toUpperCase()} colSpan={15} headerStyle={{ textAlign: 'center' }} />
        </Row>
        <Row>
            <Column header={direccion_aux.toUpperCase()} colSpan={15} headerStyle={{ textAlign: 'center' }}/>
        </Row>
        <Row>
            <Column header={'RIF: ' + rif_aux.toUpperCase()} colSpan={15} headerStyle={{ textAlign: 'center' }}/>
        </Row>
        <Row>
            <Column header={'COMPRAS DEL: ' + fdesde + ' AL ' + fhasta} colSpan={15} headerStyle={{ textAlign: 'center' }}/>
        </Row>
        <Row>
            <Column header="Fech. Compra" field="txtFechaCompra" />
            <Column header="Rif" field="txtRif" />
            <Column header="Razon Social" field="txtRazonSocial" />
            <Column header="Nro Factura" field="txtNroDoc" />
            <Column header="N° Nota Crdto." field="txtNroNotaCredito" />
            <Column header="N° Nota Debito." field="txtNroNotaDebito" />
            <Column header="N° Factura Afectada" field="txtNroDocNotaCD" />
            <Column header="Nro Control" field="txtNroControl" />
            <Column header="Total c/Alicuota" field="txtTotalConImp" />
            <Column header="Sin Der c/Fiscal" field="txtSinDeCret" />
            <Column header="Base Impo." field="txtBaseImponible" />
            <Column header="Alicuota" field="txtAlicuota" />
            <Column header="Impuesto" field="txtImpuesto" />
            <Column header="Comprob Retencion" field="sComprobanteRetencion" />
            <Column header="Fecha de Retencion" field="dFechaRetencion" />
        </Row>
    </ColumnGroup>;

    let footerGroup = <ColumnGroup>
    <Row>
        <Column footer="TOTAL CON ALICUOTA:" colSpan={14} footerStyle={{ textAlign: 'right' }} />
        <Column footer={TotalConImp} />
    </Row>
    <Row>
        <Column footer="TOTAL SIN DERECHO A CREDITO FISCAL:" colSpan={14} footerStyle={{ textAlign: 'right' }} />
        <Column footer={SinDeCret} />
    </Row>
    <Row>
        <Column footer="TOTAL BASE IMPONIBLE:" colSpan={14} footerStyle={{ textAlign: 'right' }} />
        <Column footer={BaseImponible} />
    </Row>
    <Row>
        <Column footer="TOTAL ALICUOTA:" colSpan={14} footerStyle={{ textAlign: 'right' }} />
        <Column footer={Impuesto} />
    </Row>
    </ColumnGroup>;


    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <h5>Libro de Compra</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="txtFechaDesde">Fecha desde</label>
                            <Calendar showIcon showButtonBar dateFormat="dd/m/yy" id="txtFechaDesde" name="txtFechaDesde" value={values.txtFechaDesde} onChange={handleInputChange} monthNavigator yearNavigator yearRange="2010:2030" className={classNames({ 'p-invalid': submitted && !values.txtFechaDesde })} />
                            {submitted && !values.txtFechaDesde && <small className="p-invalid">{errors.txtFechaDesde}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="txtFechaHasta">Fecha Hasta</label>
                            <Calendar showIcon showButtonBar dateFormat="dd/m/yy" id="txtFechaHasta" name="txtFechaHasta" value={values.txtFechaHasta} onChange={handleInputChange} monthNavigator yearNavigator yearRange="2010:2030" className={classNames({ 'p-invalid': submitted && !values.txtFechaHasta })} />
                            {submitted && !values.txtFechaHasta && <small className="p-invalid">{errors.txtFechaHasta}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <React.Fragment>
                                <Button label="Generar Libro de Compra" icon="pi pi-add" className="p-button-info p-mr-2" onClick={GenerarLibroCompra} />
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <Tooltip target=".export-buttons>button" position="bottom" />
                    <DataTable ref={dt} value={compras} dataKey="id" headerColumnGroup={headerGroup} footerColumnGroup={footerGroup} >
                        <Column field="txtFechaCompra" body={FechaCompraBodyTemplate}></Column>
                        <Column field="txtRif" header="Rif" body={RifBodyTemplate}></Column>
                        <Column field="txtRazonSocial" header="Razon Social" body={RazonSocialBodyTemplate}></Column>
                        <Column field="txtNroDoc" header="Nro Factura" body={NroDocBodyTemplate}></Column>
                        <Column field="txtNroNotaCredito" header="N° Nota Crdto." body={NotaCreditoBodyTemplate}></Column>
                        <Column field="txtNroNotaDebito" header="N° Nota Debito." body={NotaDebitoBodyTemplate}></Column>
                        <Column field="txtNroDocNotaCD" header="N° Factura Afectada" body={FacturaAfectadaBodyTemplate}></Column>
                        <Column field="txtNroControl" header="Nro Control" body={NroControlBodyTemplate}></Column>
                        <Column field="txtTotalConImp" header="Total c/Alicuota" body={TotalConImpBodyTemplate}></Column>
                        <Column field="txtSinDeCret" header="Sin Der c/Fiscal" body={SinDeCretBodyTemplate}></Column>
                        <Column field="txtBaseImponible" header="Base Impo." body={BaseImponibleBodyTemplate}></Column>
                        <Column field="txtAlicuota" header="Alicuota" body={AlicuotaBodyTemplate}></Column>
                        <Column field="txtImpuesto" header="Impuesto" body={ImpuestoBodyTemplate}></Column>
                        <Column field="sComprobanteRetencion" header="Comprob. Retencion" body={ComprobRetencionBodyTemplate}></Column>
                        <Column field="dFechaRetencion" header="Fecha de Retencion" body={FechaRetencion}></Column>
                    </DataTable>
                </div>
            </div>
            <div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
};
