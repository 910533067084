import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import Constantes from "../../../Constantes";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "../AsientosContables.styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/es"
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { getUserData } from "../../../service/AuthHelperMethods";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: 900, // Set your custom width here
    mt: 3,
    p: 5,
    [theme.breakpoints.down("sm")]: {
      width: 777, // Set a different width for small screens if necessary
    },
  },
}));

export default function UpdateAsientoContable({
  asientoData,
  isOpenDialogUpdate,
  handleCloseDialogUpdate,
}) {
  const [date, setDate] = useState("");
  const [concepto, setConcepto] = useState("");
  const [cuentas, setCuentas] = useState();
  const [userData] = useState(getUserData() || null);
  const idUsuarioUpdate = userData.idUsuario;

  const classes = useStyles();
  
  useEffect(() => {
    const cuentasContablesCall = () => {
      axios
        .get(
          `${Constantes.RUTA_API}/get_options_select.php?tipoDato=lista_cuentas_contables&IdContribuyente=${asientoData.nIdContribuyente}`
        )
        .then((res) => {
          setCuentas(res.data);
        })
        .catch((error) => console.log(error));
    };
    cuentasContablesCall();
  }, [asientoData]);

  useEffect(() => {
    setConcepto(asientoData?.sConcepto);
  }, [asientoData]);

  const handleChangeDate = (e) => {
    const newDate = dayjs(e);
    const formattedDate = newDate ? newDate.format("YYYY-MM-DD") : "";
    setDate(formattedDate);
  };

  const handleClose = () => {
    handleCloseDialogUpdate(false);
  };

  const formatNumber = (value) => {
    // Remove non-numeric characters from the input value
    const numericValue = value.toString().replace(/\D/g, "");
    // Convert the numeric value to a number and divide by 100 to move the decimal point two places to the left
    const numberValue = Number(numericValue) / 100;
    // Format the number value with a dot as a thousand separator and a comma as a decimal separator
    return numberValue.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatDate = (dateStr) => {
    let [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year}`;
  };

  const notifySuccess = () => {
    Swal.fire({
      title: "Asiento Modificado!",
      text: "Modificación de Asiento Éxitoso",
      icon: "success",
      confirmButtonText: "Cerrar",
      customClass: {
        container: "my-swal",
      },
    });
  };

  const notifyServerError = () => {
    Swal.fire({
      title: "Error!",
      text: "Ha ocurrido un error, intente nuevamente",
      icon: "error",
      confirmButtonText: "Cerrar",
      customClass: {
        container: "my-swal",
      },
    });
  };

  const handleSubmit = () => {
    axios
      .put(
        `${Constantes.RUTA_API}/admin_asientos_contables.php`,
        {
          nIdAsientoCont: asientoData?.nIdAsientoCont,
          nIdContribuyente: asientoData?.nIdContribuyente,
          dtFechaAsiento: !date ? asientoData?.dtFechaAsiento : date,
          nNroAsiento: asientoData?.nNroAsiento,
          sConcepto: !concepto ? asientoData?.sConcepto : concepto,
          nIdUsuarioUpdate: idUsuarioUpdate,
          Detalle: asientoData?.Detalle?.map((d) => {
            const cuentaID = cuentas?.find((acc) => acc.title === d.sCodigoCta);
            if (cuentaID) {
              return { ...d, sCodigoCta: cuentaID.id };
            } else {
              return d;
            }
          }),
        }
      )
      .then((res) => {
        notifySuccess();
        setTimeout(() => {
          handleClose();
        }, 2500);
      })
      .catch((err) => {
        notifyServerError();
        console.log(err);
      });
  };

  return (
    <Box>
      <Dialog
        maxWidth={false}
        open={isOpenDialogUpdate}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid #0003",
            backgroundColor: `#0288D1`,
            color: "white",
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: "18px" }}>
            Modificar Asiento Contable: Nº{asientoData.nNroAsiento}
          </Typography>
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText
            sx={{
              fontSize: "1.2rem",
              color: `black`,
              width: "100%",
            }}
          >
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs="auto">
                {/* Column 1 */}
                <Typography sx={styles.gridTextTitle}>
                  Número de Asiento:
                </Typography>
                <Typography sx={styles.gridTextTitle}>
                  Monto Total Asiento:
                </Typography>
                <Typography sx={styles.gridTextTitle}>
                  Fecha de Asiento:
                </Typography>
                <Typography sx={styles.gridTextTitle}>Concepto:</Typography>
              </Grid>
              <Grid item xs="auto">
                {/* Column 2 */}
                <Typography sx={styles.gridTextContent}>
                  {asientoData.nNroAsiento}
                </Typography>
                <Typography sx={styles.gridTextContent}>
                  {asientoData.deMonto
                    ? formatNumber(asientoData.deMonto)
                    : "0,00"}
                </Typography>
                <Typography sx={styles.gridTextContent}>
                  {asientoData.dtFechaAsiento !== undefined && formatDate(asientoData.dtFechaAsiento)}
                </Typography>
                <Typography sx={styles.gridTextContent}>
                  {asientoData.sConcepto}
                </Typography>
              </Grid>

              <Grid item xs={0.5}></Grid>
            </Grid>
            <Typography sx={{ mt: 2, mb: 1, fontWeight: 500 }}>
              Datos a Modificar
            </Typography>
            <Box sx={{ width: "100%", display: "flex", gap: 3 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                <DatePicker
                  sx={{ width: "50%" }}
                  label="Nueva Fecha"
                  name="FechaAsiento"
                  onChange={(e) => handleChangeDate(e)}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
              <TextField
                label="Nuevo Concepto"
                sx={{ width: "50%" }}
                value={concepto}
                onChange={(e) => setConcepto(e.target.value)}
              />
            </Box>
          </DialogContentText>
          <Box
            sx={{
              width: "100%",
              color: "white",
              borderTop: "1px solid #D9D9D9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          />
          <DialogContentText sx={{ color: `black` }}>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 500, mb: 2, mt: 3 }}
            >
              Detalle Asiento Contable
            </Typography>

            <Box sx={{ mt: 2, borderRadius: "3px 3px 0px 0px" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "9vh",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#F8F9FA",
                  border: "1px solid #0003",
                  pr: 3,
                  pl: 3,
                }}
              >
                <Box sx={{ width: "40%", fontWeight: 500, fontSize: "1rem" }}>
                  Cuenta
                </Box>
                <Box sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}>
                  Debe
                </Box>
                <Box sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}>
                  Haber
                </Box>
                <Box sx={{ width: "5%" }}></Box>
              </Box>
              {asientoData &&
                asientoData.Detalle?.map((i, index) => (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        height: "8vh",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "white",
                        border: "1px solid #0003",
                        pr: 3,
                        pl: 3,
                      }}
                      key={index}
                    >
                      <Box
                        sx={{ width: "40%", fontWeight: 500, fontSize: "1rem" }}
                      >
                        {i.sCodigoCta}
                      </Box>
                      <Box
                        sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}
                      >
                        {i.cTipoRegMov === "D"
                          ? formatNumber(i.deMontoMovCont)
                          : 0}
                      </Box>
                      <Box
                        sx={{ width: "30%", fontWeight: 500, fontSize: "1rem" }}
                      >
                        {i.cTipoRegMov === "H"
                          ? formatNumber(i.deMontoMovCont)
                          : 0}
                      </Box>
                      <Box
                        sx={{ width: "5%", fontWeight: 500, fontSize: "1rem" }}
                      ></Box>
                    </Box>
                  </>
                ))}
              <Box
                sx={{
                  width: "100%",
                  height: "9vh",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#F8F9FA",
                  border: "1px solid #0003",
                  pr: 3,
                  pl: 3,
                }}
              >
                <Box sx={{ width: "40%", fontWeight: 500, fontSize: "1rem" }}>
                  Total Asiento
                </Box>
                <Box sx={{ width: "30%", fontWeight: 700, fontSize: "1rem" }}>
                  {asientoData.deMonto
                    ? formatNumber(asientoData.deMonto)
                    : "0,00"}
                </Box>
                <Box sx={{ width: "30%", fontWeight: 700, fontSize: "1rem" }}>
                  {asientoData.deMonto
                    ? formatNumber(asientoData.deMonto)
                    : "0,00"}
                </Box>
                <Box sx={{ width: "5%" }}></Box>
              </Box>
            </Box>
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button
              sx={{
                backgroundColor: "#3B82F6",
                color: "white",
                "&:hover": { backgroundColor: "#3B82F6" },
                textTransform: "none",
              }}
              onClick={handleSubmit}
            >
              Guardar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
