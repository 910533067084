const formatExcel = (data, fields) => {
    const newArray = [];
    data.forEach(val => {
        const col = {}
        fields.forEach(field => {
            col[field.header] = val[field.field]
        });
        newArray.push(col);
    });
    return newArray;
}

export {formatExcel};