import React, { useState, useEffect, useRef } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Tabs,
  Tab,
  Typography,
  Tooltip,
  MenuItem,
  FormLabel,
  Select,
  TextField,
} from "@mui/material";

import Swal from "sweetalert2";

import LoadingButton from "@mui/lab/LoadingButton";

import SaveIcon from "@mui/icons-material/Save";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import axios from "axios";

import { getUserData } from "../../service/AuthHelperMethods";

import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";

import { styles, CssTextField } from "./PlanCuentas.style";

import { useValue } from "./states/useValue";

import Constantes from "../../Constantes";

export default function PlanCuentas() {
  const toast = useRef(null);
  const [/* anchorEl */, setAnchorEl] = useState(null); // Estado para controlar el ancla del menú
  const [/* open */, setOpen] = useState(false); // Estado para controlar la apertura y cierre del Dialog
  const [openSaldos, setOpenSaldos] = useState(false); // Estado para controlar la apertura y cierre del Dialog
  const [openNewAccount, setOpenNewAccount] = useState(false); // Estado para controlar la apertura y cierre del Dialog
  const [cuenta, setCuenta] = useState("");

  const [codCuenta, setCodCuenta] = useState("");
  const [codCuentaEdit, setCodCuentaEdit] = useState(""); //CODIGO CUENTA FOR EDIT
  const [clasifCuenta, setClasifCuenta] = useState(""); //CODIGO CUENTA FOR EDIT
  const [/* nameCuentaEdit */, setNameCuentaEdit] = useState(""); //NOMBRE CUENTA FOR EDIT
  const [nameAccount, setNameAccount] = useState("");

  const [accountEdit, setAccountEdit] = useState({
    saldoDebe: "",
    saldoHaber: "",
  });

  const [saldoDebe, setSaldoDebe] = useState("");
  const [saldoHaber, setSaldoHaber] = useState("");
  const [resetState, setResetState] = useState({ debe: "", haber: "" });
  const [editAvailable, setEditAvailable] = useState(false);

  // const [isEditable, setIsEditable] = useState(false);

  // const handleEditClick = () => {
  //   setIsEditable(true);
  // };

  // const handleSaveClick = () => {
  //   // Realizar la lógica para guardar los cambios
  //   // Puedes usar axios u otra librería de tu elección
  //   // Después de guardar, puedes establecer isEditable a false si deseas que los TextField se vuelvan a deshabilitar automáticamente

  //   setIsEditable(false);
  // };

  const changeEditAvailable = () => {
    setEditAvailable(!editAvailable);
    if (editAvailable === true) {
      setSaldoDebe(resetState?.debe);
      setSaldoHaber(resetState?.haber);
    }
  };

  // STATES EDIT ACCOUNT
  const [openEdit, setOpenEdit] = useState(false);

  // STATES DELETE ACCOUNT
  const [openDelete, setOpenDelete] = useState(false);

  // STATES DELETE SALDO INICIAL
  const [openDeleteSaldo, setOpenDeleteSaldo] = useState(false);

  const userData = getUserData();

  // CUENTA CONTABLE MANEJO DE SELECTS
  const [tipo, setTipo] = useState(1);
  const [clase, setClase] = useState(1);
  const [grupo, setGrupo] = useState(1);
  const [textClase, setTextClase] = useState(null);
  const [textGrupo, setTextGrupo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [/* restartCuentas */, setRestartCuentas] = useState(false);
  const [idSelected, setIdSelected] = useState();

  const [cuentasContables, setCuentasContables] = useState();

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${Constantes.RUTA_API}/get_general_data.php?tipoDato=plan_cuentas_personal&idRef={"txtContribuyente": ${userData.contribuyente}}`
      )
      .then((response) => {
        setCuentasContables(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userData.contribuyente]);

  useEffect(() => {
    setTextClase(null);
    setLoading(true);
    axios
      .get(
        `${Constantes.RUTA_API}/get_options_select.php?tipoDato=lista_cuentas_contables_clase&tipo_ref={"tipo": ${tipo}}&IdContribuyente=${userData.contribuyente}`
      )
      .then((response) => {
        setTextClase(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userData.contribuyente, tipo]);

  useEffect(() => {
    setTextGrupo(null);
    setLoading(true);
    if (tipo) {
      axios
        .get(
          `${Constantes.RUTA_API}/get_options_select.php?tipoDato=lista_cuentas_contables_grupo&tipo_ref={"tipo": ${tipo}, "clase" : ${clase}}&IdContribuyente=${userData.contribuyente}`
        )
        .then((response) => {
          setTextGrupo(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [userData.contribuyente, tipo, clase]);

  const handleClase = (e) => {
    setClase(e.target.value);
    setGrupo(null);
  };
  const handleGrupo = (e) => {
    setGrupo(e.target.value);
  };

  const handleOptionNewAccount = (option) => {
    setOpenNewAccount(true);
  };
  const handleOptionSaldos = (idData, nombreCuenta) => {
    // setSelectedOption(option);
    setOpenSaldos(true);
    setIdSelected(idData);
    setCuenta(nombreCuenta);
    axios
      .get(
        `${Constantes.RUTA_API}/get_general_data.php?tipoDato=saldo_inicial_sw&idRef={"idCuenta": ${idData}, "idContribuyente": ${userData.contribuyente}}`
      )
      .then((response) => {
        setAccountEdit(response?.data);
        setSaldoDebe(response?.data?.debe);
        setSaldoHaber(response?.data?.haber);
        setResetState({
          debe: response?.data?.debe,
          haber: response?.data?.haber,
        });

        const { debe, haber } = response?.data;
        setAccountEdit({ debe, haber });

        setLoading(false);
      })
      .catch((error) => {
        setResetState({ debe: "", haber: "" });
        console.error(error);
      });
  };

  const handleOptionEdit = (idData, codigoCuenta, nombre, clasificacion) => {
    setOpenEdit(true);
    setIdSelected(idData);
    setCodCuenta(codigoCuenta);
    setCodCuentaEdit(codigoCuenta);
    setClasifCuenta(clasificacion);

    setNameAccount(nombre);
  };
  const handleOptionDelete = (idData) => {
    setOpenDelete(true);
    setIdSelected(idData);
  };

  const handleCuentaChange = (event) => {
    setCuenta(event.target.value);
  };
  const handleSaldoDebeChange = (event) => {
    const inputVal = event.target.value;
    const regex = /^[0-9]+(\.[0-9]*)?$/; // Expresión regular que solo permite números y un punto decimal
    if (regex.test(inputVal) || inputVal === "") {
      setSaldoDebe(inputVal);
    }
  };
  const handleSaldoHaberChange = (event) => {
    const inputVal = event.target.value;
    const regex = /^[0-9]+(\.[0-9]*)?$/; // Expresión regular que solo permite números y un punto decimal
    if (regex.test(inputVal) || inputVal === "") {
      setSaldoHaber(inputVal);
    }
  };

  // CREAR CUENTA CONTABLE

  const handleCodCuentaChange = (event) => {
    const inputVal = event.target.value;
    const regex = /^[0-9\b]+$/; // Expresión regular que solo permite números
    if (regex.test(inputVal) || inputVal === "") {
      setCodCuenta(inputVal);
    }
  };
  const handleCodCuentaEditChange = (event) => {
    const inputVal = event.target.value;
    const regex = /^[0-9\b]+$/; // Expresión regular que solo permite números
    if (regex.test(inputVal) || inputVal === "") {
      setCodCuentaEdit(inputVal);
    }
  };

  const handleNameAccountChange = (event) => {
    setNameAccount(event.target.value);
  };

  // Función para abrir el menú
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // Función para cerrar el menú
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setOpenSaldos(false);
    setOpenNewAccount(false);
    setCuenta("");
    setCodCuenta("");
    setNameAccount("");
    setSaldoDebe("");
    setSaldoHaber("");
    setOpenEdit(false);
    setOpenDelete(false);
    setIdSelected(null);
    setCodCuentaEdit("");
    setNameCuentaEdit("");
    setOpenDeleteSaldo(false);
    setEditAvailable(false);
  };

  const handleSubmit = () => {
    // Aquí puedes agregar la lógica para enviar los datos a tu servidor o a una base de datos.
    setLoading(true);
    setLoadingBtn(true);
    axios
      .post(
        `${Constantes.RUTA_API}/admin_cuentas_contables.php`,
        {
          txtTipo: tipo,
          txtClase: clase,
          txtGrupo: grupo,
          txtCodigoCta: codCuenta,
          txtNombre: nameAccount,
          idContribuyente: userData.contribuyente,
          idUsuario: userData.idUsuario,
          caso: "0",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          // toast?.current?.show({
          //   severity: "error",
          //   summary: "Error",
          //   detail: res.data.message,
          //   life: 3000,
          // });

          Swal.fire({
            title: "Error!",
            text: res.data.message,
            icon: "error",
            confirmButtonText: "Cool",
          });
        } else {
          // toast.current?.show({
          //   severity: "success",
          //   summary: "Exito",
          //   detail: res.data.message,
          //   life: 5000,
          // });

          Swal.fire(
            "Cuenta Contable Creada",
            res.data.message,
            "success"
          );
        }

        setRestartCuentas(true);
        setTimeout(() => {
          setRestartCuentas(false);
          setLoading(false);
          setLoadingBtn(false);
          handleClose();
        }, 100);
      })
      .catch((err) => {
        console.log(err);
        // toast?.current?.show({
        //   severity: "error",
        //   summary: "Error",
        //   detail: err?.message,
        //   life: 3000,
        // });
        Swal.fire({
          title: "Error!",
          text: err?.message,
          icon: "error",
          confirmButtonText: "Cool",
        });
      });
  };
  const handleSubmitSaldoInicial = () => {
    setEditAvailable(false);
    setLoading(true);
    setLoadingBtn(true);
    axios
      .post(
        `${Constantes.RUTA_API}/admin_saldos_iniciales.php`,
        {
          idCuenta: idSelected,
          idContribuyente: userData.contribuyente,
          idUsuario: userData.idUsuario,
          montoDebe: saldoDebe,
          montoHaber: saldoHaber,
          caso: "0",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          // toast?.current?.show({
          //   severity: "error",
          //   summary: "Error",
          //   detail: res.data.message,
          //   life: 3000,
          // });

          Swal.fire({
            title: "Error!",
            text: res.data.message,
            icon: "error",
            confirmButtonText: "Cool",
          });
        } else {
          // toast.current?.show({
          //   severity: "success",
          //   summary: "Exito",
          //   detail: res.data.message,
          //   life: 5000,
          // });

          Swal.fire(
            "Saldo inicial Guardado",
            res.data.message,
            "success"
          );
        }

        setTimeout(() => {
          setLoading(false);
          setLoadingBtn(false);
          setEditAvailable(false);
          handleClose();
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        // toast?.current?.show({
        //   severity: "error",
        //   summary: "Error",
        //   detail: err?.message,
        //   life: 3000,
        // });

        Swal.fire({
          title: "Error!",
          text: err?.message,
          icon: "error",
          confirmButtonText: "Cool",
        });
      });
    // handleClose();
  };
  const handleSubmitSaldoInicialEdit = () => {
    setEditAvailable(true);
    setLoading(true);
    setLoadingBtn(true);
    axios
      .post(
        `${Constantes.RUTA_API}/admin_saldos_iniciales.php`,
        {
          idCuenta: idSelected,
          idContribuyente: userData.contribuyente,
          idUsuario: userData.idUsuario,
          montoDebe: saldoDebe,
          montoHaber: saldoHaber,
          caso: "1",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          // toast?.current?.show({
          //   severity: "error",
          //   summary: "Error",
          //   detail: res.data.message,
          //   life: 3000,
          // });

          Swal.fire({
            title: "Error!",
            text: res.data.message,
            icon: "error",
            confirmButtonText: "Cool",
          });
        } else {
          
          // toast.current?.show({
          //   severity: "success",
          //   summary: "Exito",
          //   detail: res.data.message,
          //   life: 5000,
          // });

          Swal.fire(
            "Saldo Inicial Modificado",
            res.data.message,
            "success"
          );
        }

        setTimeout(() => {
          setLoading(false);
          setLoadingBtn(false);
          setEditAvailable(false);
          handleClose();
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        // toast?.current?.show({
        //   severity: "error",
        //   summary: "Error",
        //   detail: err?.message,
        //   life: 3000,
        // });

        Swal.fire({
          title: "Error!",
          text: err?.message,
          icon: "error",
          confirmButtonText: "Cool",
        });
      });
    // handleClose();
  };
  const handleSubmitSaldoInicialDelete = () => {
    setEditAvailable(false);
    setLoading(true);
    setLoadingBtn(true);
    axios
      .post(
        `${Constantes.RUTA_API}/admin_saldos_iniciales.php`,
        {
          idCuenta: idSelected,
          idContribuyente: userData.contribuyente,
          idUsuario: userData.idUsuario,
          // montoDebe: saldoDebe,
          // montoHaber: saldoHaber,
          caso: "2",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          // toast?.current?.show({
          //   severity: "error",
          //   summary: "Error",
          //   detail: res.data.message,
          //   life: 3000,
          // });

          Swal.fire({
            title: "Error!",
            text: res.data.message,
            icon: "error",
            confirmButtonText: "Cool",
          });
        } else {
          // toast.current?.show({
          //   severity: "success",
          //   summary: "Exito",
          //   detail: res.data.message,
          //   life: 5000,
          // });

          Swal.fire(
            "Saldo Inicial Eliminado",
            res.data.message,
            "success"
          );
        }

        setTimeout(() => {
          setLoading(false);
          setLoadingBtn(false);
          setEditAvailable(false);
          handleClose();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        // toast?.current?.show({
        //   severity: "error",
        //   summary: "Error",
        //   detail: err?.message,
        //   life: 3000,
        // });

        Swal.fire({
          title: "Error!",
          text: err?.message,
          icon: "error",
          confirmButtonText: "Cool",
        });
      });
    // handleClose();
  };

  const handleSubmitEdit = () => {
    // handleClose();
    setLoading(true);
    setLoadingBtn(true);
    axios
      .post(
        `${Constantes.RUTA_API}/admin_cuentas_contables.php`,
        {
          idContribuyente: userData.contribuyente,
          idUsuario: userData.idUsuario,
          txtCodigoCta: codCuenta,
          txtNombre: nameAccount,
          id: idSelected?.toString(),
          caso: "1",
          txtCodigoNuevo: codCuentaEdit,
          txtClasificacion: clasifCuenta,
          // txtNombreNuevo: nameCuentaEdit,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setRestartCuentas(true);
        // if (res.data.message === "No se puede modificar la cuenta ya que posee saldo inicial") {
        if (res.data.error) {
          // toast?.current?.show({
          //   severity: "error",
          //   summary: "Error",
          //   detail: res.data.message,
          //   life: 3000,
          // });

          Swal.fire({
            title: "Error!",
            text: res.data.message,
            icon: "error",
            confirmButtonText: "Cool",
          });
        } else {
          // toast.current?.show({
          //   severity: "success",
          //   summary: "Exito",
          //   detail: res.data.message,
          //   life: 5000,
          // });

          Swal.fire(
            "Cuenta Contable Modificada",
            res.data.message,
            "success"
          );
        }

        setTimeout(() => {
          setRestartCuentas(false);
          setLoading(false);
          setLoadingBtn(false);
          handleClose();
        }, 100);
      })
      .catch((err) => {
        console.log(err);
        // toast?.current?.show({
        //   severity: "error",
        //   summary: "Error",
        //   detail: err?.message,
        //   life: 3000,
        // });

        Swal.fire({
          title: "Error!",
          text: err?.message,
          icon: "error",
          confirmButtonText: "Cool",
        });
      });
  };
  const handleSubmitDelete = () => {
    setLoading(true);
    setLoadingBtn(true);
    axios
      .post(
        `${Constantes.RUTA_API}/admin_cuentas_contables.php`,
        {
          idContribuyente: userData.contribuyente,
          idUsuario: userData.idUsuario,
          id: idSelected?.toString(),
          caso: "2",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setRestartCuentas(true);
        // if (res.data.message === "No se puede eliminar la cuenta ya que posee movimientos") {
        if (res.data.error) {
          // toast?.current?.show({
          //   severity: "error",
          //   summary: "Error",
          //   detail: res.data.message,
          //   life: 3000,
          // });

          Swal.fire({
            title: "Error!",
            text: res.data.message,
            icon: "error",
            confirmButtonText: "Cool",
          });
        } else {
          // toast.current?.show({
          //   severity: "success",
          //   summary: "Exito",
          //   detail: res.data.message,
          //   life: 5000,
          // });

          Swal.fire(
            "Cuenta Contable Eliminada",
            res.data.message,
            "success"
          );
        }

        setTimeout(() => {
          setRestartCuentas(false);
          setLoading(false);
          setLoadingBtn(false);
          handleClose();
        }, 100);
      })
      .catch((err) => {
        console.log(err);
        // toast?.current?.show({
        //   severity: "error",
        //   summary: "Error",
        //   detail: err?.message,
        //   life: 3000,
        // });

        Swal.fire({
          title: "Error!",
          text: err?.message,
          icon: "error",
          confirmButtonText: "Cool",
        });
      });
  };


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        sx={{ "&.MuiTabPanel-root": { width: "50vw" } }}
        {...other}
      >
        {value === index && <Box sx={styles.tabPanel}>{children}</Box>}
      </Box>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: "50%",
      width: "100%",
      backgroundColor: `#2196F3`,
    },
  });

  const StyledTab = styled((props) => (
    <>
      <Tab disableRipple {...props} />{" "}
    </>
  ))(({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "#838383",
    "&.Mui-selected": {
      color: "#2196F3",
      fontWeight: 700,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#2196F3",
    },
  }));

  const CssSelect = styled(Select)({
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0007",
      borderRadius: "5px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#EDEDED",
        borderRadius: "5px",
      },
      "&:hover fieldset": {
        borderColor: "#0007",
        borderRadius: "5px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0007",
        borderRadius: "5px",
      },
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
  });

  const { value, handleValueChange } = useValue();

  return (
    <Box
      component="div"
      sx={{
        width: "100%",
      }}
    >
      <Typography variant="h3">Plan de Cuentas</Typography>
      <Typography variant="h6" sx={{ color: "#5B6B8C", marginBottom: "3%" }}>
        Configura y personaliza las cuentas contables
      </Typography>

      <Box
        component="div"
        sx={{
          display: "flex",
          width: "21%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: "#689F38",
            textTransform: "none",
            fontSize: "1rem",
            "&:hover": { backgroundColor: "#689F38" },
          }}
          onClick={handleOptionNewAccount}
        >
          Nuevo
        </Button>
      </Box>

      <Box component="div" sx={styles.container}>
        <Toast ref={toast} />
        <DataTable
          value={cuentasContables}
          paginator
          rows={10}
          dataKey="nId"
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} cuentas contables"
          emptyMessage="No existen cuentas contables."
        >
          <Column
            field="txtClasificacion"
            header="Clasificación"
            sortable
          ></Column>
          <Column field="txtCodigoCta" header="Cuenta" sortable></Column>
          <Column field="txtNombre" header="Nombre" sortable></Column>
          <Column
            field="txtNaturaleza"
            header="Naturaleza"
            sortable
            // filter
          ></Column>
          <Column
            field={(rowData) => (
              <>
                <Tooltip title="Agregar Saldo Inicial">
                  <IconButton
                    onClick={() =>
                      handleOptionSaldos(rowData.nId, rowData.txtCodigoCta)
                    }
                    sx={{
                      backgroundColor: "#689F38",
                      color: "white",
                      "&:hover": { backgroundColor: "#689F38", color: "white" },
                    }}
                  >
                    <MonetizationOnIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Editar">
                  <IconButton
                    onClick={() =>
                      handleOptionEdit(
                        rowData.nId,
                        rowData.txtCodigoCta,
                        rowData.txtNombre,
                        rowData.txtClasificacion
                      )
                    }
                    sx={{
                      backgroundColor: "#FBC02D",
                      color: "white",
                      marginLeft: "2%",
                      "&:hover": { backgroundColor: "#FBC02D", color: "white" },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Eliminar">
                  <IconButton
                    onClick={() => handleOptionDelete(rowData.nId)}
                    sx={{
                      backgroundColor: "#E28080",
                      color: "white",
                      marginLeft: "2%",
                      "&:hover": { backgroundColor: "#E28080", color: "white" },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            header="Acciones"
            sortable
          ></Column>
        </DataTable>

        <Dialog
          open={openNewAccount}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Nueva Cuenta Contable</DialogTitle>
          <Typography
            sx={{ color: "#5B6B8C", marginLeft: "5%", fontWeight: "500" }}
          >
            Para crear una nueva cuenta contable debe rellenar los siguientes
            campos:{" "}
          </Typography>
          <DialogContent>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  border: 1,
                  borderColor: "divider",
                  width: "100%",
                  borderRadius: "3px",
                }}
              >
                <StyledTabs
                  value={value}
                  onChange={handleValueChange}
                  aria-label="scrollable auto tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <StyledTab
                    label="Activo"
                    {...a11yProps(0)}
                    sx={styles.tab}
                    onClick={() => setTipo(1)}
                  />
                  <StyledTab
                    label="Pasivo"
                    {...a11yProps(1)}
                    sx={styles.tab}
                    onClick={() => setTipo(2)}
                  />
                  <StyledTab
                    label="Patrimonio"
                    {...a11yProps(2)}
                    sx={styles.tab}
                    onClick={() => setTipo(3)}
                  />
                  <StyledTab
                    label="Ingresos"
                    {...a11yProps(3)}
                    sx={styles.tab}
                    onClick={() => setTipo(4)}
                  />
                  <StyledTab
                    label="Costos de Operaciones Continuas"
                    {...a11yProps(4)}
                    sx={styles.tab}
                    onClick={() => setTipo(5)}
                  />
                  <StyledTab
                    label="Gastos"
                    {...a11yProps(5)}
                    sx={styles.tab}
                    onClick={() => setTipo(6)}
                  />
                </StyledTabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "5%",
                  }}
                >
                  <Box sx={styles.field}>
                    <FormLabel sx={styles.label}> Clase </FormLabel>
                    <CssSelect
                      fullWidth
                      id="clase"
                      type="text"
                      name="clase"
                      sx={{ width: "100%" }}
                      value={clase}
                      onChange={handleClase}
                      disabled={loading}
                    >
                      {textClase?.map((item, index) => (
                        <MenuItem key={`index-${index}`} value={item.id}>
                          <Typography>{item.title}</Typography>
                        </MenuItem>
                      ))}
                    </CssSelect>
                  </Box>
                  <Box sx={styles.field}>
                    <FormLabel sx={styles.label}> Tipo </FormLabel>
                    <CssSelect
                      fullWidth
                      id="model-year"
                      type="text"
                      name="year"
                      sx={{ width: "100%" }}
                      value={grupo}
                      onChange={handleGrupo}
                      disabled={clase === "" || loading}
                    >
                      {textGrupo?.map((item, index) => (
                        <MenuItem key={`index-${index}`} value={item.id}>
                          <Typography>{item.title}</Typography>
                        </MenuItem>
                      ))}
                    </CssSelect>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "5%",
                  }}
                >
                  <Box sx={styles.field}>
                    <FormLabel sx={styles.label}> Clase </FormLabel>
                    <CssSelect
                      fullWidth
                      id="model-year"
                      type="text"
                      name="year"
                      sx={{ width: "100%" }}
                      value={clase}
                      onChange={handleClase}
                      disabled={loading}
                    >
                      {textClase?.map((item, index) => (
                        <MenuItem key={`index-${index}`} value={item.id}>
                          <Typography>{item.title}</Typography>
                        </MenuItem>
                      ))}
                    </CssSelect>
                  </Box>
                  <Box sx={styles.field}>
                    <FormLabel sx={styles.label}> Tipo </FormLabel>
                    <CssSelect
                      fullWidth
                      id="model-year"
                      type="text"
                      name="year"
                      sx={{ width: "100%" }}
                      value={grupo}
                      onChange={handleGrupo}
                      disabled={clase === "" || loading}
                    >
                      {textGrupo?.map((item, index) => (
                        <MenuItem key={`index-${index}`} value={item.id}>
                          <Typography>{item.title}</Typography>
                        </MenuItem>
                      ))}
                    </CssSelect>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "5%",
                  }}
                >
                  <Box sx={styles.field}>
                    <FormLabel sx={styles.label}> Clase </FormLabel>
                    <Select
                      fullWidth
                      id="model-year"
                      type="text"
                      name="year"
                      sx={{ width: "100%" }}
                      value={clase}
                      onChange={handleClase}
                      disabled={loading}
                    >
                      {textClase?.map((item, index) => (
                        <MenuItem key={`index-${index}`} value={item.id}>
                          <Typography>{item.title}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box sx={styles.field}>
                    <FormLabel sx={styles.label}> Tipo </FormLabel>
                    <Select
                      fullWidth
                      id="model-year"
                      type="text"
                      name="year"
                      sx={{ width: "100%" }}
                      value={grupo}
                      onChange={handleGrupo}
                      disabled={clase === "" || loading}
                    >
                      {textGrupo?.map((item, index) => (
                        <MenuItem key={`index-${index}`} value={item.id}>
                          <Typography>{item.title}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "5%",
                  }}
                >
                  <Box sx={styles.field}>
                    <FormLabel sx={styles.label}> Clase </FormLabel>
                    <Select
                      fullWidth
                      id="model-year"
                      type="text"
                      name="year"
                      sx={{ width: "100%" }}
                      value={clase}
                      onChange={handleClase}
                      disabled={loading}
                    >
                      {textClase?.map((item, index) => (
                        <MenuItem key={`index-${index}`} value={item.id}>
                          <Typography>{item.title}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box sx={styles.field}>
                    <FormLabel sx={styles.label}> Tipo </FormLabel>
                    <Select
                      fullWidth
                      id="model-year"
                      type="text"
                      name="year"
                      sx={{ width: "100%" }}
                      value={grupo}
                      onChange={handleGrupo}
                      disabled={loading}
                    >
                      {textGrupo?.length === 0 ? (
                        <Typography sx={{ paddingLeft: "5%" }}>
                          No hay Tipos
                        </Typography>
                      ) : (
                        textGrupo?.map((item, index) => (
                          <MenuItem key={`index-${index}`} value={item.id}>
                            <Typography>{item.title}</Typography>
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "5%",
                  }}
                >
                  <Box sx={styles.field}>
                    <FormLabel sx={styles.label}> Clase </FormLabel>
                    <Select
                      fullWidth
                      id="model-year"
                      type="text"
                      name="year"
                      sx={{ width: "100%" }}
                      value={clase}
                      onChange={handleClase}
                      disabled={loading}
                    >
                      {textClase?.map((item, index) => (
                        <MenuItem key={`index-${index}`} value={item.id}>
                          <Typography>{item.title}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box sx={styles.field}>
                    <FormLabel sx={styles.label}> Tipo </FormLabel>
                    <Select
                      fullWidth
                      id="model-year"
                      type="text"
                      name="year"
                      sx={{ width: "100%" }}
                      value={grupo}
                      onChange={handleGrupo}
                      disabled={clase === "" || loading}
                    >
                      {textGrupo?.map((item, index) => (
                        <MenuItem key={`index-${index}`} value={item.id}>
                          <Typography>{item.title}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={5}>
                dd
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "5%",
                  }}
                >
                  <Box sx={styles.field}>
                    <FormLabel sx={styles.label}> Clase </FormLabel>
                    <Select
                      fullWidth
                      id="model-year"
                      type="text"
                      name="year"
                      sx={{ width: "100%" }}
                      value={clase}
                      onChange={handleClase}
                      disabled={loading}
                    >
                      {textClase?.map((item, index) => (
                        <MenuItem key={`index-${index}`} value={item.id}>
                          <Typography>{item.title}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box sx={styles.field}>
                    <FormLabel sx={styles.label}> Tipo </FormLabel>
                    <Select
                      fullWidth
                      id="model-year"
                      type="text"
                      name="year"
                      sx={{ width: "100%" }}
                      value={grupo}
                      onChange={handleGrupo}
                      disabled={clase === "" || loading}
                    >
                      {textGrupo?.map((item, index) => (
                        <MenuItem key={`index-${index}`} value={item.id}>
                          <Typography>{item.title}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
              </TabPanel>
            </Box>
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <TextField
                margin="dense"
                id="codCuenta"
                sx={{ width: "47%" }}
                label="Codigo"
                type="text"
                fullWidth
                value={codCuenta}
                onChange={handleCodCuentaChange}
              />
              <TextField
                margin="dense"
                id="nameAccount"
                sx={{ width: "47%" }}
                label="Nombre"
                type="text"
                fullWidth
                value={nameAccount}
                onChange={handleNameAccountChange}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <LoadingButton
              onClick={handleSubmit}
              color="primary"
              sx={{
                backgroundColor: "#2196F3",
                color: "white",
                "&:hover": { backgroundColor: "#2196F3" },
              }}
              disabled={
                loadingBtn ||
                !tipo ||
                !clase ||
                codCuenta === "" ||
                nameAccount === ""
              }
              endIcon={<SaveIcon />}
              loading={loadingBtn}
              loadingPosition="end"
            >
              Guardar
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <Dialog open={openSaldos} onClose={handleClose}>
          <DialogTitle>Saldos Iniciales</DialogTitle>
          <Box
            component="div"
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              gap: "20%",
            }}
          >
            <Button
              sx={{
                backgroundColor: "#FBC02D",
                color: "white",
                marginLeft: "2%",
                "&:hover": { backgroundColor: "#FBC02D", color: "white" },
              }}
              onClick={changeEditAvailable}
              disabled={
                accountEdit?.debe === "" ||
                accountEdit?.haber === "" ||
                (resetState.debe === "" && resetState.haber === "")
              }
            >
              {editAvailable === true ? "Dejar de Editar" : "Editar"}
            </Button>
            <Button
              sx={{
                backgroundColor: "#E28080",
                color: "white",
                marginLeft: "2%",
                "&:hover": { backgroundColor: "#E28080", color: "white" },
              }}
              onClick={() => {
                setOpenDeleteSaldo(!openDeleteSaldo);
              }}
              disabled={resetState.debe === "" && resetState.haber === ""}
            >
              Eliminar
            </Button>
          </Box>
          <DialogContent>
            <CssTextField
              autoFocus
              margin="dense"
              id="cuenta"
              label="Cuenta"
              type="text"
              fullWidth
              value={cuenta}
              onChange={handleCuentaChange}
              disabled
            />
            <CssTextField
              margin="dense"
              id="saldoDebe"
              label="Saldo Debe"
              type="text"
              fullWidth
              value={saldoDebe}
              onChange={handleSaldoDebeChange}
              disabled={
                !editAvailable && resetState?.debe !== ""
              }
            />
            <CssTextField
              margin="dense"
              id="saldoHaber"
              label="Saldo Haber"
              type="text"
              fullWidth
              value={saldoHaber}
              onChange={handleSaldoHaberChange}
              disabled={
                !editAvailable && resetState?.haber !== ""
              }
            />
          </DialogContent>
          <DialogActions>
            {resetState.debe === saldoDebe &&
            resetState.haber === saldoHaber ? (
              <Box></Box>
            ) : (
              <Button onClick={handleClose}>Cancelar</Button>
            )}
            {resetState.debe === saldoDebe &&
            resetState.haber === saldoHaber ? (
              <Button
                onClick={handleClose}
                color="primary"
                sx={{
                  backgroundColor: "#2196F3",
                  color: "white",
                  "&:hover": { backgroundColor: "#2196F3" },
                }}
                disabled={
                  cuenta === "" || saldoDebe === "" || saldoHaber === ""
                }
              >
                Cerrar
              </Button>
            ) : (editAvailable === true && resetState.debe !== saldoDebe) ||
              (editAvailable === true && resetState.haber !== saldoHaber) ? (
              <LoadingButton
                onClick={handleSubmitSaldoInicialEdit}
                color="primary"
                sx={{
                  backgroundColor: "#2196F3",
                  color: "white",
                  "&:hover": { backgroundColor: "#2196F3" },
                }}
                endIcon={<SaveIcon />}
                loading={loading}
                loadingPosition="end"
                disabled={
                  cuenta === "" || saldoDebe === "" || saldoHaber === ""
                }
              >
                Modificar
              </LoadingButton>
            ) : (
              <LoadingButton
                onClick={handleSubmitSaldoInicial}
                color="primary"
                sx={{
                  backgroundColor: "#2196F3",
                  color: "white",
                  "&:hover": { backgroundColor: "#2196F3" },
                }}
                endIcon={<SaveIcon />}
                loading={loading}
                loadingPosition="end"
                disabled={
                  (saldoDebe === "" && saldoHaber === undefined) ||
                  (saldoDebe === undefined && saldoHaber === "") ||
                  saldoDebe === undefined ||
                  saldoHaber === undefined ||
                  saldoHaber === "" ||
                  saldoDebe === ""
                }
              >
                Guardar
              </LoadingButton>
            )}
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDeleteSaldo}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Estas seguro que deseas eliminar este saldo inicial?
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <LoadingButton
              onClick={handleSubmitSaldoInicialDelete}
              color="primary"
              sx={{
                backgroundColor: "#E28080",
                color: "white",
                "&:hover": { backgroundColor: "#E28080" },
              }}
              endIcon={<DeleteIcon />}
              loading={loading}
              loadingPosition="end"
            >
              Eliminar
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <Dialog open={openEdit} onClose={handleClose}>
          <DialogTitle>Editar Cuenta Contable</DialogTitle>
          <DialogContent>
            <CssTextField
              autoFocus
              margin="dense"
              id="Codigo"
              label="Codigo"
              type="text"
              fullWidth
              value={codCuentaEdit}
              onChange={handleCodCuentaEditChange}
            />
            <CssTextField
              margin="dense"
              id="Nombre"
              label="Nombre"
              type="text"
              fullWidth
              value={nameAccount}
              onChange={handleNameAccountChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <LoadingButton
              onClick={handleSubmitEdit}
              color="primary"
              sx={{
                backgroundColor: "#2196F3",
                color: "white",
                "&:hover": { backgroundColor: "#2196F3" },
              }}
              endIcon={<SaveIcon />}
              loading={loading}
              loadingPosition="end"
              disabled={loading || nameAccount === "" || codCuentaEdit === ""}
            >
              Guardar
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDelete}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Estas seguro que deseas eliminar esta cuenta?
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <LoadingButton
              onClick={handleSubmitDelete}
              color="primary"
              sx={{
                backgroundColor: "#E28080",
                color: "white",
                "&:hover": { backgroundColor: "#E28080" },
              }}
              endIcon={<DeleteIcon />}
              loading={loading}
              loadingPosition="end"
            >
              Eliminar
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
