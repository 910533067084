import axios from 'axios';
import Constantes from '../Constantes';
import decode from 'jwt-decode';

    
const login = (datos) => {
		// Connect to the api to verify access credentials. If the answer is correct, store the Token in the LocalStorage
		return axios.post(`${Constantes.RUTA_API}/login.php`, datos)
		.then(res => {
			const data = res.data;
			if (!data.error){
				setToken(data.jwt) // Setting the token in localStorage
				// console.log ("token en login ", data.tokenFCM);
				setTokenFCM(data.tokenFCM) // Setting the token in localStorage
			}
			return Promise.resolve(data);
			
		}).catch(error => {
			console.log(error);
		})
}

const validarUsuario = (datos) => {
	// Connect to the api to verify access credentials. If the answer is correct, store the Token in the LocalStorage
	return axios.post(`${Constantes.RUTA_API}/validar_usuario.php`, datos)
	.then(res => {
		const data = res.data;
		if (!data.error){
			setToken_aux(data.jwt) // Setting the token in localStorage
		}
		return Promise.resolve(data);
		
	}).catch(error => {
		console.log(error);
	})
}

const  loggedIn = () => {
		// Checks if there is a saved token and it's still valid
		const token = getToken() // Getting token from localstorage
		return !!token && !isTokenExpired(token) // handwaiving here
}

const  isTokenExpired = (token) => {
		try {
				const decoded = decode(token);
				if (decoded.exp < Date.now() / 1000) { // Checking if token is expired.
						return true;
				}
				else
						return false;
		}
		catch (err) {
				console.log("expired check failed!");
				return false;
		}
}

const  setToken = (idToken) => {
		// Saves user token to localStorage
		localStorage.setItem('id_token', idToken)
}

const  setTokenFCM = (idToken) => {
	// Saves user tokenFCM to localStorage
	localStorage.setItem('id_token_fcm', idToken)
}

const  setToken_aux = (idToken_aux) => {
	// Saves user token to localStorage
	localStorage.setItem('id_token_aux', idToken_aux)
}

const getToken = () => {
		// Retrieves the user token from localStorage
		return localStorage.getItem('id_token')
}

const getTokenFCM = () => {
	// Retrieves the user tokenFCM from localStorage
	return localStorage.getItem('id_token_fcm')
}

const getToken_aux = () => {
	// Retrieves the user token from localStorage
	return localStorage.getItem('id_token_aux')
}

const logout = (userData) => {
		
		//Desactivo token en BD
		const datos = {
			"idUsuario" : userData.idUsuario,
			"idContrib" : userData.contribuyente,
			"tipo" : "D"
		}
		// datos["idContrib"] = idContrib;
		return axios.post(`${Constantes.RUTA_API}/admin_token_notificaciones.php`, datos)
		.then(res => {
			// Clear user token and profile data from localStorage
			localStorage.removeItem('id_token');
			
		}).catch(error => {
			console.log(error);
		})
}

const getUserData = () => {
		try {
			let user = decode(getToken());
			return user.data;	
		} catch (error) {
			console.log("Token no encontrado " + error);
		}
		
}

const getUserData_aux = () => {
	try {
		let user = decode(getToken_aux());
		return user.data;	
	} catch (error) {

		console.log("Token not found " + error);
	}
	
}

const initAxiosInterceptors = () => {
	axios.interceptors.request.use(function(config) {
		const token = getToken();
		
		if (loggedIn()) {
			config.headers.Authorization =  `Bearer ${token}`;
		}
		return config;
	});
}

function findHijo(item, idx, array){
	if (item.hasOwnProperty("items")){
		let existeHijo = item.items.find(subitem => subitem.to === this);
		return existeHijo;
	}
}

const configPermisosRW = (location, userData) => {
	let acceso = null;
	if (!location.hasOwnProperty("aboutProps")){
		let existeElemento = userData.menu.find(item => item.to === location.pathname);
		if (existeElemento === undefined){
			let existeHijo = userData.menu.find(findHijo, location.pathname);
			if (existeHijo !== undefined){
				acceso = existeHijo.acceso;		
			}
		} else {
			acceso = existeElemento.acceso;	
		}
	} else {
		acceso = location.aboutProps.acceso;
	}
	return acceso;
}



export { login, loggedIn, setToken, logout, initAxiosInterceptors, getUserData, validarUsuario, getUserData_aux, getTokenFCM, setTokenFCM, configPermisosRW } 