import React, { useState, useEffect, useRef } from "react";
import { Grid, Container, Paper, Avatar, Typography, CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Edit as AddIcon } from "@material-ui/icons";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import { useHistory } from "react-router-dom";
import { HttpService } from '../../service/HttpService';
import { Toast } from 'primereact/toast';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#2E6DB4",
        height: "100vh",
        overflow: "auto",
    },
    container: {
        opacity: '0.8',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        [theme.breakpoints.down(400 + theme.spacing(2) + 2)]: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            width: '100%',
        }
    },
    div: {
        marginTop: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    div_error: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%",
        height: "80vh",
        marginTop: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const initialFValues = {
    txtEmail: '',
};

const clearStorage = () => {
    localStorage.clear();
};

export default function OlvideClave(props) {
    const httpService = new HttpService();
    const toast = useRef(null);

    useEffect(() => {
        clearStorage();
        return () => { };
    }, []);



    const history = useHistory();
    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const classes = useStyles();

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("txtEmail" in fieldValues) {
            if (fieldValues.txtEmail === "") {
                temp.txtEmail = "Campor Requerido.";
            } else if (!(/$^|.+@.+..+/.test(fieldValues.txtEmail))) {
                temp.txtEmail = "Email no valido.";
            } else {
                temp.txtEmail = "";
            }
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setButtonDisabled(true);
            httpService.olvidoDeClave(values)
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        let temp = { ...errors };
                        if (res.message.indexOf("Rif") >= 0) {
                            temp.txtRif = res.message;
                        } else {
                            temp.txtEmail = res.message;
                        }
                        setErrors({
                            ...temp,
                        });
                    } else {
                        //registro exitoso
                        toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                        resetForm();
                        setTimeout(() => {
                            history.push("/login");
                        }, 3100);
                    }
                })
                .catch((err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
                    setButtonDisabled(false);
                });
        }
    };

    return (
        <Form onSubmit={handleSubmit} id="formRegistro">
            <Toast ref={toast} />
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Container component={Paper} elevation={5} maxWidth="xs" className={classes.container}>
                    <div className={classes.div}>
                        <Avatar className={classes.avatar}>
                            <AddIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Recuperación de Datos
                        </Typography>
                        <Typography component="h1" variant="subtitle1" align="center">
                            Escriba la dirección de correo electrónico asociada a su usuario
                        </Typography>
                        <div className="p-field p-col-12 p-md-12">
                            <Controls.Input fullWidth autoFocus color="Primary" margin="normal" variant="outlined" onChange={handleInputChange} name="txtEmail" label="Email" value={values.txtEmail} error={errors.txtEmail} />
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <Controls.Button fullWidth variant="contained" color="primary" className={classes.button} disabled={ButtonDisabled} type="submit" text="Enviar" />
                        </div>
                    </div>
                </Container>
            </Grid>
        </Form>
    );
}
