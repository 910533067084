import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData } from '../../service/AuthHelperMethods';
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {Tooltip} from 'primereact/tooltip';
/* import { useHistory } from 'react-router-dom'; */
import jsPDF from 'jspdf';
import ShowLoading from "../../components/ShowLoading/ShowLoading";

const initialFValues = {
    txtFechaDesde: '',
    txtContribuyente: '',
    id: ''
};
export const LibroDeInventario = () => {

    const toast = useRef(null);
    const [inventario, setInventario] = useState([]);
    const httpService = new HttpService();
    const userData = getUserData() || null;
    const [submitted, /* setSubmitted */] = useState(false);
    const [fdesde, setFDesde] = useState('_/_/');
	const [loadingDialog, setLoadingDialog] = useState(false);

    let razon_social_aux = userData.razon_social;
    let direccion_aux = userData.direccion;
    let rif_aux = userData.rif;

    useEffect(() => {
        
    }, []);

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboProducto" in fieldValues) temp.cboProducto = fieldValues.cboProducto ? "" : "Campo Requerido.";
        if ("cboUoM" in fieldValues) temp.cboUoM = fieldValues.cboUoM ? "" : "Campo Requerido.";

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

    const cols = [
        { field: 'txtDescripcion', header: 'Descripcion' },
        { field: 'txtCantII', header: 'Cant.' },
        { field: 'txtPrecioII', header: 'Precio BS.' },
		{ field: 'txtValorII', header: 'Valor Bs.' },
		{ field: 'txtCantE', header: 'Cant.' },
		{ field: 'txtPrecioE', header: 'Precio Bs.' },
		{ field: 'txtValorE', header: 'Valor Bs.' },
		{ field: 'txtCantS', header: 'Cant.' },
		{ field: 'txtPrecioS', header: 'Precio Bs.' },
		{ field: 'txtValorS', header: 'Valor Bs.' },
        { field: 'txtCantIF', header: 'Cant.' },
        { field: 'txtPrecioIF', header: 'Precis Bs.' },
        { field: 'txtValorIF', header: 'Valor Bs.' }
    ];

    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
        
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    }

    const exportPdf = () => {

        const doc = new jsPDF('landscape');
		// doc.autoTable({ html: '#my-table' })
		console.log(userData);
		doc.setFontSize(12);
		doc.setFont("helvetica", "bold", );
		doc.text(userData.razon_social, 15, 20);
		doc.setFont("helvetica", "normal");
		doc.text(userData.direccion, 15, 25);
		doc.text("RIF: "+ userData.rif, 15, 30);
		// doc.text("This is right aligned text", 200, 100, null, null, "right");
		doc.setFont("helvetica", "bold");
		doc.text("LIBRO DE  INVENTARIO", 150, 30, null, null, "center");
		doc.text(`MES/AÑO:  ${fdesde}`, 150, 37, null, null, "center");
        doc.text("Inventario Inicial", 68, 47, "center");
        doc.text("Entradas", 130, 47, "center");
        doc.text("Salidas", 185, 47, "center");
        doc.text("Inventario Final", 250, 47, "center");
        var totalCantII = 0;
        var totalValorII = 0;
        var totalCantE = 0;
        var TotalValorE = 0;
        var totalCantS = 0;
        var TotalValorS = 0;
        var totalCantIF = 0;
        var TotalValorIF = 0;

        for (let mont of inventario) {
            totalCantII += parseFloat(mont.txtCantIISF);
        }
        
        for (let mont of inventario) {
            totalValorII += parseFloat(mont.txtValorIISF);
        }

        for (let mont of inventario) {
            totalCantE += parseFloat(mont.txtCantESF);
        }

        for (let mont of inventario) {
            TotalValorE += parseFloat(mont.txtValorESF);
        }

        for (let mont of inventario) {
            totalCantS += parseFloat(mont.txtCantSSF);
        }

        for (let mont of inventario) {
            TotalValorS += parseFloat(mont.txtValorSSF);
        }

        for (let mont of inventario) {
            totalCantIF += parseFloat(mont.txtCantIFSF);
        }

        for (let mont of inventario) {
            TotalValorIF += parseFloat(mont.txtValorIFSF);
        }
        var body = [...inventario.map(el => [el.txtDescripcion, el.txtCantII, el.txtPrecioII, el.txtValorII,
                el.txtCantE, el.txtPrecioE, el.txtValorE, el.txtCantS, el.txtPrecioS, el.txtValorS,
                el.txtCantIF, el.txtPrecioIF, el.txtValorIF]), 
        [{content: 
            `\n TOTAL CANT. INVENTARIO INICIAL = ${totalCantII} \n` + 
            `\n TOTAL VALOR INVENTARIO INCIAL = ${totalValorII} \n` + 
            `\n TOTAL CANT. ENTRADA = ${totalCantE} \n` + 
            `\n TOTAL VALOR ENTRADA = ${TotalValorE} \n`+ 
            `\n TOTAL CANT. SALIDA = ${totalCantS} \n` + 
            `\n TOTAL VALOR SALIDA = ${TotalValorS} \n`+ 
            `\n TOTAL CANT. INVENTARIO FINAL = ${totalCantIF} \n` + 
            `\n TOTAL VALOR INVENTARIO FINAL = ${TotalValorIF} \n`, colSpan: 11, 
            styles: { fontStyle: 'bold', halign: 'right' }
        }]]
		
		doc.autoTable({
			margin: { top: 50 }, 
			columns: exportColumns, 
			body: body,
            styles: {overflow: 'linebreak', fontSize: 9}
		});
		doc.save('libro_inventario.pdf');
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(inventario);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'inventario');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="p-mr-2" data-pr-tooltip="CSV" />
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />
                <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning p-mr-2" data-pr-tooltip="PDF" />
            </React.Fragment>
        )
    }

    const DescripcionBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtDescripcion}
            </>
        );
    }

    const CantIIBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtCantII}
            </>
        );
    }

    const PrecioIIBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtPrecioII}
            </>
        );
    }

    const ValorIIBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtValorII}
            </>
        );
    }

    const CantidadEBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtCantE}
            </>
        );
    }

    const PrecioEBodyTemplate = (rowData) => {
        return `${rowData.txtPrecioE}`;
    }

    const ValorEBodyTemplate = (rowData) => {
        return `${rowData.txtValorE}`;
    }

    const CantidadSBodyTemplate = (rowData) => {
        return `${rowData.txtCantS}`;
    }

    const PrecioSBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtPrecioS}
            </>
        );
    }

    const ValorSBodyTemplate = (rowData) => {
        return `${rowData.txtValorS}`;
    }

    const CantidadIFBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtCantIF}
            </>
        );
    }

    const PrecioIFBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtPrecioIF}
            </>
        );
    }

    const ValorIFBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtValorIF}
            </>
        );
    }

    const dt = useRef(null);

    const GenerarLibroInventario = () => {
		setLoadingDialog(true);
        console.log(inventario)
        var fd = new Date(values.txtFechaDesde);
        setFDesde(`${(fd.getMonth()+1)}`.padStart(2,'0')+ "/" + fd.getFullYear());
        
        console.log(values);
        httpService.getTableSelect("libro_de_inventario", userData.contribuyente, values.txtFechaDesde)
		.then(data => {setInventario(data); setLoadingDialog(false);})
		.catch(err => setLoadingDialog(false));
    }

    /* const formatCurrency = function (number) {
        var numeral = require('numeral');
        var myNumeral = numeral(number);
        return myNumeral.format('0,0.00');
    }; */

    const TotalCantIISF = () => {
        let total = 0;
        for (let mont of inventario) {
            total += parseFloat(mont.txtCantIISF);
        }
        return total;
    }

    const TotalValorIISF = () => {
        let total = 0;
        for (let mont of inventario) {
            total += parseFloat(mont.txtValorIISF);
        }
        return total;
    }

    const TotalCantESF = () => {
        let total = 0;
        for (let mont of inventario) {
            total += parseFloat(mont.txtCantESF);
        }
        return total;
    }

    const TotalValorESF = () => {
        let total = 0;
        for (let mont of inventario) {
            total += parseFloat(mont.txtValorESF);
        }
        return total;
    }

    const TotalCantSSF = () => {
        let total = 0;
        for (let mont of inventario) {
            total += parseFloat(mont.txtCantSSF);
        }
        return total;
    }

    const TotalValorSSF = () => {
        let total = 0;
        for (let mont of inventario) {
            total += parseFloat(mont.txtValorSSF);
        }
        return total;
    }

    const TotalCantIFSF = () => {
        let total = 0;
        for (let mont of inventario) {
            total += parseFloat(mont.txtCantIFSF);
        }
        return total;
    }

    const TotalValorIFSF = () => {
        let total = 0;
        for (let mont of inventario) {
            total += parseFloat(mont.txtValorIFSF);
        }
        return total;
    }

    let headerGroup = 
    <ColumnGroup>
        <Row>
            <Column header={razon_social_aux.toUpperCase()} colSpan={13} headerStyle={{ textAlign: 'center' }} />
        </Row>
        <Row>
            <Column header={direccion_aux.toUpperCase()} colSpan={13} headerStyle={{ textAlign: 'center' }}/>
        </Row>
        <Row>
            <Column header={'RIF: ' + rif_aux.toUpperCase()} colSpan={13} headerStyle={{ textAlign: 'center' }}/>
        </Row>
        <Row>
            <Column header={'Relacion de Movimientos de Entradas y Salidas de los Inventarios'} colSpan={13} headerStyle={{ textAlign: 'center' }}/>
        </Row>
        <Row>
            <Column header={'Mes/Año: ' + fdesde} colSpan={13} headerStyle={{ textAlign: 'center' }}/>
        </Row>
        <Row>
            <Column header={''} colSpan={1} headerStyle={{ textAlign: 'center' }}/>
            <Column header={'Inventario Inicial'} colSpan={3} headerStyle={{ textAlign: 'center' }}/>
            <Column header={'Entradas'} colSpan={3} headerStyle={{ textAlign: 'center' }}/>
            <Column header={'Salidas'} colSpan={3} headerStyle={{ textAlign: 'center' }}/>
            <Column header={'Inventario Final'} colSpan={3} headerStyle={{ textAlign: 'center' }}/>
        </Row>
        <Row>
            <Column header="Descripcion" field="txtDescripcion" />
            <Column header="Cant" field="txtCantII" />
            <Column header="Precio Bs." field="txtPrecioII" />
            <Column header="Valor Bs." field="txtValorII" />
            <Column header="Cant" field="txtCantE" />
            <Column header="Precio Bs." field="txtPrecioE" />
            <Column header="Valor Bs." field="txtValorE" />
            <Column header="Cant" field="txtCantS" />
            <Column header="Precio Bs." field="txtPrecioS" />
            <Column header="Valor Bs." field="txtValorS" />
            <Column header="Cant" field="txtCantIF" />
            <Column header="Precio Bs." field="txtPrecioIF" />
            <Column header="Valor Bs." field="txtValorIF" />
        </Row>
    </ColumnGroup>;

    let footerGroup = <ColumnGroup>
    <Row>
        <Column footer="TOTAL CANT. INVENTARIO INICIAL:" colSpan={12} footerStyle={{ textAlign: 'right' }} />
        <Column footer={TotalCantIISF} />
    </Row>
    <Row>
        <Column footer="TOTAL VALOR INVENTARIO INCIAL:" colSpan={12} footerStyle={{ textAlign: 'right' }} />
        <Column footer={TotalValorIISF} />
    </Row>
    <Row>
        <Column footer="TOTAL CANT. ENTRADA:" colSpan={12} footerStyle={{ textAlign: 'right' }} />
        <Column footer={TotalCantESF} />
    </Row>
    <Row>
        <Column footer="TOTAL VALOR ENTRADA:" colSpan={12} footerStyle={{ textAlign: 'right' }} />
        <Column footer={TotalValorESF} />
    </Row>
    <Row>
        <Column footer="TOTAL VALOR SALIDA:" colSpan={12} footerStyle={{ textAlign: 'right' }} />
        <Column footer={TotalCantSSF} />
    </Row>
    <Row>
        <Column footer="TOTAL VALOR SALIDA:" colSpan={12} footerStyle={{ textAlign: 'right' }} />
        <Column footer={TotalValorSSF} />
    </Row>
    <Row>
        <Column footer="TOTAL VALOR INVENTARIO FINAL:" colSpan={12} footerStyle={{ textAlign: 'right' }} />
        <Column footer={TotalCantIFSF} />
    </Row>
    <Row>
        <Column footer="TOTAL VALOR INVENTARIO FINAL:" colSpan={12} footerStyle={{ textAlign: 'right' }} />
        <Column footer={TotalValorIFSF} />
    </Row>
    </ColumnGroup>;


    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <h5>Libro de Inventario</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="txtFechaDesde">Fecha:</label>
                            <Calendar showIcon showButtonBar dateFormat="dd/m/yy" id="txtFechaDesde" name="txtFechaDesde" value={values.txtFechaDesde} onChange={handleInputChange} monthNavigator yearNavigator yearRange="2010:2030" className={classNames({ 'p-invalid': submitted && !values.txtFechaDesde })} />
                            {submitted && !values.txtFechaDesde && <small className="p-invalid">{errors.txtFechaDesde}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-12">
                            <React.Fragment>
                                <Button label="Generar Libro de Inventario" icon="pi pi-add" className="p-button-info p-mr-2" onClick={GenerarLibroInventario} />
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <Tooltip target=".export-buttons>button" position="bottom" />
                    <DataTable ref={dt} value={inventario} dataKey="id" headerColumnGroup={headerGroup} footerColumnGroup={footerGroup} >
                        <Column field="txtDescripcion" header="Descripcion" body={DescripcionBodyTemplate}></Column>
                        <Column field="txtCantII" header="Cantidad" body={CantIIBodyTemplate}></Column>
                        <Column field="txtPrecioII" header="Precio Bs" body={PrecioIIBodyTemplate}></Column>
                        <Column field="txtValorII" header="Valor Bs" body={ValorIIBodyTemplate}    ></Column>
                        <Column field="txtCantE" header="Cantidad" body={CantidadEBodyTemplate}></Column>
                        <Column field="txtPrecioE" header="Precio Bs" body={PrecioEBodyTemplate}></Column>
                        <Column field="txtValorE" header="Valor Bs" body={ValorEBodyTemplate}></Column>
                        <Column field="txtCantS" header="Cantidad" body={CantidadSBodyTemplate}></Column>
                        <Column field="txtPrecioS" header="Precio Bs" body={PrecioSBodyTemplate}></Column>
                        <Column field="txtValorS" header="Valor Bs" body={ValorSBodyTemplate}></Column>
                        <Column field="txtCantIF" header="Cantidad" body={CantidadIFBodyTemplate}></Column>
                        <Column field="txtPrecioIF" header="Precio Bs" body={PrecioIFBodyTemplate}></Column>
                        <Column field="txtValorIF" header="Valor Bs" body={ValorIFBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            <div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
};
