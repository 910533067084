import React, { useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { logout, getUserData } from './service/AuthHelperMethods';
import { useHistory } from 'react-router-dom';

export const AppProfile = () => {

    const [expanded, setExpanded] = useState(false);

    const userData = getUserData() || null;

    const onClick = (event) => {
        setExpanded(prevState => !prevState);
        event.preventDefault();
    }

    const history = useHistory();
    const handlelogout = () => {
		if (userData !== null)
        	logout(userData);
        history.push('/login')
    }

    const CambioClave = () => {
        history.push('/cambio_clave')
    }

    return (
        <div className="layout-profile">
            {/*<div>
                <img src="assets/layout/images/profile.png" alt="Profile" />
            </div> */}
            <button className="p-link layout-profile-link" onClick={onClick}>
                <span className="username">{userData ? userData.nombre_completo : ""}</span>
                <i className="pi pi-fw pi-cog" />
            </button>
            <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={expanded} unmountOnExit>
                <ul className={classNames({ 'layout-profile-expanded': expanded })}>
                    {/* <li><button type="button" className="p-link"><i className="pi pi-fw pi-user" /><span>Cuenta</span></button></li> */}
                    <li><button type="button" className="p-link" onClick={CambioClave}><i className="pi pi-fw pi-lock" /><span>Cambio de Clave</span></button></li>
                    {/* <li><button type="button" className="p-link"><i className="pi pi-fw pi-inbox" /><span>Notificaciones</span><span className="menuitem-badge">0</span></button></li> */}
                    <li><button type="button" className="p-link" onClick={handlelogout}><i className="pi pi-fw pi-power-off" /><span>Cerrar Sesion</span></button></li>
                </ul>
            </CSSTransition>
        </div>
    );

}
