import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { HttpService } from "../../service/HttpService";
import { getUserData, configPermisosRW } from "../../service/AuthHelperMethods";
import ShowLoading from "../../components/ShowLoading/ShowLoading";
import { useLocation, NavLink } from 'react-router-dom';

export const Notificaciones = () => {

	let location = useLocation();
	const [acceso, setAcceso] = useState("");
    const [httpService] = useState(new HttpService());
    const [userData] = useState(getUserData() || null);
    const [notificaciones, setNotificaciones] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
	const [loadingDialog, setLoadingDialog] = useState(false);

    useEffect(() => {
		setLoadingDialog(true);
		let acc = configPermisosRW(location, userData);
		setAcceso(acc);
        let isSubscribed = true;
        httpService.getGeneralData("all_notificaciones", userData.contribuyente)
		.then((data) => {setNotificaciones(data); setLoadingDialog(false);})
		.catch(err => setLoadingDialog(false));
        return () => (!isSubscribed);
    }, [httpService, userData, location]);

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.id}
            </>
        );
    };

    /* const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.texto_notificacion}
            </>
        );
    }; */

    const nameDeberBodyTemplate = (rowData) => {
		 
		if (rowData.nombre_deber.search('Declara') >= 0 && acceso && acceso === "W") {
			
			return (
				<>
					{/* <span className="p-column-title">Deber Formal</span>
					<a href="declaraciones">{rowData.nombre_deber}</a>
					<Link to="/declaraciones" replace  /> */}
					<NavLink to="/declaraciones">{rowData.nombre_deber}</NavLink>
				</>
			);
		} else {
			return (
				<>
					<span className="p-column-title">Deber Formal</span>
					{rowData.nombre_deber}
				</>
			);
		}
    };

    const inicioAlarmaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Inicio Recordatorio</span>
                {rowData.fecha_init_periodo}
            </>
        );
    };
    const finAlarmaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Inicio Recordatorio</span>
                {rowData.fecha_fin_periodo}
            </>
        );
    };

    const vencimientoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Vencimiento</span>
                {rowData.fecha_vence}
            </>
        );
    };

    const estatusBodyTemplate = (rowData) => {
		const colorStyle = {
            'color': rowData.estatus === "Vencida" ? 'red' : 'var(--blue-500)',
			'fontWeight': 'bold'
        };

        return (
            <span style={colorStyle}>
                {rowData.estatus}
			</span>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Histórico de Notificaciones</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <DataTable
                        ref={dt}
                        value={notificaciones}
                        // selection={selectedPerfiles}
                        // onSelectionChange={(e) => setSelectedPerfiles(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
						className="datatable-responsive p-datatable-striped p-datatable-customers"
						rowHover
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} Notificaciones"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron notificaciones."
                        header={header}
                    >
                        <Column field="id" header="ID" headerStyle={{ display: "none" }} bodyStyle={{ display: "none" }} body={codeBodyTemplate}></Column>
                        <Column field="nombre_deber" header="Deber Formal"  headerStyle={{ width: "25%" }} body={nameDeberBodyTemplate}></Column>
                        <Column field="fecha_init_periodo" header="Inicio Periodo Imposición"  headerStyle={{ width: "15%", textAlign: "center" }} bodyStyle={{textAlign: "center"}} body={inicioAlarmaBodyTemplate}></Column>
                        <Column field="fecha_fin_periodo" header="Fin Periodo Imposición"  headerStyle={{ width: "15%", textAlign: "center" }} bodyStyle={{textAlign: "center"}} body={finAlarmaBodyTemplate}></Column>
                        <Column field="fecha_vence" header="Vencimiento"  headerStyle={{ width: "15%", textAlign: "center" }} bodyStyle={{textAlign: "center"}} body={vencimientoBodyTemplate}></Column>
                        <Column field="estatus" header="Estatus"  headerStyle={{ width: "10%", textAlign: "center" }}  bodyStyle={{textAlign: "center"}} body={estatusBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
};
