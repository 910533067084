export const styles = {
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    width: "100%",
  },
  gridTextTitle: {
    fontSize: "14px",
    fontWeight: 500,
    paddingBottom: "6px",
  },
  gridTextContent: {
    fontSize: "14px",
    fontWeight: 400,
    paddingBottom: "6px",
  },
};
