import React, { useState, useEffect, useRef } from "react";
import moment from 'moment'
import { Grid, Container, Paper, Avatar, Typography, CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Edit as AddIcon } from "@material-ui/icons";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import { useHistory } from "react-router-dom";
import { HttpService } from "../../service/HttpService";
import { Toast } from "primereact/toast";
import ShowLoading from "../../components/ShowLoading/ShowLoading";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#2E6DB4",
        height: "100vh",
        overflow: "auto",
    },
    container: {
        opacity: "0.8",
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down(400 + theme.spacing(2) + 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            width: "100%",
        },
    },
    div: {
        marginTop: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    div_error: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%",
        height: "80vh",
        marginTop: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const initialFValues = {
    txtNroDocRL: "-",
    txtNombreRL: "",
    txtRazonSocial: "",
    txtRif: " -        - ",
    txtDireccion: "",
    txtTelefono1: "",
    txtTelefono2: "",
    txtEmail: "",
    cboTipoContrib: "",
    cboUsaMaquina: "",
    fechaAdquiMF: null,
    txtCantidadMF: "",
    cboPeriodoFiscal: "",
    fechaCierrePF: null,
    cboTipoActividadComercial: "",
    txtSubActividadComercial: "",
};

const clearStorage = () => {
    localStorage.clear();
};

export default function Registro(props) {
    const [httpService] = useState(new HttpService());
    const toast = useRef(null);
    const [tipoContribOptions, seTtipoContribOptions] = useState([]);
    const [tipoActComercialOptions, setTipoActComercialOptions] = useState([]);
    const [periodoFiscalOptions, setPeriodoFiscalOptions] = useState([]);
    const [subActComercialOptions, setSubActComercialOptions] = useState([]);
    const [loadingDialog, setLoadingDialog] = useState(false);

    const usaMaquinaOptions = [
        { id: "1", title: "Si" }, { id: "0", title: "No" }
    ];

    useEffect(() => {
        clearStorage();
        return () => { };
    }, []);

    useEffect(() => {
        httpService.getOptionSelect("tipo_contribuyente").then((data) => seTtipoContribOptions(data));
        httpService.getOptionSelect("actividad_comercial").then((data) => setTipoActComercialOptions(data));
        httpService.getOptionSelect("periodo_fiscal").then((data) => setPeriodoFiscalOptions(data));
        httpService.getOptionSelect("sub_actividad_comercial").then((data) => setSubActComercialOptions(data));
    }, [httpService]);

    const history = useHistory();
    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const classes = useStyles();

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("txtNroDocRL" in fieldValues) temp.txtNroDocRL = fieldValues.txtNroDocRL.trim() !== "-" && fieldValues.txtNroDocRL.trim() !== "" ? "" : "Campo Requerido.";
        if ("txtNombreRL" in fieldValues) temp.txtNombreRL = fieldValues.txtNombreRL ? "" : "Campo Requerido.";
        if ("txtRazonSocial" in fieldValues) temp.txtRazonSocial = fieldValues.txtRazonSocial ? "" : "Campo Requerido.";
        if ("txtRif" in fieldValues) temp.txtRif = fieldValues.txtRif.trim() !== "-        -" && fieldValues.txtRif.trim() !== "" ? "" : "Campo Requerido.";
        if ("txtDireccion" in fieldValues) temp.txtDireccion = fieldValues.txtDireccion ? "" : "Campo Requerido.";
        if ("txtTelefono1" in fieldValues) temp.txtTelefono1 = fieldValues.txtTelefono1.trim() !== "+" && fieldValues.txtTelefono1.trim() !== "" ? "" : "Campo Requerido.";
        if ("txtTelefono1" in fieldValues) temp.txtTelefono1 = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(fieldValues.txtTelefono1) ? "" : "Teléfono no valido.";
        if ("txtEmail" in fieldValues) {
            if (fieldValues.txtEmail === "") {
                temp.txtEmail = "Campor Requerido.";
            } else if (!/$^|.+@.+..+/.test(fieldValues.txtEmail)) {
                temp.txtEmail = "Email no valido.";
            } else {
                temp.txtEmail = "";
            }
        }
        if ("cboTipoContrib" in fieldValues) temp.cboTipoContrib = fieldValues.cboTipoContrib ? "" : "Campo Requerido.";
        if ("cboTipoActividadComercial" in fieldValues) temp.cboTipoActividadComercial = fieldValues.cboTipoActividadComercial ? "" : "Campo Requerido.";
        if ("cboUsaMaquina" in fieldValues) temp.cboUsaMaquina = fieldValues.cboUsaMaquina ? "" : "Campo Requerido.";
        // if (textUsaMaquina === "Si" && fieldValues.fechaAdquiMF === null) {
        //     temp.fechaAdquiMF = "Campo requerido.";
        // } else {
        //     temp.fechaAdquiMF = "";
        // }
        if (textUsaMaquina === "Si" && fieldValues.txtCantidadMF === null) {
            temp.txtCantidadMF = "Campo requerido.";
        } else {
            temp.txtCantidadMF = "";
        }

        if ("cboPeriodoFiscal" in fieldValues) temp.cboPeriodoFiscal = fieldValues.cboPeriodoFiscal ? "" : "Campo Requerido.";

        if (textPeriodoFiscal === "Irregular" && fieldValues.fechaCierrePF === null) {
            temp.fechaCierrePF = "Campo requerido.";
        } else {
            temp.fechaCierrePF = "";
        }

        if ("txtSubActividadComercial" in fieldValues) {
            temp.txtSubActividadComercial = fieldValues.txtSubActividadComercial ? "" : "Campo requerido.";
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, errors, setErrors, handleInputChange, handleAutoCompleteInputChange, resetForm } = useForm(initialFValues, true, validate);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setButtonDisabled(true);
            setLoadingDialog(true);
            values.txtNroDocRL = values.txtNroDocRL.trim();
            values.txtTelefono1 = values.txtTelefono1.trim();
            values.txtTelefono2 = values.txtTelefono2 ? values.txtTelefono2.trim() : null;
            values.fechaAdquiMF = values.fechaAdquiMF ? moment(values.fechaAdquiMF).format("YYYY-M-D") : null;
            values.fechaCierrePF = values.fechaCierrePF ? moment(values.fechaCierrePF).format("YYYY-M-D") : null;
            httpService.registrarContribuyente(values)
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        setLoadingDialog(false);
                        let temp = { ...errors };
                        if (res.message.indexOf("Rif") >= 0) {
                            temp.txtRif = res.message;
                        } else {
                            temp.txtEmail = res.message;
                        }
                        setErrors({
                            ...temp,
                        });
                    } else {
                        const a_data = { idContrib: "" };
                        a_data.idContrib = res.data;
                        httpService.crearNotificaciones(a_data).then((res2) => {
                            if (res2.error) {
                                setLoadingDialog(false);
                                toast.current.show({ severity: "error", summary: "Error", detail: res2.message, life: 5000 });
                                setButtonDisabled(false);
                            } else {
                                setLoadingDialog(false);
                                toast.current.show({ severity: "success", summary: "Exito", detail: res.message, life: 5000 });
                                resetForm();
                                setTimeout(() => {
                                    history.push("/login");
                                }, 3100);
                            }
                        }).catch((err2) => {
                            setLoadingDialog(false);
                            toast.current.show({ severity: "error", summary: "Error", detail: err2.message, life: 3000 });
                            setButtonDisabled(false);
                        });


                    }
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: err.message, life: 3000 });
                    setButtonDisabled(false);
                });
        }
    };

    const handleReset = async (e) => {
        console.log("reset form");
        e.preventDefault();
        resetForm();
    };

    const [textUsaMaquina, setTextMaquinafiscal] = useState("");
    const setTextMaquinafiscal2 = (e) => {
        console.log(e.nativeEvent.target.innerText);
        setTextMaquinafiscal(e.nativeEvent.target.innerText);
    }

    const [textPeriodoFiscal, setTextPeriodoFiscal] = useState("");
    const setTextPeriodoFiscal2 = (e) => {
        console.log(e.nativeEvent.target.innerText);
        setTextPeriodoFiscal(e.nativeEvent.target.innerText);
    }

    return (
        <Form onSubmit={handleSubmit} id="formRegistro">
            <Toast ref={toast} />
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Container component={Paper} elevation={5} maxWidth="md" className={classes.container}>
                    <div className={classes.div}>
                        <Avatar className={classes.avatar}>
                            <AddIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Registro de Contribuyente
                        </Typography>
                        <Typography component="h1" variant="subtitle1" align="left">
                            Datos del Representante Legal
                        </Typography>
                        <div className="p-fluid p-formgrid p-grid p-col-12" >
                            <div className="p-field p-col-12 p-md-6">
                                <Controls.InputTextMask size="small" fullWidth color="primary" margin="normal" variant="outlined" onChange={handleInputChange} type="cedula" name="txtNroDocRL" label="Documento de Identidad" placeholder="V-9999999" value={values.txtNroDocRL} error={errors.txtNroDocRL} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <Controls.Input size="small" fullWidth color="primary" margin="normal" variant="outlined" onChange={handleInputChange} name="txtNombreRL" label="Nombre y Apellido del Representante Legal" value={values.txtNombreRL} error={errors.txtNombreRL} />
                            </div>
                        </div>
                        <Typography component="h1" variant="subtitle1" align="left">
                            Datos del Contribuyente
                        </Typography>
                        <div className="p-fluid p-formgrid p-grid p-col-12">
                            <div className="p-field p-col-12 p-md-6">
                                <Controls.Input size="small" fullWidth color="primary" margin="normal" variant="outlined" onChange={handleInputChange} name="txtRazonSocial" label="Razón Social" value={values.txtRazonSocial} error={errors.txtRazonSocial} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <Controls.InputTextMask size="small" fullWidth color="primary" margin="normal" variant="outlined" onChange={handleInputChange} type="rif" name="txtRif" label="RIF" placeholder="J-9999999-9" value={values.txtRif} error={errors.txtRif} />
                            </div>

                            <div className="p-field p-col-12">
                                <Controls.Input size="small" fullWidth color="primary" margin="normal" variant="outlined" onChange={handleInputChange} name="txtDireccion" label="Dirección" value={values.txtDireccion} error={errors.txtDireccion} />
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <Controls.InputNumberMask size="small" fullWidth color="primary" margin="normal" variant="outlined" onChange={handleInputChange} type="tel" name="txtTelefono1" label="Teléfono" placeholder="+589999999" value={values.txtTelefono1} error={errors.txtTelefono1} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <Controls.InputNumberMask size="small" fullWidth color="primary" margin="normal" variant="outlined" onChange={handleInputChange} type="tel" name="txtTelefono2" label="Teléfono 2" placeholder="+589999999" value={values.txtTelefono2} error={errors.txtTelefono2} />
                            </div>

                            <div className="p-field p-col-12 p-md-6">
                                <Controls.Input size="small" fullWidth color="primary" margin="normal" variant="outlined" onChange={handleInputChange} name="txtEmail" label="Email" value={values.txtEmail} error={errors.txtEmail} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <Controls.Select size="small" fullWidth color="primary" margin="normal" variant="outlined" onChange={handleInputChange} name="cboTipoContrib" label="Tipo de Contribuyente" options={tipoContribOptions} value={values.cboTipoContrib} error={errors.cboTipoContrib} />
                            </div>
                            <div className="p-field p-col-12 p-md-3" >
                                <Controls.Select size="small" fullWidth color="primary" margin="normal" variant="outlined" onChange={(e) => { handleInputChange(e); setTextMaquinafiscal2(e) }} name="cboUsaMaquina" label="Usa maquina fiscal" options={usaMaquinaOptions} value={values.cboUsaMaquina} error={errors.cboUsaMaquina} />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
								<Controls.InputNumberMask size="small" fullWidth color="primary" margin="normal" variant="outlined" onChange={handleInputChange} type="num" name="txtCantidadMF" label="¿Cuántas?" placeholder="" value={values.txtCantidadMF} error={errors.txtCantidadMF} disabled={textUsaMaquina === "" || textUsaMaquina === "No" ? true : false} />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Controls.Select size="small" color="primary" margin="normal" variant="outlined" onChange={(e) => { handleInputChange(e); setTextPeriodoFiscal2(e) }} name="cboPeriodoFiscal" label="Periodo Fiscal" options={periodoFiscalOptions} value={values.cboPeriodoFiscal} error={errors.cboPeriodoFiscal} />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Controls.DatePicker size="small" color="primary" margin="normal" variant="outlined" onChange={handleInputChange} id="fechaCierrePF" name="fechaCierrePF" label="Fecha de cierre" value={values.fechaCierrePF} error={errors.fechaCierrePF} disabled={textPeriodoFiscal === "" || textPeriodoFiscal === "Regular" ? true : null} />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <Controls.Select
                                    fullWidth
                                    color="primary"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    onChange={handleInputChange}
                                    name="cboTipoActividadComercial"
                                    label="Actividad Comercial"
                                    options={tipoActComercialOptions}
                                    value={values.cboTipoActividadComercial}
                                    error={errors.cboTipoActividadComercial}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <Controls.InputAutoComplete
                                    fullWidth
                                    color="primary"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e, value) => {
                                        handleAutoCompleteInputChange("txtSubActividadComercial", value);
                                    }}
                                    name="txtSubActividadComercial"
                                    label="Sub-Actividad Comercial"
                                    options={subActComercialOptions.map((option) => option.id + " - " + option.title)}
                                    error={errors.txtSubActividadComercial}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3" />
                            <div className="p-field p-col-12 p-md-3">
                                <Controls.Button fullWidth variant="contained" color="default" className={classes.button} disabled={ButtonDisabled} text="Limpiar" onClick={handleReset} />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <Controls.Button fullWidth variant="contained" color="primary" className={classes.button} disabled={ButtonDisabled} type="submit" text="Registrar" />
                            </div>
                            <div className="p-field p-col-12 p-md-3" />
                        </div>
                        <div className="p-col-12">
                            <div>
                                <ShowLoading data={loadingDialog} />
                            </div>
                        </div>
                    </div>
                </Container>
            </Grid>
        </Form>
    );
}
