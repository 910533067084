import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
        fontSize: 8,
    },
    rowT: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        fontSize: 8,
    },
	rowSinBorde: {
        flexDirection: 'row',
        // borderBottomColor: '#bff0fd',
        // borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    nroOper: {
        width: '10%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    fecha: {
        width: '15%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    nroFact: {
        width: '15%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    nroConFact: {
        width: '15%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    tCcA: {
        width: '25%',
		borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    tcScF: {
        width: '25%',
		borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    bI: {
        width: '25%',
		borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    alicuota: {
        width: '15%',
		borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
    },
    deAlicuota: {
        width: '25%',
		borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    deRetenido: {
        width: '25%',
		borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },

    //TOTALES
    nroOperT: {
        width: '10%',
        textAlign: 'center',
        paddingLeft: 8,
    },
    fechaT: {
        width: '15%',
        textAlign: 'left',
        paddingLeft: 8,
    },
    nroFactT: {
        width: '15%',
        textAlign: 'left',
        paddingLeft: 8,
    },
    nroConFactT: {
        width: '15%',
        textAlign: 'left',
        paddingLeft: 8,
    },
    tCcAT: {
        width: '25%',
        textAlign: 'right',
        paddingRight: 8,
    },
    tcScFT: {
        width: '25%',
        textAlign: 'right',
        paddingRight: 8,
    },
    bIT: {
        width: '25%',
        textAlign: 'right',
        paddingRight: 8,
    },
    alicuotaT: {
        width: '15%',
        textAlign: 'center',
        paddingRight: 8,
    },
    deAlicuotaT: {
        width: '25%',
        textAlign: 'right',
        paddingRight: 8,
    },
    deRetenidoT: {
        width: '25%',
        textAlign: 'right',
        paddingRight: 8,
        fontWeight: "bold"
    },
    deRetenidoISLR: {
        width: '25%',
        textAlign: 'right',
        paddingRight: 8,
        fontWeight: "bold"
    },

    description: {
        width: '40%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amountUnit: {
        width: '15%',
		borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
		// borderRightColor: borderColor,
        // borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
	vacio: {
		width: '65%',
		borderLeft: 'none',
	},
	labelTotal: {
		width: '85%',
        textAlign: 'right',
        paddingRight: 8,
	},
	total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });

  const formatCurrency = function (number) {
	var numeral = require("numeral");
	var myNumeral = numeral(number);
	return myNumeral.format("0,0.00");
	};


    const tCcAT = (items) => {
        let total = 0;
        let sMoneda = "";
        for (let mont of items) {
            total += parseFloat(mont.txtTotalConImp);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency(total) + " " + sMoneda;
    };

    const bIT = (items) => {
        let total = 0;
        let sMoneda = "";
        for (let mont of items) {
            total += parseFloat(mont.txtTotaLBaseImpo);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency(total) + " " + sMoneda;
    };

    const deRetenidoT = (items) => {
        let total = 0;
        let sMoneda = "";
        for (let mont of items) {
            total += parseFloat(mont.txtPctRetenido);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency(total) + " " + sMoneda;
    };

    const deRetenidoISLR = (items) => {
        let total = 0;
        let sMoneda = "";
        for (let mont of items) {
            total += parseFloat(mont.txtPctRetenidoISLR);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency(total) + " " + sMoneda;
    };

    const totalFac = (items) => {
        let total1 = 0;
        let total2 = 0;
        let sMoneda = "";
        for (let mont of items) {
            total1 += parseFloat(mont.txtTotalConImp);
            sMoneda = mont.sMoneda;
        }
        for (let mont of items) {
            total2 += parseFloat(mont.txtTotalSinImp);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency(total1 + total2) + " " + sMoneda;
    };

    const totalPagar = (items) => {
        let total1 = 0;
        let total2 = 0;
        let total3 = 0;
        let total4 = 0;
        let sMoneda = "";
        for (let mont of items) {
            total1 += parseFloat(mont.txtTotalConImp);
            sMoneda = mont.sMoneda;
        }
        for (let mont of items) {
            total2 += parseFloat(mont.txtTotalSinImp);
            sMoneda = mont.sMoneda;
        }
        for (let mont of items) {
            total3 += parseFloat(mont.txtPctRetenidoISLR);
            sMoneda = mont.sMoneda;
        }
        for (let mont of items) {
            total3 += parseFloat(mont.txtPctRetenido);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency((total1 + total2) - (total3 + total4)) + " " + sMoneda;
    };


const RetencionesItemsRowsISLR = ({items}) => {
	
    const rows = items.map( item => 
		
        <View style={styles.row} key={item.id}>
            <Text style={styles.fecha}>{item.txtFechaCompra}</Text>
            <Text style={styles.nroFact}>{item.txtNroFactura}</Text>
            <Text style={styles.nroConFact}>{item.sNroControl}</Text>
            <Text style={styles.tCcA}>{formatCurrency(item.txtTotalConImp)}</Text>
            <Text style={styles.bI}>{formatCurrency(item.txtTotaLBaseImpo)}</Text>
            <Text style={styles.alicuota}>{item.dePctTarifaRetenidaISLR}</Text>
            <Text style={styles.alicuota}>{item.deSustraendoISLR}</Text>
            <Text style={styles.deAlicuota}>{item.sConceptoISLR}</Text>
            <Text style={styles.deRetenido}>{formatCurrency(item.txtPctRetenidoISLR)}</Text>
        </View>
    )
  
    return (
		<Fragment>
			{rows}
            <View style={styles.rowT}>
                <Text style={styles.fechaT}></Text>
                <Text style={styles.nroFactT}></Text>
                <Text style={styles.nroConFactT}>TOTALES</Text>
                <Text style={styles.tCcAT}>{tCcAT(items)}</Text>
                <Text style={styles.bIT}>{bIT(items)}</Text>
                <Text style={styles.alicuotaT}></Text>
                <Text style={styles.alicuotaT}></Text>
                <Text style={styles.alicuotaT}></Text>
                <Text style={styles.deRetenidoT}>{deRetenidoISLR(items)}</Text>
			</View>  

            <View style={styles.rowSinBorde}>
				<Text style={styles.labelTotal}>Total Factura</Text>
				<Text style={styles.total}>{ totalFac(items) }</Text>
			</View>

            <View style={styles.rowSinBorde}>
				<Text style={styles.labelTotal}>Monto Retenido ISLR</Text>
				<Text style={styles.total}>{ deRetenidoISLR(items) }</Text>
			</View>   

            <View style={styles.rowSinBorde}>
				<Text style={styles.labelTotal}>Monto Retenido IVA</Text>
				<Text style={styles.total}>{ deRetenidoT(items) }</Text>
			</View>    

            <View style={styles.rowSinBorde}>
				<Text style={styles.labelTotal}>Total a Pagar</Text>
				<Text style={styles.total}>{totalPagar(items)}</Text>
			</View>    
		</Fragment> 
	)
};
  
  export default RetencionesItemsRowsISLR