import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function InputAutoComplete(props) {
    // const { name, label, value, margin, variant, error=null, onChange, type, fullWidth, autoFocus, options} = props;
    const { label, name, variant, value, error = null, onChange } = props;
    return (
        <Autocomplete {...props} id="controlled" value={value} onChange={onChange} 
			renderInput={ (params) => 
				<TextField 
					{...params} 
					name={name} 
					label={label} 
					variant={variant} 
					value={value}
					{...(error && { error: true, helperText: error })} 
				/>
			} 
		/>

        
    );
}
