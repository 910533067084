import { TextField, Select } from "@mui/material";
import { styled } from "@mui/material/styles";

export const styles = {
  container: {
    width: "100%",
    border: 1,
    borderColor: "divider",
    borderRadius: 2,
    marginTop: 5,
    marginBottom: 2,
  },

  field: {
    display: "flex",
    width: "50%",
    // backgroundColor:"red",
    flexDirection: { xs: "column" },
    // flex: "0 1 50%",
    // my: 0.5,
    margin: "3% 0%",
  },
  label: {
    display: "flex",
    fontWeight: 700,
    fontSize: "16px",
  },
};

export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#0007",
  },
  // ".MuiFormHelperText-root": {
  //   color: `${env.COLOR_RED_PRINCIPAL} !important`,
  // },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#0007",
    borderRadius: "5px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#EDEDED",
      borderRadius: "5px",
    },
    "&:hover fieldset": {
      borderColor: "#0007",
      borderRadius: "5px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0007",
      borderRadius: "5px",
    },
  },
});

export const CssSelect = styled(Select)({
  "& .MuiInput-underline:after": {
    borderBottomColor: "#0007",
    borderRadius: "5px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#EDEDED",
      borderRadius: "5px",
    },
    "&:hover fieldset": {
      borderColor: "#0007",
      borderRadius: "5px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0007",
      borderRadius: "5px",
    },
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent",
  },
});
