import { useState } from "react";
// import { useIbylitStore } from "../../../../../../hooks/useIbylitStore.jsx";

export const useValue = () => {
  const [value, setValue] = useState(0);

  // const { isUsersLoginLoading } = useIbylitStore().users;

  const handleValueChange = (event, newValue) => setValue(newValue);

  return {
    value,
    setValue,

    handleValueChange,

    // isUsersLoginLoading,
  };
};
