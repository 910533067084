import React from 'react';
import {View, StyleSheet, Text } from '@react-pdf/renderer';
import RetencionesItemsRows from './RetencionesItemsRows'

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
	container: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
        fontSize: 8,
    },
    nroOper: {
        width: '10%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    fecha: {
        width: '15%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    nroFact: {
        width: '15%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    nroConFact: {
        width: '15%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    tcCa: {
        width: '25%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    tcScF: {
        width: '25%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    bI: {
        width: '25%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    alicuota: {
        width: '15%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    deAlicuota: {
        width: '25%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    deRetenido: {
        width: '25%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    description: {
        width: '40%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    amount: {
        width: '15%'
    },
});

  const RetencionesDetalles = ({retencion}) => (
    <View style={styles.tableContainer}>
        <View style={styles.container}>
            <Text style={styles.nroOper}>Nro Oper</Text>
            <Text style={styles.fecha}>Fecha Fact.</Text>
			<Text style={styles.nroFact}>Nro Fact.</Text>
            <Text style={styles.nroConFact}>Nro Control</Text>
			<Text style={styles.tcCa}>Total/c Alicuota.</Text>
            <Text style={styles.tcScF}>Total/S Credito</Text>
            <Text style={styles.bI}>Base Imponible</Text>
            <Text style={styles.alicuota}>% Alicuota</Text>
            <Text style={styles.deAlicuota}>Impuesto</Text>
            <Text style={styles.deRetenido}>{retencion.txtPctRetenido} Retenido</Text>
    	</View>

        <RetencionesItemsRows items={retencion.items} />
    </View>
  );
  
  export default RetencionesDetalles