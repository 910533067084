import Input from "./Input";
import InputTextMask from "./InputTextMask";
import InputNumberMask from "./InputNumberMask";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import InputAutoComplete from "./InputAutoComplete";

const Controls = {
    Input,
    InputTextMask,
    InputNumberMask,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    InputAutoComplete,
};

export default Controls;
